import React from "react";
import Header from "../../Container/Header";
import MenuHeader from "../../Container/MenuHeader";
import { contactData } from "../../helpers/AuthStore/contactSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLayoutEffect, useEffect, useState } from "react";
import PostApiData from "../../helpers/postApiData";

import SideMenu from "../../Container/SideMenu";
import {MdDelete} from "react-icons/md";

import { AiOutlineSearch } from "react-icons/ai";
import ToasterGen from "../../Container/ToasterGen";
const Orders = () => {
  const dispatch = useDispatch();
  let contact = useSelector((state) => state.contact);

  const [showModal, setShowModal] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [contactState, setcontactState] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100000000;
  const filteredMenu = contact.contacts.contacts
    ? contact.contacts.contacts.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMenu.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredMenu.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    dispatch(contactData());
  }, [dispatch]);
  useEffect(() => {
    dispatch(contactData());
  }, [showModal, showModalCreate]);

  const handleUpdateClick = async (item) => {
    item = {
      ...item,
    };

    setcontactState(item);
    setShowModal(true);
  };
  const handleDeleteButton = async (id) => {
    await PostApiData("auth/delete_contact", { ID: id });
    dispatch(contactData());
  };

  return (
    <>
    <ToasterGen></ToasterGen>

      <Header></Header>
      <MenuHeader></MenuHeader>

      <div className="grid grid-cols-1 lg:grid-cols-10 gap-0">
        <div className="lg:col-span-2  lg:flex bg-background-main">
          <SideMenu />
        </div>
        <div className="lg:col-span-8 bg-background-main">
          <div className="container mx-auto mt-14">
            <div className="flex flex-col min-h-screen bg-transparent">
              <div className="p-4">
                <span className="mb-3 mx-auto text-2xl font-bold font-dmsans text-subheading-400  text-line">
                 Contact Page
                </span>
              </div>

              <div className="flex mx-8 mt-10 items-end justify-end sm:justify-end ">
                <div className="absolute flex-shrink-0 items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search contact"
                      className="bg-background bcontact bcontact-gray-600 text-line font-dmsans text-sm rounded-lg focus:ring-background focus:bcontact-background block w-full pl-10 p-2.5 focus:outline-none focus:ring-0"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <AiOutlineSearch className="w-5 h-5 text-line dark:text-line" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative flex flex-col m-6 space-y-8 bg-background shadow-2xl rounded-2xl">
                <div className="p-8 md:p-14">
                  <span className="mb-3 font-dmsans text-subheading-400 text-textColor">
                    Contact List
                  </span>

                  <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-background">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Contact Name
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Contact Phone Number
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Contact Email
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Contact Description
                          </th>
                          
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                          <th className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-background divide-y divide-gray-200">
                        {currentItems.map((item,index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.name}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.phone}
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.email}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.desc}
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <button
                                onClick={() => handleDeleteButton(item._id)}
                                href="#"
                                className="text-line hover:extra-color"
                              >
                                <MdDelete />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
