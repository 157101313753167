import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";

export const orderData = createAsyncThunk(
  "order",

  async (payload, { rejectWithValue }) => {
    try {
      const request = await axios.get(env.API_URL + "merchant/get_orders");
      const response = await request.data;

      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message); 
    }
  }
);

const orderSlice = createSlice({
  name: "desposit",
  initialState: {
    orders : [],
    loading: false,
    error: null,
  },
  reducers: {
    addorder: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(orderData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(orderData.rejected, (state, action) => {
  
        state.loading = true;
      })
      .addCase(orderData.fulfilled, (state, action) => {
          state.loading = false;
          state.orders = action.payload;
       
        }
      );
  },
});
export const { addorder } = orderSlice.actions;
export default orderSlice.reducer;

