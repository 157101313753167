
import { useFormik } from "formik";

import Header from "../Container/Header";
import React from "react";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import Sec from "./Sec";
import { merchantData } from "../helpers/AuthStore/merchantSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLayoutEffect, useEffect, useState, useMemo,useRef } from "react";
import Select from "react-select";
import { toast, Toaster, ToastBar } from "react-hot-toast";
import { AiOutlineSearch } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import GetApiData from "../helpers/getApiData";

import j1 from '../assets/1c.png'

import j2 from '../assets/2c.png'
import j3 from '../assets/3c.png'
import j4 from '../assets/4c.png'
import j5 from '../assets/5c.png'
import j6 from '../assets/6c.png'
import j7 from '../assets/7c.png'


import j8 from '../assets/8x.png'

import TradeDetails from "./TradeDetails";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";

import { ValidateContactForm } from "../utils/validationAmount";
import Footer from "../Container/Footer";
import ToasterGen from "../Container/ToasterGen";
import Faqs from "./Faqs";
import GuideSection from "./GuideSection";
import P2P from "./P2P";
import Explore from "./Explore";
import Sec2 from "./Sec2";

const DashBoard = ({ }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let merchant = useSelector((state) => state.merchant);
  const [selectedOptions, setSelectedOptions] = useState();
  const sectionRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const [showFilters, setShowFilters] = useState(true);
  const [type, setType] = useState("buyer");

  function handleScroll() {
    window.scroll({
      top: document.body.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }

  const [martusd5, setmartusd5] = useState(false);
  const [martusd6, setmartusd6] = useState(false);
  const [martusd7, setmartusd7] = useState(false);
  const [martusd8, setmartusd8] = useState(false);
  const [martusd9, setmartusd9] = useState(false);
  const [randomPosition, setRandomPosition] = useState({});

  useEffect(() => {
    generateRandomPosition();
  }, []);

  const generateRandomPosition = () => {
    const randomX = Math.random() * 100; // Change as needed
    const randomY = Math.random() * 100; // Change as needed

    setRandomPosition({
      left: `${randomX}%`,
      top: `${randomY}%`,
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      generateRandomPosition();
      
      
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);





  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      desc: "",
    },
    validate: ValidateContactForm,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);
      formik.handleReset();
      return false;
    },
  });

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const userrole = JSON.parse(localStorage.getItem("user")).role;

      if (userrole == "admin") {
        navigate("/merchant_list");
      } else if (userrole == "user") {
        navigate("/dashboard");
      } // Redirect to dashboard if user is already authenticated
    }
  }, []);

  function handleSelect(selected) {
    handleCurrencyFilter(selected.label);
    setSelectedOptions(selected);
  }
  const [selectedFilters, setSelectedFilters] = useState({
    Currency: "",
    type:"buyer",
  });


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDark ? "#F5F5F5" : "#F5F5F5",
      borderColor: "#F5F5F5",
      borderRadius: "3px",
      fontSize: "14px",

      padding: "1px",
      boxShadow: state.isFocused ? "0 0 0 0 #F5F5F5" : "none",
      fontFamily: "Poppins, sans-serif", // Set the font family to Poppins

      color: "#171717",
      "&:hover": {
        borderColor: "#F5F5F5",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#F5F5F5",
        height: "20px",
      },
      input: {
        color: "#F5F5F5",
        backgroundColor: "#F5F5F5",
      },

      height: "1px", // Set the height of the select box
    }),
    input: (provided) => ({
      ...provided,
      color: "#F5F5F5", // Set the input text color to white
      fontFamily: "Poppins, sans-serif", // Set the font family to Poppins
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#171717" : "#F5F5F5",
      color: state.isFocused ? "#F5F5F5" : "#171717",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#171717",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#F5F5F5",
      borderRadius: "4px",
      color: "#171717",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#171717",
      fontSize: "14px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#171717",
      "&:hover": {
        backgroundColor: "#F5F5F5",
        color: "#171717",
      },
    }),
  };


  const itemsPerPage = 5;
  const filteredmerchant = merchant.merchants.merchant
    ? merchant.merchants.merchant.filter((item) => {
        const isNameMatch = item.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      
        const isCurrencyMatch =
          selectedFilters.Currency == "" ||
          !selectedFilters.Currency ||
          (item.Currency &&
            item.Currency.toString() === selectedFilters.Currency);


            const isTypeMatch =
            selectedFilters.type == "" ||
            !selectedFilters.type ||
            (item.type &&
              item.type.toString() === selectedFilters.type);
  

        return isCurrencyMatch && isNameMatch && isTypeMatch;
      })
    : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredmerchant.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  let totalPages = Math.ceil(filteredmerchant.length / itemsPerPage);
  const [options, setOptions] = useState([]);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const NfilteredMerchant = useMemo(() => {
    if (!merchant.merchants) return [];

    return currentItems.filter((item) => {
      const isNameMatch = item.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      const isCurrencyMatch =
        selectedFilters.Currency === "" ||
        !selectedFilters.Currency ||
        (item.Currency &&
          item.Currency.toString() === selectedFilters.Currency);


          
          const isTypeMatch =
          selectedFilters.type == "" ||
          !selectedFilters.type ||
          (item.type &&
            item.type.toString() === selectedFilters.type);


      return isCurrencyMatch && isNameMatch && isTypeMatch;
    });
  }, [currentItems, selectedFilters]);




  useEffect(() => {
    async function fetchData() {
      const data = await GetApiData("merchant/get_currencies", "");

      const results = [];
      results.push({ key: 0, value: "" });

      if(data)
      {
     data  &&  data.symbols.map((value, index) => {
        return results.push({
          value: index,
          label: value,
        });
      });

      setOptions([...results]);
    }
    }

    fetchData();
  }, [merchant.merchants]);

  const getPages = () => {
    const maxVisiblePages = 5;
    const sidePages = Math.floor((maxVisiblePages - 3) / 2);
    const pages = [];

    pages.push(
      <button
        onClick={() => handlePageChange(1)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 italic  ${
          currentPage === 1
            ? "bg-gray-200 text-gray-600" 
            : " bg-newbgcolor text-line"
        }`}
      >
        1
      </button>
    );

    let startPage = Math.max(2, currentPage - sidePages);
    let endPage = Math.min(totalPages - 1, currentPage + sidePages);
    let addStartDots = false;
    let addEndDots = false;

    if (endPage < totalPages - 1) {
      addEndDots = true;
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={` px-3 py-1 font-bold rounded font-dmsans     ${
            currentPage === page
            ? "bg-gray-200 text-gray-600"
            :  " bg-newbgcolor text-line"
          }`}
        >
          {page}
        </button>
      );
    }

    if (addEndDots) {
      pages.push(
        <span key="end-dots" className="px-0 py-1  text-gray-600">
          ...
        </span>
      );
    }

    pages.push(
      <button
        key={totalPages}
        onClick={() => handlePageChange(totalPages)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 italic ${
          currentPage === totalPages
          ?  "bg-gray-200 text-gray-600"
          :" bg-newbgcolor text-line" 
        }`}
      >
        {totalPages}
      </button>
    );

    return pages;
  };




  const handleColumnSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedItems = NfilteredMerchant.sort((a, b) => {
    if (sortColumn === "name") {
      return sortDirection === "asc"
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (sortColumn === "Price") {
      return sortDirection === "asc"
      ? a.Payment - b.Payment 
      : b.Payment - a.Payment; 
    }
    return 0;
  });

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCurrencyFilter = (Currency) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      Currency,
    }));
  };

  const clearFilters = () => {
    setSelectedFilters({
      Currency: "",
    });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useLayoutEffect(() => {
    return () => {
      if (location.state) {
        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          category: location.state,
        }));
      }
    };
  }, [merchant.merchants.merchant]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const role = JSON.parse(localStorage.getItem("user")).role;
      if (role == "admin") {
        navigate("/merchant_list");
      }
    }
    
  }, []);

  useEffect(() => {
  console.log(location.state);

    dispatch(merchantData({}));

  }, []);

  useEffect(() => {
    if (merchant.merchants.length > 0) {
      toast.success("Merchant data loaded successfully!");
    }
  }, [merchant.merchants]);

  const isLoading = !merchant.merchants;


  const handleFaqClick = () => {
    console.log("ASDasd")
    if (sectionRef.current) {
    sectionRef.current?.scrollIntoView({
      behavior: 'smooth', 

      block: 'start',
    });
  }
  };


  return (
    <React.Fragment  >
      <Header handleFaqClick = {handleFaqClick} ></Header>


      <ToasterGen>
       </ToasterGen>
    
<div className="bg-maincolor">
      <div className="relative w-full bg-maincolor">



      <div className="flex flex-col items-center justify-center w-full h-[50vw] max-h-[400px] opacity-70 text-line relative">
      <div
        className="flex flex-col h-auto p-4 md:p-8 text-center bg-transparent text-line w-1/2 z-50"
        style={randomPosition}
      >
        <p className="text-black font-bold md:text-5xl w-full text-lg  justify-center text-center leading-6 font-dmsans z-50">
          Trade Freely, Trade Privately: Join the P2P Revolution
        </p>
      </div>

      <div className="group2"></div>
      <div className="group4"></div>
      <div className="group3"></div>
    </div>




      </div>
    
    <div className="bg-newbgcolor border-1 border-solid border-gray-300  mx-10 my-10 rounded-xl ">
      <div className="flex  bg-newbgcolor px-3 border-b border-gray-300  rounded-xl ">



  <div className="p-4 bg-transparent px-3 z-0  ">
    <div className={`pricing-toggle ${type === 'buyer' ? 'buyer-toggle' : 'sell-toggle'} bg-newbgcolor p-2 border rounded-md shadow-border`}>
      <input
        type="radio"
        id="buyer-toggle-buy"
        name="pricing"
        value="buy"
        checked={type === "buyer"}
        className="hidden"
        onChange={(e) => {
          setType("buyer");
          setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            type:"buyer",
          }));
          


        }}
      />
      <label
        className="cursor-pointer font-medium bg-maincolor text-line py-2 px-4 rounded-md"
        htmlFor="buyer-toggle-buy"
      >
        Buy
      </label>

      <input
        type="radio"
        id="sell-toggle-sell"
        name="pricing"
        value="sell"
        checked={type === "seller"}
        className="hidden"
        onChange={(e) => {
          setType("seller");

          setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            type:"seller",
          }));
          

        }}
      />
      <label
        className={`cursor-pointer py-2 px-4 rounded-md ${
          type === "sell" ? "bg-red-500 text-white" : "bg-maincolor text-line font-medium"
        }`}
        htmlFor="sell-toggle-sell"
      >
        Sell
      </label>
    </div>
  </div>

  <div className="bg-newbgcolor justify-start mx-auto  mt-3 md:hidden lg:hidden sm:hidden  ">
    <div className="bg-maincolor w-full">
      <Select
        isSearchable={true}
        selectedOptions={selectedOptions}
        options={options}
        placeholder="Select Currency"
        styles={customStyles}
        onChange={(selected) => handleSelect(selected)}
        className="block border bg-maincolor  w-full py-2 rounded-lg focus:outline-none focus:ring-maincolor focus:border-maincolor text-white"
      ></Select>
    </div>
  </div>
  
</div>


      <div className="relative w-full bg-transparent   items-center px-12 sm:px-24 md:px-24 lg:px-24 xl:px-24 2xl:px-24">
        <div className="flex flex-col md:flex-row items-end justify-start  mx-2 sm:mx-4 ">
          <div className="relative mb-4 md:mb-0 md:w-64 mt-6 ">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search Merchant"
                className="bg-maincolor text-line  h-14   font-dmsans   text-base rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2 focus:outline-none focus:ring-0"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <AiOutlineSearch className="w-5 h-5 text-line dark:text-line" />
              </div>
            </div>
          </div>

          <div className="md:w-56 ml-0  md:mt-0 md:ml-4 hidden md:block ">
            <Select
              isSearchable={true}
              selectedOptions={selectedOptions}
              options={options}
              placeholder="Select Currency" // Set the placeholder text

     
              styles={customStyles}
              onChange={(selected) => handleSelect(selected)}
              className="text-textColor block    border  bg-maincolor border-[#27203D]      w-52   px-3 py-2 rounded-lg focus:outline-none focus:ring-maincolor focus:border-maincolor "
            ></Select>
          </div>
        </div>
      </div>

      <div className="mt-0   grid grid-cols-1 sm:grid-cols-5 gap-2 sm:px-2 md:px-16 lg:px-28 xl:px-28 2xl:px-28 px-2">
        <div
          className={`w-full col-span-4 ${
            showFilters ? "col-span-5" : "col-span-5"
          }`}
        >
          <TradeDetails
            handleColumnSort={handleColumnSort}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            currentItems={sortedItems}
            typeNew={type}
          ></TradeDetails   >
        </div>
      </div>


      
      <div className=" flex justify-end border-1 rounded-xl bg-newbgcolor  gap-2 sm:px-2 md:px-16 lg:px-28 xl:px-28 2xl:px-28 px-2">
        <div className="flex flex-col ">
          <div className="flex flex-1 mb-4">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="font-dmsans  px-3 py-1 font-medium rounded bg-newbgcolor  text-line  md:mb-0 mr-2"
            >
              <FaAngleLeft />
            </button>
            <div className="italic ">{getPages()}</div>

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="font-dmsans px-3 py-1 font-medium rounded bg-newbgcolor text-line"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
      </div>

      <div className="   mx-10 my-10 border-1 border-solid  rounded-xl">

      <Sec ></Sec>
     </div>

     
  
<Sec2 ></Sec2>

      {/* <div className="relative mt-0 w-full bg-maincolor p-5">
  <div className="flex flex-col gap-4 px-4 py-8 sm:flex-row">
    <div className="w-full sm:w-1/2 border-solid rounded-lg bg-newbgcolor border-1 p-4">
      <h2 className="text-line font-dmsans font-bold text-2xl leading-7">
        About TUMAANEX
      </h2>
      <p className="text-line font-dmsans font-normal text-sm leading-6 mt-2">
      Welcome to TUMAANEX, Asia's leading peer-to-peer USDT marketplace that redefines the way cryptocurrency trading happens in the region. With a strong commitment to innovation, security, and user experience, TUMAANEX has emerged as the ultimate platform for traders seeking seamless, secure, and efficient USDT transactions.      </p>
    </div>

    <div className="w-full sm:w-1/2 border-solid rounded-lg bg-newbgcolor border-1 p-4">
      <h2 className="text-line font-dmsans font-bold text-2xl leading-7">
        Our Mission
      </h2>
      <p className="text-line font-dmsans font-normal text-sm leading-6 mt-2">
      At TUMAANEX, our mission is to provide a dynamic and reliable ecosystem that empowers traders across Asia to engage in secure and efficient USDT transactions. We believe in fostering financial inclusion by simplifying the process of peer-to-peer cryptocurrency trading, making it accessible to everyone.      </p>
    </div>
  </div>
</div>  */}



      <div className=" bg-newbgcolor   h-max   mx-10 my-10 border-1 border-solid rounded-xl">
        <div className="flex p-5 justify-center font-bold  font-dmsans text-2xl  leading-7 text-line">
          Why Choose TUMAANEX?
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 bg-newbgcolor ">
          <div className="p-8">
            <img className=" w-11 h-11 " src={j5}></img>
            <p className=" font-bold font-dmsans  text-xl   leading-8  text-line">
            Decentralized Excellence
                        </p>
            <p className="font-normal font-dmsans  text-sm      text-line leading-5">
            TUMAANEX stands as a beacon of decentralized excellence in the world of digital asset trading. By choosing TUMAANEX, you opt for a platform where the power is distributed, ensuring that your trades are conducted in a truly decentralized and transparent environment.            </p>
          </div>

          <div className="p-8">

          <img className=" w-11 h-11 " src={j1}></img>
            <p className=" font-bold font-dmsans  text-xl   leading-8  text-line">
            Empowerment Beyond Transactions
            </p>
            <p className="font-normal font-dmsans  text-sm      text-line leading-5">
            We go beyond mere transactions. TUMAANEX is committed to empowering every trader by providing an inclusive space where your voice matters. Experience a trading platform where you are not just a participant but an active contributor to the evolution of our exchange.            </p>
          </div>

          <div className="p-8">
          <img className=" w-11 h-11 " src={j3}></img>
            <p className=" font-bold font-dmsans  text-xl   leading-8  text-line">
            Community-Driven Innovation
            </p>
            <p className="font-normal font-dmsans  text-sm      text-line leading-5">
            TUMAANEX is a hub of community-driven innovation. Our platform is not dictated from the top down; instead, it evolves based on the needs and ideas of our diverse user base. Join a community that shapes the features and future of TUMAANEX.            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-0">
          <div className="p-8">
          <img className=" w-11 h-11 " src={j7}></img>
            <p className=" font-bold font-dmsans  text-xl   leading-8  text-line">
            Transparency at its Core
            </p>
            <p className="font-normal font-dmsans  text-sm      text-line leading-5">
            Trust is paramount, and transparency is at the core of TUMAANEX. Every transaction is visible, ensuring that you have a clear view of your trades. Say goodbye to opacity and welcome a new era of secure and transparent trading.            </p>
          </div>

          <div className="p-8">
          <img className=" w-11 h-11 " src={j8}></img>
            <p className=" font-bold font-dmsans  text-xl   leading-8  text-line">
            Direct Trading, No Middlemen
            </p>
            <p className="font-normal font-dmsans  text-sm       text-line leading-5">
            Experience the simplicity of direct trading. TUMAANEX eliminates unnecessary middlemen, ensuring that your trades happen directly between you and other traders. This not only reduces complexities but also ensures that you receive the full benefits of your transactions.            </p>
          </div>

          <div className="p-8">
          <img className=" w-11 h-11 " src={j5}></img>
            <p className=" font-bold font-dmsans  text-xl   leading-8  text-line">
            Inclusivity for All Traders
            </p>
            <p className="font-normal font-dmsans  text-sm      text-line leading-5">
            TUMAANEX welcomes traders of all levels and backgrounds. Whether you're a seasoned professional or just beginning your trading journey, our platform is designed to be inclusive, providing a space where everyone can participate and thrive.            </p>
          </div>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-0">
          <div className="p-8">
          <img className=" w-11 h-11 " src={j2}></img>
            <p className=" font-bold font-dmsans  text-xl   leading-8  text-line">
            Revolutionizing Financial Freedom
            </p>
            <p className="font-normal font-dmsans  text-sm      text-line leading-5">
            Choosing TUMAANEX is not just a transaction; it's a step toward revolutionizing financial empowerment. Be part of a movement that seeks to redefine the essence of financial freedom, where every trade contributes to positive change.
            </p>
         </div>

          <div className="p-8">
          <img className=" w-11 h-11 " src={j4}></img>
            <p className=" font-bold font-dmsans  text-xl   leading-8  text-line">
            Join a Decentralized Community
            </p>
            <p className="font-normal font-dmsans  text-sm       text-line leading-5">
            By choosing TUMAANEX, you join a community that is decentralizing, empowering, and revolutionizing the world of digital asset trading. Your choice is not just about a platform; it's about being part of a movement that is reshaping the landscape of financial transactions.</p>

        </div>

      
        </div>


      </div>
      <div className="bg-newbgcolor h-max flex flex-col justify-center items-center    mx-10 my-10 border-1 border-solid rounded-xl ">
        <p className="p-5 text-line font-bold font-dmsans text-3xl leading-10">
          Our Commitment
        </p>

  
        <p className="flex py-4 mb-2  font-normal  font-dmsans text-xl w-4/5    justify-center whitespace-break-spaces text-line align-middle text-center  ">
        We are committed to empowering individuals and businesses alike to harness the potential of USDT trading. By leveraging the latest technology, security practices, and a dedicated support team, we are on a mission to create an inclusive trading environment that fosters growth and prosperity.
        </p>
        <p className="flex py-4 mb-2  font-normal  font-dmsans text-xl w-4/5    justify-center whitespace-break-spaces text-line align-middle text-center  ">
        Join us at TUMAANEX and become part of a vibrant community that believes in the transformative power of cryptocurrency trading. Whether you're a seasoned trader or new to the world of cryptocurrencies, we invite you to experience trading like never before.        </p>
        
      </div>
      <div className="   mx-10 my-10 border-1 border-solid rounded-xl">
      <P2P></P2P>
      </div> 
      
{/* <div className="   mx-10 my-10 border-1 border-solid  rounded-xl">
<Explore></Explore>
</div> */}

<div ref={sectionRef} className="   mx-10 my-10 border-1 border-solid rounded-xl">

<Faqs  ></Faqs>
</div>
<div className="   mx-10 my-10 border-1 border-solid rounded-xl">
    <GuideSection></GuideSection></div>

    <div className=" bg-maincolor    mx-10 border-1 border-solid rounded-xl ">
      <div className="bg-newbgcolor px-3 rounded-lg h-max  font-dmsans p-10  xl:p-1 2xl:p-1 lg:p-1 md:p-1 sm:p-1 ">
        <div className="  flex flex-col md:flex-row  gap-4">
          <div className="   h-max px-10 py-10 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full  w-full">
          <div className="bg-newbgcolor h-max flex flex-col justify-center items-center">
              <p className="font-bold text-2xl md:text-3xl text-line flex mb-10 justify-center ">
                Contact Us
              </p>
              <p className="text-xl leading-relaxed text-line  flex  font-dmsans  w-full align-middle  text-center ">
              Have questions or need assistance? Our support team is here to help. Reach out to us through our contact page, and we'll be delighted to assist you on your trading journey.
At TUMAANEX, we're not just a marketplace; we're a movement that's reshaping the future of trading in Asia and beyond. Join us today and be a part of the revolution!

              </p>
            </div>
          </div>
          <div className="col-span-3 lg:w-1/2 md:w-1/2 sm:w-1/2 xl:w-1/2 2xl:w-1/2">
            <div className="bg-newbgcolor p-6 md:p-8">
              <div className="space-y-4">
                <form className="py-2" onSubmit={formik.handleSubmit}>
                  <div className="py-4">
                    <input
                      {...formik.getFieldProps("name")}
                      id="name"
                      type="text"
                      className="w-full h-12 px-4 py-2 border font-dmsans rounded-lg bg-newbgcolor text-line placeholder-[#8e8e93] focus:outline-none"
                      placeholder="Enter your Name"
                    />
                  </div>
                  <div className="py-4">
                    <input
                      {...formik.getFieldProps("email")}
                      id="email"
                      type="email"
                      className="w-full h-12 px-4 py-2 border font-dmsans rounded-lg bg-newbgcolor text-line placeholder-[#8e8e93] focus:outline-none"
                      placeholder="Enter your Email Address"
                    />
                  </div>
                  <div className="py-4 mb-3">
                    <input
                      {...formik.getFieldProps("phone")}
                      id="phone"
                      type="phone"
                      pattern="[0-9]{7,}"
                      className="w-full h-12 px-4 py-2 border font-dmsans rounded-lg bg-newbgcolor text-line placeholder-[#8e8e93] focus:outline-none"
                      placeholder="Enter your Phone Number"
                    />
                  </div>

                  <div className="py-5 w-full">
                    <textarea
                      {...formik.getFieldProps("desc")}
                      id="desc"
                      type="desc"
                      className=" w-full h-44 px-4 py-4 border font-dmsans rounded-lg bg-newbgcolor text-line   placeholder-[#8e8e93] focus:outline-none"
                      placeholder="Explain the issue that you have been facing provide as much details as possible!"
                    />
                  </div>

                  <div className=" w-full flex justify-center">
                    
       
                    <button   className="w-36 h-16 rounded-xl font-dmsans border-1 font-medium  text-xl  text-newbgcolor bg-[#B28EFB] justify-center items-center">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      
      </div>
   
      <Footer>+</Footer>
      
    </React.Fragment>
  );
};
export default DashBoard;
