import { redirect, useParams } from "react-router-dom";
import React, { useLayoutEffect, useEffect } from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";

import { useState } from "react";
import { socket } from "../utils/global";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ToasterGen from "../Container/ToasterGen";
import postApiData from "../helpers/postApiData";
import getApiData from "../helpers/getApiData";
import { BiTimeFive } from "react-icons/bi";

const SingleOrder = () => {
  const { state } = useLocation();
  const { id, type } = state || { id: null, type: null };
  
  const navigate = useNavigate();

  const [number, setNumber] = useState();

  const [PaymentMethod, setPaymentMethod] = useState();
  const [BankAccountNumber, setBankAccountNumber] = useState();
  const [title, setTitle] = useState();
  const [userBankName, setUserBankName] = useState();

  const [BankName, setBankName] = useState();
  const [price, setPrice] = useState();
  const [quantity, setQuantity] = useState();
  const [amount, setAmount] = useState("");

  const [name, setName] = useState();
  const [merchantName,setMerchantName] = useState();
  const [bankname, setbankname] = useState("");
  const [accountnumber, setaccountnumber] = useState("");
  const [paymentmethod, setpaymentmethod] = useState("");
  const [verify, setVerify] = useState();
  const [notify, setNotify] = useState();
  const [paymentMethodSell, setPaymentMethodSell] = useState("");
  const [AccountNumberSell, setAccountNumberSell] = useState("");
  const [releaseTime, setReleaseTime] = useState("");
  const [currency, setCurrency] = useState();
  const [time_created, setTimeCreated] = useState("");

  async function deleteOrder() {
    const order = await postApiData("merchant/deleteOrder", { ID: id });

    navigate("/dashboard");
  }

  useEffect(() => {}, []);
  async function updateOrder() {
    const order = await postApiData("merchant/update_sell_order", {
      bankname: bankname,
      accountnumber: accountnumber == "" ? AccountNumberSell : accountnumber,
      pmethod: paymentmethod == "" ? paymentMethodSell[0] : paymentmethod,
      ID: id,
      bankname: userBankName,
      network: title,
      notify: "notified",
    });
  }

  useEffect(() => {
    if (!id || id == null || id == "") {
      navigate("/dashboard");
    }

    if (localStorage.getItem("user")) {
      const role = JSON.parse(localStorage.getItem("user")).role;
      if (role == "admin") {
        navigate("/notFound");
      }
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      let getdata = {};

      if (!id || id == null || id == "" || id == null) {
        navigate("/dashboard");
      }

      const userId = JSON.parse(localStorage.getItem("user"))._id;
      const response = await getApiData("auth/get_user_by_id", userId);

      if (response.user.paymentMethod == "" || !response.user.paymentMethod) {
        setPaymentMethodSell("");
      } else if (response.user.paymentMethod[0] == "undefined") {
        setPaymentMethodSell("");
      } else {
        setPaymentMethodSell(response.user.paymentMethod);
      }

      setUserBankName(response.user.bankname);
      setTitle(response.user.firstName);

      setAccountNumberSell(response.user.accountNumber);

      getdata = await getApiData("merchant/get_order_data", id);
      if (getdata.orders && getdata.orders.length > 0) {
        setAmount(getdata.orders[0].amount);
        setBankName(getdata.orders[0].merchant.BankName);
        setMerchantName(getdata.orders[0].merchant.name);
        setPaymentMethod(getdata.orders[0].merchant.PaymentMethod);
        setPrice(getdata.orders[0].merchant.Payment);
        setQuantity(getdata.orders[0].quantity);
        setCurrency(getdata.orders[0].currency);
        setNumber(getdata.orders[0].merchant.phoneNumber);
        setVerify(getdata.orders[0].verify);
        setNotify(getdata.orders[0].notify);
        setBankAccountNumber(getdata.orders[0].merchant.BankAccountNumber);
        setReleaseTime(getdata.orders[0].merchant.Ttime);
        setName(getdata.orders[0].merchant.banktitle);
        setTimeCreated(getdata.orders[0].date);
      } else {
        navigate("/dashboard");
      }
    }

    fetchData();
  }, [id]);

  useEffect(() => {
    var total_seconds = 60 * releaseTime;

    async function CheckTime() {
      total_seconds--;

      let notifynew = "";
      if (total_seconds > 0) {
        const response = await getApiData("merchant/get_order_data", id);

        if (response && response.orders.length > 0) {
          notifynew = response.orders[0].notify
            ? response.orders[0].notify
            : notify;
        }
      }

      if (notifynew == "notified" || notifynew == "completed") {
        clearInterval(interval);
      }
      if (total_seconds <= 0) {
        if (!notifynew && total_seconds == 0) {
          const value = await postApiData("merchant/delete_order", { ID: id });

          clearInterval(interval);
          navigate("/dashboard");
        } else {
          clearInterval(interval);
        }
      } else {
        console.log(total_seconds);
        
      }
    }

    if (releaseTime > 0) {
      var timeout = setTimeout(CheckTime, 1000);
      var interval = setInterval(CheckTime, 1000);
    }
  }, [releaseTime]);

  return (
    <>
      <ToasterGen></ToasterGen>

      <Header />
      <MenuHeader />

      <div className=" bg-background p-10">
        <div className=" bg-background text-line  ">
          <div className="px-5 py-5 container mx-auto rounded-sm border mt-10 font-dmsans text-xs  border-gray-500 border-opacity-40">
            <div className="px-7">
              <div className="flex grid-col-2  ">
                {type == "buy" ?
                <div>Pay the seller within {releaseTime} minutes</div>
                  : <div> You Will Receive Payment within {releaseTime} minutes</div>
                  
                  }



              </div>
            </div>
            <form className="py-1">
              <ol className="relative text-gray-500 border-l border-textColor dark:border-gray-700 dark:text-gray-400">
                <li className="mb-10 ml-6">
                  <span 
                  className={` ${
                    !notify ? "bg-textColor ring-textColor " : "bg-gray-200 ring-maincolor"
                  } absolute flex items-center justify-center w-4 h-4  rounded-full -left-2 ring-4   dark:ring-gray-900 dark:bg-gray-700`}>
     
                  
                    1
                  </span>
                  <h3 className="font-medium leading-tight">
                    <b> Confirm Order Info</b>
                  </h3>
                  <div className="bg-maincolor  p-4 relative px-3 py-5   break-words">
                    <h1 className="text-line whitespace-pre-wrap  font-dmsansbg-maincolor  font-bold text-sm  leading-8 ">
                      Order Created
                    </h1>
                  </div>

                  <div className="bg-maincolor ">
                    <table className="relativebg-maincolor w-fit ">
                      <thead className="hidden md:table-header-group h-10 lg:w-max xl:w-max 2xl:w-max md:w-max sm:w-max ">
                        <tr>
                          <th className="h-4 font-normal w-1/2 leading-5 text-sm text-newcolortext px-4 text-left">
                            Amount
                          </th>
                          <th className="h-4 font-normal w-1/2 leading-5 text-sm text-newcolortext text-left justify-start">
                            Price
                          </th>

                          <th className="h-4 font-normal w-2/4 leading-5 text-sm text-newcolortext px-4 text-left">
                            Quantity
                          </th>
                        </tr>
                      </thead>

                      <tbody className="bg-maincolor border-newcolortext border-solid">
                        <tr className="lg:table-row md:table-row xl:table-row 2xl:table-row grid w-full  h-20">
                          <td className="px-4">
                            <div className="flex grid-col-2 gap-2 md:justify-start sm:justify-normal lg:justify-normal xl:justify-normal 2xl:justify-normal justify-between">
                              <div className="flex grid-col justify-start">
                                <div className="col-span-1 lg:hidden sm:hidden md:hidden xl:hidden 2xl:hidden flex justify-end">
                                  <p className="">Amount</p>
                                </div>
                              </div>

                              <div className="flex col-span-2 justify-end items-end text-right ">
                                <p className="text-xs font-dmsans font-medium text-line">
                                  {type === "buy" ? (
                                    <p>
                                      {currency} {quantity}
                                    </p>
                                  ) : (
                                    <p> {quantity} USDT</p>
                                  )}
                                </p>
                              </div>
                            </div>
                          </td>

                          <td className="md:px-0 px-4">
                            <div className="flex grid-col-2 gap-2 md:justify-start sm:justify-normal lg:justify-normal xl:justify-normal 2xl:justify-normal justify-between">
                              <div className="flex grid-col justify-start">
                                <div className="col-span-1 lg:hidden sm:hidden md:hidden xl:hidden 2xl:hidden flex justify-end">
                                  <p className="">Price</p>
                                </div>
                              </div>
                              <div className="flex col-span-2 justify-end items-end text-right ">
                                <p className="text-xs font-dmsans font-medium text-line">
                                  {currency} {price}
                                </p>
                              </div>
                            </div>
                          </td>

                          <td className="md:px-0 px-4">
                            <div className="flex grid-col-2 gap-2 md:justify-start sm:justify-normal lg:justify-normal xl:justify-normal 2xl:justify-normal justify-between">
                              <div className="flex grid-col justify-start">
                                <div className="col-span-1 lg:hidden sm:hidden md:hidden xl:hidden 2xl:hidden flex justify-end">
                                  <p className="">
                                    {type == "sell" ? (
                                      <p>Total Quantity</p>
                                    ) : (
                                      <p> Receive Quantity</p>
                                    )}
                                  </p>
                                </div>
                              </div>

                              <div className="flex col-span-2 justify-end items-end text-right ">
                                <p className="text-xs font-dmsans font-medium text-line">
                                  {type == "sell" ? (
                                    <p>
                                      {amount && amount.toFixed(2)} {currency}
                                    </p>
                                  ) : (
                                    <p> {amount && amount.toFixed(2)} USDT</p>
                                  )}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <div className="flex  items-center justify-center  mt-1 mb-1 w-full px-3 md:hidden sm:hidden lg:hidden xl:hidden 2xl:hidden ">
                          <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                          <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                        </div>
                        <tr className="md:hidden sm:hidden lg:hidden xl:hidden 2xl:hidden ">
                          <td className="md:px-0 px-4 py-2">
                            <div className="flex grid-col-2 gap-2 md:justify-start sm:justify-normal lg:justify-normal xl:justify-normal 2xl:justify-normal ">
                              <div className="flex grid-col justify-start">
                                <div className="col-span-1 lg:hidden sm:hidden md:hidden xl:hidden 2xl:hidden flex justify-end">
                                  <p className="">Order Number</p>
                                </div>
                              </div>
                              <div className="flex col-span-2 justify-end items-end text-left whitespace-wrap ">
                                <p
                                  className="text-xs font-dmsans font-medium text-line whitespace-wrap   break-normal "
                                  style={{ "lineBreak": "anywhere" }}
                                >
                                  {id}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr className="md:hidden sm:hidden lg:hidden xl:hidden 2xl:hidden ">
                          <td className="md:px-0 px-4 py-2">
                            <div className="flex grid-col-2 gap-2 md:justify-start sm:justify-normal lg:justify-normal xl:justify-normal 2xl:justify-normal ">
                              <div className="flex grid-col justify-start">
                                <div className="col-span-1 lg:hidden sm:hidden md:hidden xl:hidden 2xl:hidden flex justify-end">
                                  <p className="">Time Created</p>
                                </div>
                              </div>
                              <div className="flex col-span-2 justify-end items-end text-left whitespace-wrap ">
                                <p
                                  className="text-xs font-dmsans font-medium text-line whitespace-wrap   break-normal "
                                  style={{ "line-break": "anywhere" }}
                                >
                                  {time_created}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>

                <li className="mb-10 ml-6">
                  
                  <span className={` ${
                          notify == "notified" ? "bg-textColor ring-textColor " : "bg-gray-200 ring-maincolor"
                        } absolute flex items-center justify-center w-4 h-4  rounded-full -left-2 ring-4   dark:ring-gray-900 dark:bg-gray-700`}>
                    2
                  </span>
                  <h3 className="font-medium leading-tight">
                    
                    {
                      type == "buy" ?
                    <b>Make Payment </b>:
                    <b>Receive Payment</b>
                    }

                  </h3>

                  <span className="font-dmsans font-normal text-line ">
                    {type == "buy" ? (
                      <p className=" font-medium   text-xs   leading-5 text-line  ">
                        Transfer the funds to the seller's account provided
                        below
                      </p>
                    ) : (
                      <p className=" font-medium   text-xs   leading-5 text-line  ">
                        Confirm that payment is made using buyer's real name  
                        {" ( " + merchantName + " )"}
                      </p>
                    )}
                  </span>

                  {type == "sell" ? (
                    <div className="flex grid-col-2 w-full">
                      <div className="w-1/2 border border-maincolor bg-background  border-opacity-10 p-2 hidden md:block">
                        <div className="flex grid-cols-2 items-center gap-2">
                          <div className="w-1 h-4 top-1 rounded bg-[#F0B90B]"></div>
                          <p className="text-sm font-dmsans font-medium text-line">
                            {paymentMethodSell || ""}
                          </p>
                        </div>
                      </div>

                      <div className="md:w-1/2 sm:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 border  border-maincolor border-opacity-10  p-2">
                        {/* <span className="text-xs text-line flex justify-start">
                          Payment Method
                        </span>
                        <div className="flex  text-line py-2 font-normal text-smjustify-start">
                          {paymentMethodSell || ""}
                        </div> */}

                        <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
                          <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                          <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                        </div>

                        {paymentMethodSell == "Bank Transfer" ? (
                          <>
                            <span className="text-xs text-line flex justify-start">
                              Payment Method
                            </span>
                            <div className="flex  text-line py-2 font-normal text-smjustify-start">
                              {paymentMethodSell || ""}
                            </div>

                            <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
                              <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                              <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                            </div>

                            <span className="text-xs text-line flex justify-start">
                              Title
                            </span>
                            <div className="flex  text-line py-2 font-normal text-smjustify-start">
                              {title || ""}
                            </div>

                            <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
                              <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                              <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                            </div>

                            <span className="text-xs text-line flex justify-start">
                              Bank Name
                            </span>
                            <div className="flex  text-line py-2 font-normal text-smjustify-start">
                              {userBankName || ""}
                            </div>

                            <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
                              <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                              <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <div className="flex justify-center"></div>

                        <span className="text-xs text-line flex justify-start">
                          Account Number
                        </span>
                        <div className="flex  text-line py-2 font-normal text-smjustify-start">
                          {AccountNumberSell || ""}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="p-2bg-maincolor">
                      <div className="flex grid-col-2 w-full">
                        <div className="w-1/2 border border-maincolor border-opacity-10 p-2 hidden md:block">
                          <div className="flex grid-cols-2 items-center gap-2">
                            <div className="w-1 h-4 top-1 rounded bg-[#F0B90B]"></div>
                            <p className="text-sm font-dmsans font-medium text-line">
                              {PaymentMethod}
                            </p>
                          </div>
                        </div>

                        <div className="md:w-1/2 sm:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 border  border-maincolor border-opacity-10  p-2">
                          <div className="  block  md:hidden ">
                            <div className="flex  grid-cols-2 items-center gap-2    ">
                              <div className="w-1 h-4 top-1 rounded bg-[#F0B90B]"></div>
                              <p className="text-sm font-dmsans font-medium text-line">
                                {PaymentMethod}
                              </p>
                            </div>
                          </div>
                          <div className="flex  items-center justify-center  mt-1 mb-1 w-full md:hidden">
                            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                          </div>

                          <span className="text-xs text-line flex justify-start">
                            Account Title
                          </span>
                          <div className="flex  text-line py-2 font-normal text-smjustify-start">
                            {name}
                          </div>
                          <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
                            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                          </div>

                          <span className="text-xs text-line flex justify-start">
                            Bank Name
                          </span>
                          <div className="flex  text-line py-2 font-normal text-smjustify-start">
                            {BankName}
                          </div>
                          <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
                            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                          </div>

                          {/* \
                    <span className="text-xs text-line flex justify-start">
                      Payment Method
                    </span>

                    <div className="flex bg-background text-line py-2 font-normal text-smjustify-start">
                      {PaymentMethod}
                    </div> */}

                          {/* <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
                      <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                      <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                    </div> */}

                          <span className="text-xs text-line flex justify-start">
                            Bank Account Number
                          </span>
                          <div className="flex  text-line py-2 font-normal text-sm justify-start">
                            {BankAccountNumber}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
                <li className="ml-6">
                  <span className={` ${
                          notify == "completed" ? "bg-textColor ring-textColor " : "bg-gray-200 ring-maincolor"
                        } absolute flex items-center justify-center w-4 h-4  rounded-full -left-2 ring-4   dark:ring-gray-900 dark:bg-gray-700`}>
           
                    3
                  </span>
                  <h3 className="font-medium leading-tight">Confirmation</h3>
                </li>
              </ol>

              <div className="px-7 text-xs  w-full h-full bg-background ">
                <div className="w-full">
                  {type === "buy" ? (
                    <div>
                      <p className="font-medium text-xs leading-5 text-line">
                        Notify Seller
                      </p>
                      <p className="text-xs leading-normal text-line">
                        After transferring the funds, click on the "Transferred,
                        Notify Seller" button.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <p className="font-medium text-xs leading-5 text-line">
                          Confirm payment is received.
                        </p>
                        <p className="text-xs leading-normal text-line">
                          After confirming the payment , be sure to click the
                          Payment Received button.
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                {/* <ul
                  data-te-stepper-init
                  className="relative m-0 flex list-none justify-between overflow-hidden p-0 transition-[height] duration-200 ease-in-out"
                >
                  <li
                    data-te-stepper-step-ref
                    data-te-stepper-step-active
                    className="w-1/5 flex-auto"
                  >
                    <div
                      data-te-stepper-head-ref
                      className="flex cursor-pointer items-center pl-2 no-underline after:h-px after:w-full after:flex-1 after:bg-[#e0e0e0] after:content-[''] after:opacity-50 focus:outline-none dark:after:bg-neutral-600"
                    >
                      <span
                        data-te-stepper-head-icon-ref
                        className={` ${
                          !notify ? "bg-textColor" : "bg-gray-200"
                        }  my-7 flex h-6 w-6 items-center justify-center rounded-full  text-sm font-medium text-[#40464f]`}
                      >
                        1
                      </span>
                    </div>
                    <div
                      data-te-stepper-content-ref
                      className="absolute w-full p-4 transition-all duration-500 ease-in-out opacity-50"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </li>

                  <li
                    data-te-stepper-step-ref
                    data-te-stepper-step-active
                    className="w-1/5 flex-auto"
                  >
                    <div
                      data-te-stepper-head-ref
                      className="flex cursor-pointer items-center pl-2 no-underline after:h-px after:w-full after:flex-1 after:bg-[#e0e0e0] after:content-[''] after:opacity-50 focus:outline-none dark:after:bg-neutral-600"
                    >
                      <span
                        data-te-stepper-head-icon-ref
                        className={` ${
                          notify == "notified" ? "bg-textColor" : "bg-gray-200"
                        }  my-7 flex h-6 w-6 items-center justify-center rounded-full  text-sm font-medium text-[#40464f]`}
                      >
                        2
                      </span>
                    </div>
                    <div
                      data-te-stepper-content-ref
                      className="absolute w-full p-4 transition-all duration-500 ease-in-out opacity-50"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </div>
                  </li>

                  <li data-te-stepper-step-ref className="w-1/5 flex-auto">
                    <div
                      data-te-stepper-head-ref
                      className="flex cursor-pointer items-center dark:before:bg-neutral-600 dark:after:bg-neutral-600"
                    >
                      <span
                        data-te-stepper-head-icon-ref
                        className={` ${
                          notify == "completed" ? "bg-textColor" : "bg-gray-200"
                        } my-7 flex h-6 w-6 items-center justify-center rounded-full  text-sm font-medium text-[#40464f]`}
                      >
                        3
                      </span>
                    </div>
                  </li>
                </ul> */}

                {/* <ul
                  data-te-stepper-init
                  className="relative m-0 flex list-none justify-between overflow-hidden p-0 transition-[height] duration-200 ease-in-out mb-10"
                >
                  <li
                    data-te-stepper-step-ref
                    data-te-stepper-step-active
                    className="w-1/5 flex-auto"
                  >
                    <span className="font-dmsans font-normal text-line ">
                      {type == "buy" ? (
                        <p className=" font-medium   text-xs   leading-5 text-line  ">
                          Transfer Payment to Seller
                        </p>
                      ) : (
                        <p className=" font-medium   text-xs   leading-5 text-line  ">
                          Buyer Notified
                        </p>
                      )}
                    </span>
                  </li>

                  <li
                    data-te-stepper-step-ref
                    data-te-stepper-step-active
                    className="w-1/5 flex-auto"
                  >
                    <span className="font-dmsans  font-normal  text-xs   text-line ">
                      Notified
                    </span>
                  </li>

                  <li
                    data-te-stepper-step-ref
                    data-te-stepper-step-active
                    className="w-1/5 flex-auto"
                  >
                    <span className="font-dmsans font-normal  text-xs   text-line ">
                      Completed
                    </span>
                  </li>
                </ul> */}

                <div className="flex justify-center   p-8 ">
                  {verify == false || !verify ? (
                    <div className="justify-center ">
                      {type == "buy" ? (
                        <button
                          type="submit"
                          className="relative justify-center mx-2 my-6 text-sm  w-44 border h-10  bg-textColor text-line border-textColor text-md p-2 rounded-lg mb-6 hover:bg-textColor hover-text-white "
                          onClick={(e) => {
                            e.preventDefault();
                            deleteOrder();
                          }}
                        >
                          Cancel Order
                        </button>
                      ) : (
                        <></>
                      )}
                      <button
                        type="submit"
                        className="relative justify-center mx-2 my-6 text-sm  w-44 border h-10  bg-[#0ECB81] text-line border-[#0ECB81] text-md p-2 rounded-lg mb-6 hover:bg-[#0ECB81] hover-text-white "
                        onClick={(e) => {
                          e.preventDefault();

                          window.open("https://wa.me/" + number, "_blank");
                        }}
                      >
                        Whats App Message
                      </button>


                      { !notify ? 
                      <button
                        type="submit"
                        className="relative justify-center mx-2 my-6 text-sm  w-fit border h-10  bg-textColor text-line border-textColor text-md p-2 rounded-lg mb-6 hover:bg-textColor hover-text-white "
                        onClick={(e) => {
                          e.preventDefault();

                          const userId = JSON.parse(
                            localStorage.getItem("user")
                          )._id;
                          socket.emit("post_data", {
                            title:
                              "Notification  to Verify Generated for Order Id :  " +
                              id,
                            userId: userId,
                          });

                          if (type == "sell") {
                            updateOrder();
                          } else {
                            navigate("/dashboard");
                         
                            postApiData("merchant/update_buy_order", {
                              ID: id,
                            });
                          }
                        }}
                      >
                        {type == "buy"   ? (
                          <p className="w-fit break-words text-xs"> Transfered, Notify Seller</p>
                        ) : (


                          <p>Payment Received</p>
                        )}
                      </button>:
                      <></>

                      }


                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleOrder;
