import React from 'react'
import { useState } from 'react';

import { AiOutlinePlus } from "react-icons/ai";

import { AiOutlineMinus } from "react-icons/ai";

const GuideSection = () => {

    
  const [martusd5, setmartusd5] = useState(1);
  
  const [value,setValue]= useState("");
  const [heading,setHeading] = useState("Introduction");
  return (
    <div>    <div className="bg-maincolor h-max  md:p-10 p-0 ">
    <div className="  flex flex-col md:flex-row  gap-0">
      <div className="   h-max px-10 py-10  2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2">
        <div className="flex flex-col gap-2 ">
          <div className="flex  py-10  ">
            <p className="text-line font-bold text-3xl font-dmsans">
              Guide Section
            </p>
          </div>

          <div className="flex grid-col gap-2">
            <div className="flex justify-between col-span-3">
              <div>
              <p className={` ${martusd5 == 1 ? "text-[#B28EFB]" : "text-line"} mt-2 font-dmsans text-md `}>

                 Introduction 
                </p>
              </div>
            </div>
            <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
              {martusd5 == 1 ? (
                <AiOutlineMinus
                  className="text-line"
                  onClick={() => {
                    setmartusd5(0);
                    setHeading("");
                  }}
                />
              ) : (
                <AiOutlinePlus
                  className="text-line"
                  onClick={() => {
                    setmartusd5(1);
                    setHeading("Introduction");

                  }}
                />
              )}
            </div>
          </div>
          <div className="w-full">
            <div
              className={`text-line w-4/5  font-dmsans text-md whitespace-break-spaces leading-6 relative ${
                martusd5 == 1 ? "" : "h-0 overflow-hidden"
              }`}
            >


            </div>
          </div>

          <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
          </div>

          <div className="flex grid-col gap-2">
            <div className="flex justify-between col-span-3">
              <div>
              <p className={` ${martusd5 == 2 ? "text-[#B28EFB]" : "text-line"} mt-2 font-dmsans text-md`}>
                  Basic Terminologies
                </p>
              </div>
            </div>
            <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
              {martusd5 == 2 ? (
                <AiOutlineMinus
                  className="text-line"
                  onClick={() => {
                    setmartusd5(0);
                    setHeading("");

                  }}
                />
              ) : (
                <AiOutlinePlus
                  className="text-line"
                  onClick={() => {
                    setmartusd5(2);
                    setHeading("Basic Terminologies");
                    
                  }}
                />
              )}
            </div>
          </div>
         

          <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
          </div>

          <div className="flex grid-col gap-2 " >
            <div className="flex justify-between col-span-3  ">
              <div>
              <p className={` ${martusd5 == 3 ? "text-[#B28EFB]" : "text-line"} mt-2 font-dmsans text-md`}>
                  Exploring the Marketplace
                </p>
              </div>
            </div>
            <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
              {martusd5 == 3 ? (
                <AiOutlineMinus
                  className="text-line"
                  onClick={() => {
                    setmartusd5(0);
                    setHeading("");
                  }}
                />
              ) : (
                <AiOutlinePlus
                  className="text-line"
                  onClick={() => {
                    setmartusd5(3);
                    setHeading("Exploring the Marketplace");
                    
                  }}
                />
              )}
            </div>
          </div>
        

          <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
          </div>

          <div className="flex grid-col gap-2">
            <div className="flex justify-between col-span-3">
              <div>
              <p className={` ${martusd5 == 4 ? "text-[#B28EFB]" : "text-line"} mt-2 font-dmsans text-md`}>
                  How to buy and Sell
                </p>
              </div>
            </div>
            <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
              {martusd5 == 4 ? (
                <AiOutlineMinus
                  className="text-line"
                  onClick={() => {
                    setmartusd5(0);
                    setHeading("");
                  }}
                />
              ) : (
                <AiOutlinePlus
                  className="text-line"
                  onClick={() => {
                    setmartusd5(4);
                    setHeading("How to buy and Sell");
                    
                  }}
                />
              )}
            </div>
          </div>
         

          <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
            <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
          </div>

          <div className="flex grid-col gap-2">
            <div className="flex justify-between col-span-3">
              <div>
              <p className={` ${martusd5 == 5 ? "text-[#B28EFB]" : "text-line"} mt-2 font-dmsans text-md`}>
                  How to Deposit and Withdraw
                </p>
              </div>
            </div>
            <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
              {martusd5 == 5 ? (
                <AiOutlineMinus
                  className="text-line"
                  onClick={() => {
                    setmartusd5(0);
                    setHeading("");
                  }}
                />
              ) : (
                <AiOutlinePlus
                  className="text-line"
                  onClick={() => {
                    setmartusd5(5);
                    setHeading("How to Deposit and Withdraw");
                  }}
                />
              )}
            </div>
          </div>
        
        </div>
      </div>

      <div className="hidden items-center justify-center mt-3 mb-3   2xl:flex xl:flex lg:flex md:flex sm:flex">
        <div className="h-96 border-l bg-line  opacity-5 border-line"></div>
      </div>

      <div className="py-4 px-2 md:py-5 md:px-4 w-full">
      <div className="flex flex-col gap-2 md:gap-4 md:p-6">
<div className="flex justify-center   w-full">
  <div>
    <p className="text-line font-bold mt-2 py-2 md:py-4 font-dmsans text-2xl  text-center justify-center ">
      {heading}
    </p>
  </div>
</div>

{ martusd5 == 1 ? 
<div className="m-0 p-0">
<p className="text-line  leading-relaxed font-dmsans  text-md mb-6  text-center">
TUMAANEX emerges as a cornerstone of Asia's dynamic cryptocurrency landscape, providing a robust platform for secure and streamlined USDT transactions. As the largest P2P marketplace in the region, TUMAANEX fosters an expansive network of traders, ensuring liquidity and a diverse range of trading options. Our platform's emphasis on security, transparency, and user-friendly functionality positions us at the forefront of Asia's burgeoning cryptocurrency market.
</p>

<p className="text-line leading-relaxed font-dmsans text-md mb-6   text-center">
With an unwavering commitment to innovation, TUMAANEX continuously refines its features and services to meet the evolving demands of the cryptocurrency community. Our mission is to simplify the complexities of cryptocurrency trading, making it accessible to a wider audience across Asia. Join us in revolutionizing the future of cryptocurrency transactions on TUMAANEX.
</p>




</div>: martusd5 == 2 ? 

<div class="  leading-6 text-line ">
<h1 class="mx-0 mt-0 mb-4 text-base font-bold text-line lg:mt-0 md:mt-32">
Register:
</h1>

<p class="m-0 text-md leading-7 text-line ">
Follow these simple steps to register on TUMAANEX and have your cryptocurrency account:
</p>

<ul class="list-disc ml-6 mt-4 text-md font-dmsans text-line">

<li className="text-md font-dmsans">

  <ul class="list-circle list-disc   mt-2 text-md font-dmsans  text-line ">
  <li >
  Go to tumaanex.com and click "Create Account."
</li>

<li>
  Now, you just have to add your information in the boxes on your screen.</li>
    <li>Use your email (Note: This Email can't be changed again for your security purposes, so make sure to keep your email safe).</li>
    <li>Create a unique username.</li>
    <li>Enter your complete valid address as it might be used for verification later on.</li>
    <li>Enter your phone number without your country code. e.g. (000000000).</li>
    <li>Create your password with at least 8 or more characters. (For greater security, use an uppercase letter, a lowercase letter, a number, and one special character like; @ ! # etc.)</li>
  </ul>
</li>
<li className="text-md font-dmsans">
  In this step, you must check the inbox of the email you used for registration, there you will find an email from TUMAANEX that includes the verification code that the page will request. (If you cannot find the email, we recommend you look in the Spam folder).
</li>
<li className="text-md font-dmsans">
  Now, you only have to enter the verification code, and your TUMAANEX account will be created.
</li>
</ul>


<div class="mt-4  text-md font-dmsans text-line">

<h1 class="mx-0 mt-0 mb-4 text-base font-bold text-line lg:mt-0 md:mt-32">
  
  Login and Sign off:</h1>
<span class="text-base"></span>
</div>
<span class="text-md font-dmsans text-line">To log into your TUMAANEX account from a new device, you just have to follow these simple steps:</span>

<ul class="list-disc ml-6 mt-4 text-md font-dmsans text-line">
<li><span class="text-line">Go to tumaanex.com and click on "Login" located in the top right corner right next to "Create an Account"</span></li>
<li><span class="text-line">Enter your email and password and click on login</span></li>
<li><span class="text-line ">You will receive a verification code on your provided Gmail. Enter that code on TUMAANEX and that's it.</span></li>
<li><span class="text-line">You are logged in to your account.</span></li>
<li><span class="text-line">Now even if you close your browser your account will stay logged in. You have to manually logout your account if you want by clicking on your profile icon on the top right corner of the home page and then "Logout".</span></li>
</ul>




</div>:martusd5 == 3 ? <div>

  
<div class="mt-2 ml-4 text-md font-dmsans ">
<strong class="text-md font-arial text-line">Funding Wallet:</strong>
<span class="text-md font-arial text-line mx-2">You can access your Funding Wallet by clicking on your profile and then Funding Wallet, and here you can find all the amazing options that you can use!</span>
</div>

<div class="mt-2 ml-4 text-md font-dmsans  ">
<strong class="text-md font-arial text-line">Deposit Option:</strong>
<span class="text-md font-arial text-line mx-2">This button lets you deposit USDT to your wallet within TUMAANEX from other crypto wallets.</span>
</div>

<div class="mt-2 ml-4 text-md font-dmsans ">
<strong class="text-md font-arial text-line">Withdraw Option:</strong>
<span class="text-md font-arial text-line mx-2">With this option, you can send funds from your wallet in TUMAANEX to other users or crypto wallets.</span>
</div>

<div class="mt-2 ml-4 text-md font-dmsans">
<strong class="text-md font-arial text-line">Transaction History:</strong>
<span class="text-md font-arial text-line mx-2">In this space, you can view the history of transactions made or received in your account.</span>
</div>

<div class="mt-2 ml-4  text-md font-dmsans ">
<strong class="text-md font-arial text-line">Profile Section:</strong>
<span class="text-md font-arial text-line mx-2">If you press this button, you will enter your profile settings, where you can change certain aspects, such as your full name, payment methods, language settings, and more.</span>
</div>

<div class="mt-2 ml-4 text-md font-dmsans">
<strong class="text-md font-arial text-line">Balance:</strong>
<span class="text-md font-arial text-line mx-2">Here, you can view the total balance of your account.</span>
</div>


</div>: martusd5 == 4 ? <div>

<h2 class="mx-0 mt-0 mb-4 text-base font-bold text-line lg:mt-0 md:mt-32">
Decoding an Order</h2>
    <p className='text-line font-dmsans font-normal text-md'>Before we teach you how to make transactions in the TUMAANEX P2P, you must first know the information you will see in each order. This will help you know the best option for you and avoid transaction errors.</p>
    <ul class="list-circle list-disc   mt-2 text-md font-dmsans mb-4  text-line ">
        <li><strong>Price:</strong> Here, you can see the price of each cryptocurrency in your selected FIAT currency.</li>
        <li><strong>Limit/Available:</strong> This field indicates the Minimum and Maximum of cryptocurrencies you can buy or sell in the trade.
            <p><em>Note: The MINIMUM and MAXIMUM purchase/sale value varies according to each Merchant.</em></p>
        </li>
        <li><strong>Payment method:</strong> Here, you will see the payment methods that the merchant has available for the exchange.
            <p><em>Note: Before engaging in a transaction with a TUMAANEX Merchant, you must ensure that the requested Payment Method is available to you.</em></p>
        </li>
        <li><strong>User:</strong> At the left of each order, you will see the name of the Merchant who has published it. Next to the name, you will see the Merchant's score, which indicates the quality of the orders placed by the user.</li>
        <li><strong>Buy/Sell button:</strong> If the order seems like a good offer, press Buy/Sell to access the menu to place the desired amount to proceed with the operation.</li>
    </ul>

    <h2 class="mx-0  text-base font-bold text-line lg:mt-0 md:mt-32">
Buy or Sell through Orders</h2>
    <p className='text-line font-dmsans font-normal text-md '>If you want to buy USDTs for your wallet within TUMAANEX or, you want to sell part of your balance and receive your local currency, you can do so through the orders published within our Marketplace. Follow these simple steps to do it correctly:</p>
    <ol class="list-circle list-disc   mt-2 text-md  font-dmsans  text-line ">
        <li>Go to the Home Page of TUMAANEX after login. You will automatically land on Home Page after signing in where you can see all the available orders from different merchants.</li>
        <li>In the Currency Tab, indicate the currency you will send and the one you want to obtain. (E.g. Chinese Yuan, Irani Riyal, Bangladeshi Taka, Indian Rupees, etc.)</li>
        <li><strong>To Buy USDTs:</strong> Now you just have to search among the available ads and select the one that seems to you the best offer.</li>
        <li>When you have selected an order, a menu will open where you will see more information about the ad and the advertiser. In addition, you will have 1 field available that you can fill in as follows:
            <ul>
                <li><strong>"You will pay":</strong> If you already know the exact amount you want to exchange from the currency you have, you can enter the amount here.</li>
                <li><strong>"You will receive":</strong> On the other hand, you will see the amount in USDTs that you will receive upon successfully completing that order.
                    <p><em>Note: You only have to enter the amount in your local currency that you are willing to pay; the other will get filled automatically.</em></p>
                </li>
            </ul>
        </li>
        <li>Once you have placed the amount, press "Buy" at the bottom of your screen.</li>
        <li>You will now see a new page which contains all the information about your order as well as the payment details of the Merchant and you can also open a Whatsapp chat with the seller. These chats are supervised by TUMAANEX so we can make sure smooth processing of your order and help you in case of some issue.</li>
        <li>You must make the payment or deposit to the bank addresses specified by the Merchant.
            <p ><em>Note: You must be careful when making the payment. TUMAANEX is not responsible for payments made to the wrong bank addresses.</em></p>
        </li>
        <li>After making the payment to the address specified by the user, you must press "Transferred, Notify Seller." This will notify the counterparty that you have already made the deposit and must approve the release of the USDTs. Once the order is complete, the balance will be automatically added to your Funding Wallet.</li>
        <li><strong>To Sell USDTs:</strong> You must add a payment method by going to your profile in which the Merchant must deposit the money and wait while he performs the operation.
            <p><em>Notice: You must be careful when placing your payment addresses. TUMAANEX is not responsible for payments made to the wrong bank addresses.</em></p>
        </li>
        <li>Once the counterparty indicates it has already paid, you can release the cryptocurrencies. (We recommend that you verify the payment in your bank account before approving the release.)</li>
    </ol>


</div> : martusd5 == 5  ?<div>
<p className="text-line  leading-relaxed font-dmsans  text-md mb-6  text-center">
To Deposit USDTs to your TUMAANEX account. Simply just go to your Funding Wallet page and click on deposit now enter your desired amount that u wish to deposit and then you will be given your wallet’s BEP- 20 address where you must deposit the exact amount in USDTs within 1 hour and your balance will be automatically updated!
</p>

<p className="text-line leading-relaxed font-dmsans text-md mb-6   text-center">

If you are to withdraw your available balance from your wallet. Click on Withdraw button and you will be displayed a new page where you have to enter your other BEP-20 wallet address or of any P2P Marketplace address. But make sure that it works on the BEP-20 network and can receive USDTs. Then enter the amount that you wish to Withdraw from your Balance and click on Withdraw. Your withdraw request is created and it can take some minutes to some hours depending on the network availability to successfully be completed!
If you still have some queries or facing some issues feel free to contact us through Contact Us section and our team will assist you shortly!
</p>




</div>:<></>
}




</div>
</div>


    </div>
  </div>
</div>
  )
}

export default GuideSection