import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";


export const merchantData = createAsyncThunk(
  "merchant",

  async (payload, { rejectWithValue }) => {
    try {
      
      const request = await axios.get(env.API_URL + "merchant/get_merchant",{
  params:payload});
      const response = await request.data;
      
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message); 
    }
  }
);

const merchantSlice = createSlice({
  name: "merchant",
  initialState: {
    merchants : [],
    

    loading: false,
    error: null,
  },
  reducers: {
    addMerchant: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(merchantData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(merchantData.rejected, (state, action) => {
  
        state.loading = true;
      })
      .addCase(merchantData.fulfilled, (state, action) => {
          state.loading = false;
          state.merchants = action.payload;
       
        }
      );
  },
});
export const { addMerchant } = merchantSlice.actions;
export default merchantSlice.reducer;

