import React, { useState } from "react";
import one from "../assets/1.png";
import two from "../assets/2.png";
import three from "../assets/3.png";
import four from "../assets/4.png";
import five from "../assets/5.png";


const P2P = () => {
  const [panel, setPanel] = useState(true);
  return (
    <div className="w-full h-full bg-newbgcolor border-solid rounded-xl ">
      <div className="md:p-20  p-5 flex flex-col sm:flex-row gap-4 justify-between ">
        <div className="">
          <h1 className="font-bold font-dmsans flex w-  full text-xl  leading-relaxed text-line">
            HOW P2P WORKS
            
          </h1>
        </div>
        <div className="">
          <div className="flex grid-col-2 gap-4">
            <div>
              <button
                onClick={() => {
                  setPanel(true);
                }}
                className={`font-normal font-dmsans   p-2  text-sm rounded ${
                  panel === true
                    ? "bg-[#B28EFB] text-background"
                    : "bg-transparent text-line"
                }`}
              >
                Buy USDT
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  setPanel(false);
                }}
                className={`font-normal font-dmsans  text-sm   p-2 rounded ${
                  panel === false
                    ? "bg-[#B28EFB] text-background "
                    : "bg-transparent text-line "
                }`}
              >
                Sell USDT
              </button>
            </div>
          </div>
        </div>
      </div>

{ panel == true ? 
      <div className="w-full p-4 sm:p-20 flex flex-col sm:flex-row gap-4">
        <div className="p-4 sm:w-1/3 rounded-2xl border border-solid border-[#382D47] flex flex-col items-center">
          <img src={one} className="w-10 h-10 mt-4 sm:mt-10" alt="Your Image" />

          <p className="mt-2 sm:mt-4 text-base sm:text-lg  font-medium text-line font-dmsans">
            Place an Order
          </p>

          <p className="flex mt-2  text-[#707A8A] font-dmsans text-xs sm:text-sm  sm:px-20 font-normal leading-relaxed  justify-center whitespace-break-spaces text-center ">
            Once you place a P2P order, the crypto asset will be escrowed by
            TUMAANEX.
          </p>
        </div>

        <div className="p-4 sm:w-1/3 rounded-2xl border border-solid border-[#382D47] flex flex-col items-center">
          <img src={two} className="w-10 h-10 mt-4 sm:mt-10" alt="Your Image" />

          <p className="mt-2 sm:mt-4 text-base sm:text-lg font-medium text-line font-dmsans">
            Pay the Seller
          </p>

          <p className="flex mt-2  text-[#707A8A] font-dmsans text-xs sm:text-sm  sm:px-20 font-normal leading-relaxed  justify-center whitespace-break-spaces text-center ">
            Send money to the seller via the suggested payment methods. Complete
            the fiat transaction and click "Transferred, notify seller" on
            TUMAANEX.
          </p>
        </div>
        <div className="p-4 sm:w-1/3 rounded-2xl border border-solid border-[#382D47] flex flex-col items-center">
          <img src={three} className="w-10 h-10 mt-4 sm:mt-10" alt="Your Image" />

          <p className="mt-2 sm:mt-4 text-base sm:text-lg font-medium text-line font-dmsans">
            Get your Crypto
          </p>

          <p className="flex mt-2 text-[#707A8A] font-dmsans text-xs sm:text-sm sm:px-4 md:px-20 font-normal leading-relaxed justify-center whitespace-pre-wrap text-center">
            Once the seller confirms receipt of money, the escrowed crypto will
            be released to you.
          </p>
        </div>
      </div> :<div className="w-full p-4 sm:p-20 flex flex-col sm:flex-row gap-4">
        <div className="p-4 sm:w-1/3 rounded-2xl border border-solid border-[#382D47] flex flex-col items-center">
          <img src={two} className="w-10 h-10 mt-4 sm:mt-10" alt="Your Image" />

          <p className="mt-2 sm:mt-4 text-base sm:text-lg font-normal text-line font-dmsans">
            Place an Order
          </p>

          <p className="flex mt-2  text-[#707A8A] font-dmsans text-xs sm:text-sm  sm:px-20 font-normal leading-relaxed  justify-center whitespace-break-spaces text-center ">
          After you place an order, your crypto will be
escrowed by TUMAANEX.
          </p>
        </div>

        <div className="p-4 sm:w-1/3 rounded-2xl border border-solid border-[#382D47] flex flex-col items-center">
          <img src={five} className="w-10 h-10 mt-4 sm:mt-10" alt="Your Image" />

          <p className="mt-2 sm:mt-4 text-base sm:text-lg font-normal text-line font-dmsans">
          Confirm the Payment
          </p>

          <p className="flex mt-2  text-[#707A8A] font-dmsans text-xs sm:text-sm  sm:px-20 font-normal leading-relaxed  justify-center whitespace-break-spaces text-center ">
          Check the transaction record in the given payment
account, and make sure you receive the money sent
by the buyer.
          </p>
        </div>
        <div className="p-4 sm:w-1/3 rounded-2xl border border-solid border-[#382D47] flex flex-col items-center">
          <img src={four} className="w-10 h-10 mt-4 sm:mt-10" alt="Your Image" />

          <p className="mt-2 sm:mt-4 text-base sm:text-lg font-normal text-line font-dmsans">
          Release Crypto
          </p>

          <p className="flex mt-2 text-[#707A8A] font-dmsans text-xs sm:text-sm sm:px-4 md:px-20 font-normal leading-relaxed justify-center whitespace-pre-wrap text-center">
          Once you confirm the receipt of money, release
crypto to the buyer on TUMAANEX.
          </p>
        </div>
      </div>

}



    </div>
  );
};

export default P2P;
