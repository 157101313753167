import React, { useLayoutEffect, useMemo } from "react";
import { useState, useRef, useEffect } from "react";
import logo from "../../src/assets/logo2.png";
import env from "react-dotenv";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useLoginValid from "../utils/useLoginValid";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import auth from "../assets/auth2.png";
import {BiLogOut} from 'react-icons/bi';
import {AiOutlineDownload} from 'react-icons/ai';
import diamond from '../assets/diamond.png'
import twit from '../assets/twit.png'
import {FaUserAlt} from 'react-icons/fa';
import {AiFillWallet} from 'react-icons/ai';
import bell from "../assets/SVG.png";
import { socket } from "../utils/global";
import getApiData from "../helpers/getApiData";


const Header = ({handleFaqClick}) => {
  const navigate = useNavigate();

  const [user, setuserData] = useLoginValid(null);
  const location = useLocation();
  const Menu = ["Profile Settings", "Funding Wallet"];

  const navMenu = ["FAQs","Deposit","Funding Wallet"];

  const icons = [<FaUserAlt/>,<AiFillWallet></AiFillWallet>]
  const [open, setOpen] = useState(false);
  const menuRef = useRef();
  const imgRef = useRef();

  const [openbell, setOpenBell] = useState(false);
  const menuRefbell = useRef();
  const imgRefbell = useRef();
  const [feeds, setFeeds] = useState([]);
  const [isNewFeed, setIsNewFeed] = useState(false);
  const [showAllFeeds, setShowAllFeeds] = useState(false);
  const numberOfFeeds = 5;
  const displayedFeeds = showAllFeeds ? feeds : feeds.slice(0, numberOfFeeds);
  const [filename, setFileName] = useState("");
  const [ContentType, setContentType] = useState("");

  useEffect(() => {
    socket.emit("initial_data");
    socket.on("get_data", getData);
    socket.on("change_data", changeData);
    return () => {
      socket.off("get_data");
      socket.off("change_data");
    };
  }, []);

  const getData = (feeds) => {
    if (feeds.length && feeds.some((feed) => feed.read === false)) {
      setIsNewFeed(true);
    } else {
      setIsNewFeed(false);
    }
    setFeeds(feeds);
  };

  const changeData = () => socket.emit("initial_data");
  const getLinkForMenu = (menu) => {
    switch (menu) {
      case "About":
        return "/profile";
      case "Deposit":
        return "/deposit";
      case "Funding Wallet":
        return "/funding";


      default:
        return "#"; // or some default link
    }
  };


  const handleOption = (menu) => {
    if (menu === "Logout") {
      let user = localStorage.getItem("user");

      const userValues = { email: user.email };

      const value = location.pathname;

        const logout = axios
          .post(env.API_URL + "auth/logout", userValues, {})
          .then((response) => {
            if (response.status == 200) {
              localStorage.removeItem("user");
              navigate("/home");
            }
          })

          .catch((err) => {});

    }
    if (menu === "Profile Settings") {
      navigate("/profile");
    }
    if (menu == "Funding Wallet" && user.role != "admin") {
      navigate("/funding");
    }
    if (menu === "Menu") {
      navigate("/menu_list");
    }
    if (menu === "Screens") {
      navigate("/screen_list");
    }
    if (menu === "Authenticate Screens") {
      navigate("/auth_list");
    }
    if (menu === "ADD MERCHANTS") {
      navigate("/user_list");
    }
  };

  useLayoutEffect(() => {
    return () => {
      // if (localStorage.getItem("user") == null) {
      //   navigate("/login");
      // } else {
      //   setuserData();
      // }
    };
  });

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (!userData) {
      navigate("/");
    } else {
      setuserData(JSON.parse(userData));
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (localStorage.getItem("user")) {
        const userData = JSON.parse(localStorage.getItem("user"));

        const response = await getApiData(
          "auth/get_avatar/" + userData._id,
          ""
        );
        if (response && response.user) {
          setFileName(response.user[0].avatar.filename);
          setContentType(response.user[0].avatar.contentType);
        }
      }
    }

    fetchData();
  }, []);

  

  window.addEventListener("click", (e) => {
    if (e.target !== menuRef.current && e.target !== imgRef.current) {
      setOpen(false);
    }
    if (e.target !== menuRefbell.current && e.target !== imgRefbell.current) {
      setOpenBell(false);
    }
  });

  return (
    <>
      <nav className="bg-maincolor border-b border-gray-300">
        <div className="mx-5  max-w-8xl ">
          <div className="relative flex h-16 items-center justify-start">
            <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-start">
              <div className="flex  items-center rounded-full">
                <Link to="/dashboard">
                  <img
                    className=" h-8   2xl:w-auto xl:w-auto sm:w-auto lg:w-auto md:w-auto    w-min  lg:block mx-2"
                    src={logo}
                    alt="MART USD"
                  />
                </Link>
              </div>
            </div>

            <ul className=" sm:ml-5 md:flex hidden gap-4 z-50">
  {navMenu.map((menu, index) => 
    user && user.role === "user" ? (
      <li className="font-dmsans md:text-md text-xs  md:mr-2 mr-10   px-6 text-line" key={index}>
           <Link to={getLinkForMenu(menu)} className="">
      {menu === 'FAQs' ? (
        <button onClick={handleFaqClick}> FAQs</button>
      ) : (
        <span>{menu}</span>
      )}
    </Link>



      </li>
    ) : (
      <React.Fragment key={index}></React.Fragment>
    )
  )}


</ul>

            <div className=" absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto  ">
              <div className="relative ml-44">
                <div className="flex bg-maincolor grid-flow-col grid-cols-2 gap-2">
                  <div className="relative">
                  <div className="bg-maincolor text-line text-center">
{/* 
                  <button
                  onClick={() => {
                    navigate("/deposit");
                  }}
                  className="flex p-2 h-8  rounded-sm text-background font-dmsans font-normal  text-xs bg-textColor  mb-2 w-full"
                >
                  <AiOutlineDownload className="mt-1"></AiOutlineDownload>
                  Deposit
                </button> */}

                  </div>

                  

                    {/* <div className="bg-background text-white text-center">
                      <p>
                        <img
                          className="h-7 w-7 mt-0"
                          src={bell}
                          ref={imgRefbell}
                          onClick={() => setOpenBell(!openbell)}
                        ></img>
                        {openbell && (
                          <div
                            ref={menuRefbell}
                            className="bg-[#221633]  font-dmsans border p-2  border-maincolor w-fit absolute h-max shadow-lg z-20 flex-wrap top-10 right-1 overflow-y-auto max-h-72 scrollbar scrollbar-thumb-maincolor "
                          >
                            <ul>
                              {displayedFeeds.map((feed, index) =>
                                feed.userId === user._id ||
                                user.role === "admin" ? (
                                  <li
                                    onClick={() => {
                                      setOpenBell(false);
                                      handleOption(feed);
                                    }}
                                    className="font-dmsans text-xs p-3 bg-[#2B223F]  w-full  cursor-pointer  bg-opacity-75 hover:text-white relative "
                                    key={index}
                                  >
                                    {feed.title}
                                    <div className="flex  mt-2 ">
                                      <div className="flex-grow border-t bg-line border-line opacity-5"></div>
                                    </div>
                                  </li>
                                ) : null
                              )}
                            </ul>
                            {feeds.length > 5 && !showAllFeeds && (
                              <button
                                onClick={() => {
                                  setShowAllFeeds(true);
                                  setOpenBell(true);
                                }}
                                className="text-maincolor font-dmsans font-normal cursor-pointer"
                              >
                                See More
                              </button>
                            )}
                          </div>
                        )}
                      </p>
                    </div> */}



                  </div>

                  <div className="h-auto   bg-maincolor  border-solid border-white flex justify-center pt-0 mt-1 ">
                    <button
                      type="button"
                      className=" flex rounded-full w-6 h-6  bg-maincolor text-sm focus:outline-none ring-2 ring-white focus:ring-white focus:ring-offset-2 focus:ring-offset-white "
                    >
                      <img
                        ref={imgRef}
                        onClick={() => setOpen(!open)}
                        src={
                          filename && ContentType
                            ? `data:${ContentType};base64,${filename}`
                            : logo } 
                        alt="user"
                        className="h-6 w-6   rounded-full cursor-pointer  z-50 "
                      ></img>
                    </button>

                    {open && (
                      <div
                        ref={menuRef}
                        className="bg-maincolor p-4 border-solid rounded-xl  md:w-96 w-56 absolute h-max shadow-lg z-20 top-10 right-2"
                      >
                        <ul>

                          <li className="font-normal text-sm  font-dmsans text-line">{user.username}</li>

                          <li className="font-normal text-sm  font-dmsans text-line">
                            
                            <div className="flex md:flex-row  sm:flex-row flex-col gap-4 w-full mt-2">

                            <div className="text-xs  bg-[#cff5e6]  rounded-full ">
                              <div className="flex grid-col-2 p-2  gap-2">  <img src={auth} className="h-4 w-4 "></img>
                              
                              <p className=" text-xs  text-[#1ac282]">Verified</p>
                              </div>
                             
                            </div>

                            <div className="text-xs  bg-[#fafafa]  rounded-full  ">
                              <div className="flex flex-row p-2  gap-2">  <img src={diamond} className="h-4 w-4 "></img>
                              
                              <p className=" text-xs">Regular User </p>
                              </div>
                             
                            </div>


                        

                        
                            
                            </div>

                            </li>

                          {Menu.map((menu,index) =>
                            user.role == "user" ? (
                              <li
                                onClick={() => {
                                  setOpen(false);
                                  handleOption(menu);
                                }}
                                className="font-dmsans w-full text-line  p-2 text-lg cursor-pointer rounded hover:bg-softColor hover:text-white mt-2"
                                key={index}
                              >
                                <div className="flex flex-row gap-4">
                           <div className="mt-1"> {icons[index]}
                           </div>
                                {menu}
                            </div>
                              </li>
                            ) : (
                              <></>
                            )
                          )}
                          <li
                            onClick={() => {
                              setOpen(false);
                              handleOption("Logout");
                            }}
                            className="font-dmsans w-full text-line  p-2 text-lg cursor-pointer rounded hover:bg-softColor hover:text-white mt-2"
                            key={"Logout"}
                          >
                                 <div className="flex flex-row gap-4">
                                 <div className="mt-1">    <BiLogOut></BiLogOut></div>

                                 Logout
                                 </div>
                     
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

           
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
