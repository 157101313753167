import React from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import { orderData } from "../helpers/AuthStore/orderSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLayoutEffect, useEffect, useState } from "react";
import PostApiData from "../helpers/postApiData";
import { IoIosCreate } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import SideMenu from "../Container/SideMenu";

import {socket} from '../utils/global';
import { AiOutlineSearch } from "react-icons/ai";
import ToasterGen from "../Container/ToasterGen";

const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let order = useSelector((state) => state.order);
  const [parentData, setParentData] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [orderState, setorderState] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10000000000000;
  const filteredMenu = order.orders && order.orders.orders
    ? order.orders.orders.filter((item) =>

        item._id.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMenu.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredMenu.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  useEffect(() => {if(localStorage.getItem('user')){const role = JSON.parse(localStorage.getItem('user')).role;if (role == 'user'){navigate('/NotFound');}    }  },[])


  async function handleSubmit(userId, amount, _id, verify, type) {
    if (type == "deposit") {
      const dataupdate = await PostApiData("merchant/verify_deposit", {
        userId: userId,
        amount: amount,
        _id: _id,
        verify: verify,
      });

      socket.emit("post_data", {title:"Deposit Verifed Successfully  ", userId:userId});


    } else {
      const dataupdate = await PostApiData("merchant/verify_withdraw", {
        userId: userId,
        amount: amount,
        _id: _id,
        verify: verify,
      });

      socket.emit("post_data", {title:"Withdraw Verifed Successfully  ", userId:userId});
      
    }
    dispatch(orderData());
  }
  useLayoutEffect(() => {
    return () => {};
  }, [order.orders.orders]);

  useEffect(() => {
    dispatch(orderData());
  }, [dispatch]);
  useEffect(() => {
    dispatch(orderData());
  }, [showModal, showModalCreate]);

  const handleUpdateClick = async (item) => {
    item = {
      ...item,
    };

    setorderState(item);
    setShowModal(true);
  };
  const handleDeleteButton = async (id) => {
    await PostApiData("order/delete_order", { ID: id });
    dispatch(orderData());
  };

  return (
    <>
      <ToasterGen></ToasterGen>

      <Header></Header>
      <MenuHeader></MenuHeader>

      <div className="grid grid-cols-1 lg:grid-cols-10 gap-0 font-dmsans">
        <div className="lg:col-span-2  lg:flex bg-background-main">
          <SideMenu />
        </div>
        <div className="lg:col-span-8 bg-background-main">
          <div className="container mx-auto mt-14">
            <div className="flex flex-col min-h-screen bg-transparent">
              <div className="p-4">
                <span className="mb-3 mx-auto text-2xl font-bold font-dmsans text-subheading-400  text-textColor">
                  Order Page
                </span>
              </div>

              <div className="flex mx-8 mt-10 items-end justify-end sm:justify-end ">
                <div className="absolute flex-shrink-0 items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search order"
                      className="bg-background border border-gray-600 text-line font-dmsans text-sm rounded-lg focus:ring-background focus:border-background block w-full pl-10 p-2.5 focus:outline-none focus:ring-0"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <AiOutlineSearch className="w-5 h-5 text-line dark:text-line" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative flex flex-col m-6 space-y-8 bg-background shadow-2xl rounded-2xl">
                <div className="p-8 md:p-14">
                  <span className="mb-3 font-dmsans text-subheading-400 text-textColor">
                    Order List
                  </span>

                  <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-background">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Order ID
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Order Amount
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Order Type
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Order User
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Order Merchant
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Order Verification
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                          <th className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-background divide-y divide-gray-200">
                        {currentItems.map((item,index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item._id}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.amount}
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.type}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.user.username}
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.merchantId}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                <div className="p-4">
                                  <button
                                    disabled={item.verify}
                                    onClick={(e) => {
                                      if (item.verify) {
                                      } else {
                                        handleSubmit(
                                          item.userId,
                                          item.amount,
                                          item._id,
                                          item.verify,
                                          item.type
                                        );
                                      }
                                    }}
                                    className={`flex items-center justify-center w-auto border h-12 ${
                                      item.verify
                                        ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                                        : "bg-textColor text-white hover:bg-textColor hover-text-white"
                                    } border-gray-300 text-md p-2 rounded-lg mb-6`}
                                  >
                                    <IoIosCreate className="mr-2" />
                                    Verify
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
