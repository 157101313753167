import React, { useLayoutEffect, useEffect, useMemo } from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import SideMenu from "../Container/SideMenu";
import getApiData from "../helpers/getApiData";
import { useState } from "react";
import { useFormik } from "formik";
import { ValidationWithdrawAmount } from "../utils/validationAmount";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

import { toast, Toaster, ToastBar } from "react-hot-toast";
import ToasterGen from "./ToasterGen";
const Withdraw = () => {
  const [network, setNetwork] = useState();
  const [address, setAddress] = useState();
  const [currency, setCurrency] = useState();
  const navigate = useNavigate();
  const [optionsCurrency, setoptionsCurrency] = useState([]);

  const [user, setUser] = useState();
  const formik = useFormik({
    initialValues: {
      network: network,
      currency: currency,
      address: address,
      amount: "",
      userId: "",
    },
    validate: ValidationWithdrawAmount,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);

      return false;
    },
  });

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const role = JSON.parse(localStorage.getItem("user")).role;
      if (role == "admin") {
        navigate("/NotFound");
      }
    }
  }, []);

  const optionElementsCurrency = useMemo(() => {
    return optionsCurrency.map((option, index) => (
      <option key={index} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsCurrency]);

  useLayoutEffect(() => {
    async function fetchData() {
      const data = await getApiData("merchant/get_withdraw_rules", "");
      if (data) {
        setNetwork(data.withdraw[0].network);
        setCurrency(data.withdraw[0].currency);

        formik.values.network = network;

        formik.values.currency = network;
        if (localStorage.getItem("user")) {
          formik.values.userId = JSON.parse(localStorage.getItem("user"))._id;
        }
      }

      const dataCurrency = await getApiData("merchant/get_currencies", "");
      const resultsCurrency = [];
      resultsCurrency.push({ key: 0, value: "" });
      dataCurrency.symbols.map((value, index) => {
        resultsCurrency.push({
          key: index,
          value: value,
        });
      });

      setoptionsCurrency([...resultsCurrency]);
    }

    fetchData();
  });

  return (
    <>
      <ToasterGen></ToasterGen>

      <Header />
      <MenuHeader />

      <div className="md:col-span-8 bg-background">
        <div className="bg-background relative px-3 py-5">
          <h1 className="text-line font-dmsans font-medium text-2xl leading-8">
            Withdraw Crypto
          </h1>
        </div>
        <div className="p-10 bg-newbgcolor text-line  border-gray-300 ">
          <div className="px-5 py-5 container mx-auto bg-maincolor border mt-4  border-maincolor rounded-xl border-opacity-40">
            <span className="text-sm text-line flex justify-start font-dmsans">
              Enter the Amount you want to Withdraw and also Fill Information
              (Address, Amount)
            </span>

            <form className="py-1" onSubmit={formik.handleSubmit}>
              <div className="px-4 p-5 w-full h-full bg-maincolor ">
                {/* <div className="flex justify-center">
                  <div className="w-7 h-7 bg-textColor text-white rounded-full flex items-center justify-center font-bold text-xs">
                    1
                  </div>
                </div> */}

                {/* <span className="text-lg text-line flex justify-center">
                  Network
                </span>
                <div className="flex bg-[#181322] text-line py-6 font-normal text-2xl justify-center">
                  {network}
                </div> */}

              

                {/* <div className="flex justify-center">
                  <div className="w-7 h-7 bg-textColor text-white rounded-full flex items-center justify-center font-bold text-xs">
                    2
                  </div>
                </div> */}
                
                <div className="flex bg-maincolor text-line  font-dmsans py-6 font-normal text-2xl justify-start sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-5/6">
                 <div className="font-dmsans flex grid-cols-2 gap-2">
               
               <div>  <span className="text-xs text-line flex justify-start font-dmsans mt-4">
                  Coin
                </span>
                </div>
                <input
                    value={currency}
               
                    disabled={true}
                    className="bg-maincolor  text-line   border focus:outline-none focus:ring-0 border-maincolor text-xl rounded-xl focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block w-full p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
              
                  </input>


                 </div>
          
                
                </div>

                {/* <div className="flex justify-center">
                  <div className="w-7 h-7 bg-textColor text-white rounded-full flex items-center justify-center font-bold text-xs">
                    3
                  </div>
                </div> */}

                <span class="text-md text-line flex justify-start underline font-dmsans">
                  Address
                </span>

                <div className="py-1 w-full flex flex-col items-start mt-3">
                  <span className="mb-2 text-xs font-dmsans font-normal leading-5 text-line ">
                    Enter Address
                  </span>
                  <input
                    {...formik.getFieldProps("address")}
                    id="address"
                    type="text"
                    className="bg-maincolor  sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-5/6    text-line  border border-solid  focus:outline-none focus:ring-0 border-gray-300  text-sm rounded-xl focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Address"
                  />
                </div>

             
                <div className="py-1 w-full flex flex-col items-start mt-3">
                  <span className="mb-2 text-xs font-dmsans font-normal leading-5 text-line ">
                    Amount to Withdraw
                  </span>
                  <input
                    {...formik.getFieldProps("amount")}
                    id="amount"
                    type="number"
                    pattern="[0-9]{0,5}"
                    className="bg-maincolor  sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-5/6   text-line  border focus:outline-none focus:ring-0 border-gray-300  text-sm rounded-xl focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Amount"
                  />
                </div>

          

                <span className="text-xs text-line flex justify-start font-dmsans py-3">
                  Network
                </span>
                <div className="flex bg-maincolor text-line font-normal font-dmsans text-2xl justify-center sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-5/6">
                  <input
                    value={network}
              
                    disabled={true}
                    className="bg-maincolor  text-line  border focus:outline-none focus:ring-0  border-gray-300 rounded-xl  text-sm  focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block w-full p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
              
                  </input>
                </div>

                <div className="flex grid-col-2  row  justify-between    ">
                  <div className=" justify-start ">
                    <p className="mb-2 text-xs  font-normal leading-5 text-line font-dmsans ">
                      Minimum Withdrawal
                    </p>

                    <span className="mb-2 text-sm font-dmsans font-normal leading-5 text-line justify-center  ">
                      10 USDT
                    </span>
                  </div>

                  <div className=" justify-end ">
                  <p className="mb-2 text-xs font-dmsans font-normal leading-5 text-line  justify-end ">
                    Network fee
                  </p>

                  <span className="mb-2 text-sm font-dmsans font-normal leading-5 text-line   ">
                    0.29 ~ 2.97 USDT
                  </span>
                </div>


                 
                </div>

                {/* <div className="flex grid-col-2 row  justify-between  gap-10">
                  <div className=" justify-start ">
                    <p className="mb-2 text-xs font-dmsans font-normal leading-5 text-line ">
                      24h remaining limit
                    </p>

                    <span className="mb-2 text-sm font-dmsans font-normal text-line justify-center ">
                      8,000,
                      <br className="lg:hidden sm:hidden md:hidden xl:hidden 2xl:hidden"></br>
                      000.00 BUSD/
                      <br className="lg:hidden sm:hidden md:hidden xl:hidden 2xl:hidden"></br>
                      8,000,
                      <br className="lg:hidden sm:hidden md:hidden xl:hidden 2xl:hidden"></br>
                      000.00 BUSD
                    </span>
                  </div>
                </div> */}

             

                <div className="flex justify-start">
                  <button
                    type="submit"
                    onClick={async () => {
                      const id = JSON.parse(localStorage.getItem("user"))._id;

                      const userValue = await getApiData(
                        "auth/get_user_by_id" + "/" + id,
                        ""
                      );

                      const balance = userValue.user.balance;

                      if (balance < formik.values.amount) {
                        toast.success("Insufficient Balance to Withdraw", {
                          duration: 2000,
                          style: {
                            border: "2px solid #F5F5F5",
                            padding: "16px",
                            backgroundColor: "#F5F5F5", // Add your desired background color
                            color: "#171717",
                           fontFamily:"sans-serif"
                          },

                          error: true,
                        });

                        return false;
                      }
                    }}
                    className="relative  justify-center text-sm mx-2 my-6 w-56 font-dmsans  border h-10  bg-textColor text-newbgcolor border-textColor text-md p-2 rounded-lg mb-6 hover:bg-textColor hover-text-white "
                  >
                    Withdraw
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Withdraw;
