import React from "react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ValidateOrderBuy } from "../utils/validationAmount";
import { useNavigate } from "react-router-dom";
import auth from "../assets/auth.png";
import best from "../assets/best.png";
import { toast } from "react-hot-toast";
import getApiData from "../helpers/getApiData";
import ToasterGen from "../Container/ToasterGen";
import "./ReactToastify.css";
const OrderCalculate = ({
  props,
  merchantState,
  price,
  Currency,
  typeNew,
  item,

  availablity,
}) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      quantity: 0,
      price: "",
      amount:0,
      merchantId: merchantState._id,
      network: "",
      address: "",
      type: typeNew == "buyer" ? "buy" : "sell",
      currency: Currency,
      availablity: item.available,
      mprice: price,
      navigate: navigate,
    },
    validate: ValidateOrderBuy,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);
      try {
        setIsSubmitting(true);

        props(false);

        setIsSubmitting(false);
        formik.resetForm();
        return false;
      } catch (error) {
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.role == "admin") {
        navigate("/NotFound");
      }
    }
  }, []);

  return (
    <div className="w-full bg-maincolor">
      <ToasterGen></ToasterGen>
      <div className="flex flex-col md:flex-row p-2 px-6">
        <div className="md:w-2/4 p-2">
          <div className="flex items-center justify-center md:justify-start">
            <div className="relative">
              <div className="w-8 h-8 bg-[#4653C8] rounded-full flex justify-center items-center text-line text-xs font-semibold relative">
                {item.name.charAt(0).toUpperCase()}
              </div>
              <div className="absolute bottom-0 top-5 right-0 w-3 h-3  bg-[#0ECB81] rounded-full flex justify-center items-center text-line"></div>
            </div>

            <p className="text-line font-dmsans font-normal text-lg ml-2">
              {item.name}
            </p>
            <div>
              <img src={auth} alt="auth" className="text-line ml-2 h-3 w-3" />
            </div>

            <div className="flex items-center ml-2">
              <div>
                <img src={best} alt="best" />
              </div>
              <div>
                <p className="text-line font-dmsans font-normal text-sm ml-1">
                  {item.rating}%
                </p>
              </div>
            </div>
          </div>


          <div className="flex items-center justify-center mt-2 md:justify-start  ">
            <p className="text-line font-dmsans font-normal text-sm">
              {item.orders}
            </p>
            <div className="text-line mx-2">|</div>
            <div className="text-line">
              <p className="text-line font-dmsans font-normal text-sm">
                {item.completion}% completion
              </p>
            </div>
          </div>

          <div className=" contents w-full justify-start ">
            <div className="flex flex-row gap-4 mt-7">
              <div className="w-full">
                <p className="font-dmsans font-semibold text-base text-line">
                  {item.avgtime} Minutes
                </p>
                <p className="font-dmsans font-normal text-xs text-gray-400">
                  Payment Time Limit
                </p>
              </div>

              <div className="w-full">
                <p className="font-dmsans font-semibold text-base text-line">
                  {item.Ttime} Minutes
                </p>
                <p className="font-dmsans font-normal text-xs text-gray-400">
                  Avg. Release Time
                </p>
              </div>

              <div className="w-full">
                <p className="font-dmsans font-semibold text-base text-line">
                  {item.available} USDT
                </p>
                <p className="font-dmsans font-normal text-xs text-gray-400">
                  Available
                </p>
              </div>
            </div>

            <div className="flex grid-cols-2 items-center gap-2 mt-7 mb-2">
              <div className=" w-1 h-4 top-1  rounded bg-[#F0B90B] "></div>
              <p className=" text-base font-dmsans font-medium text-line">
                {item.PaymentMethod}
              </p>
            </div>
          </div>

          <div>
            <textarea
              value={`Advertiser's Term (Please read Carefully)\n ` + item.desc}
              disabled={true}
              className="w-full mt-2 h-44 px-4 py-2 border rounded-lg font-dmsans bg-newbgcolor  text-line placeholder-[#8e8e93] focus:outline-none"
            ></textarea>
          </div>
        </div>

        <div className="md:w-2/4 p-4 flex  flex-col ">
          <div className="flex justify-end">
            <button
              className="p-1 border-0 text-textColor ml-auto bg-transparent text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => props(false)}
            >
              <span className="bg-transparent text-textColor">×</span>
            </button>
          </div>
          <span className="mb-3 text-sm font-bold font-dmsans text-subheading-400 text-textColor">
            Price {price} {Currency}
          </span>

          <form onSubmit={formik.handleSubmit}>
            {typeNew == "buyer" ? (
              <div>
                <div className="py-4">
                  <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                    Amount in {item.Currency}
                  </span>
                  <div className="border  border-gray-500 text-line   rounder-sm h-20 rounded-lg">
                    <span className="px-4 text-line  opacity-60">
                      I want to pay
                    </span>

                    <div className="flex grid-col-2 ">
                      <input
                        {...formik.getFieldProps("price")}
                        id="price"
                        type="number"
                        value={formik.values.price}
                        min={item.limitLow}
                        max={item.limitHigh}
                        onInput={(e) => {
                          const input = e.target;
                          if (input.validity.rangeUnderflow) {
                            input.setCustomValidity(
                              `Amount must exceed  ${item.limitLow} ${item.Currency}`
                            );
                          } else if (input.validity.rangeOverflow) {
                            input.setCustomValidity(
                              `Amount must be less than  ${item.limitHigh} ${item.Currency}`
                            );
                          } else {
                            input.setCustomValidity("");
                          }
                        }}
                        className="bg-maincolor px-4 h-fit mt-4 placeholder:justify-start text-left focus-visible:false text-line justify-start w-full placeholder:font-dmsans rounded-xl  transition-colors placeholder:font-light outline-none "
                      ></input>
                      <div className=" mt-4 font-dmsans text-xs font-normal leading-5 text-line">
                        <div className="flex grid-col-2 ">
                          <div className="mx-2">All</div>
                          <div className="px-2 text-sm">{item.Currency}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-4">
                  <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                    Quantity
                  </span>
                  <div className="border  border-gray-500 text-line   rounder-sm h-20 rounded-lg">
                    <span className="px-4 text-line  opacity-60">
                      I will receive
                    </span>

                    <div className="flex grid-col-2 ">
                      <input
                        {...formik.getFieldProps("amount")}
                        id="amount"
                        type="number"
                        value={(formik.values.price / price).toFixed(2)}
                        className="bg-maincolor px-4 h-fit mt-4 placeholder:justify-start text-left focus-visible:false text-line justify-start w-full placeholder:font-dmsans rounded-xl  transition-colors placeholder:font-light outline-none "
                      ></input>

                      <div className=" mt-4 font-dmsans text-xs font-normal leading-5 text-line">
                        <div className="flex grid-col-2 ">
                          <div className="px-2 text-sm">USDT</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="py-4">
                  <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                    Amount
                  </span>

                  <div className="border  border-gray-500   rounder-sm h-20 rounded-lg">
                    <span className="px-4 text-line  opacity-60">
                      I will receive
                    </span>

                    <div className="flex grid-col-2 ">
                      <input
                        {...formik.getFieldProps("price")}
                        id="price"
                        type="number"
                        min={item.limitLow}
                        max={item.limitHigh}
                        onInput={(e) => {
                          const input = e.target;
                          if (input.validity.rangeUnderflow) {
                            input.setCustomValidity(
                              `Amount must exceed  ${item.limitLow} ${item.Currency}`
                            );
                          } else if (input.validity.rangeOverflow) {
                            input.setCustomValidity(
                              `Amount must be less than  ${item.limitHigh} ${item.Currency}`
                            );
                          } else {
                            input.setCustomValidity("");
                          }
                        }}
                        value={(formik.values.amount * price).toFixed(2)}
                        className="bg-maincolor px-4 h-fit mt-4 placeholder:justify-start text-left focus-visible:false text-line justify-start w-full placeholder:font-dmsans rounded-xl  transition-colors placeholder:font-light outline-none "
                      ></input>

                      <div className=" mt-4 font-dmsans text-xs font-normal leading-5 text-line">
                        <div className="flex grid-col-2 ">
                          <div className="px-2 text-sm">{item.Currency}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-4">
                  <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                    Quantity
                  </span>

                  <div className="border  border-gray-500   rounder-sm h-20 rounded-lg">
                    <span className="px-4 text-line  opacity-60">
                      Enter USDT
                    </span>

                    <div className="flex grid-col-2 ">
                      <input
                        {...formik.getFieldProps("amount")}
                        id="amount"
                        type="number"
                        value={formik.values.amount}
                        className="bg-maincolor px-4 h-fit mt-4 placeholder:justify-start text-left focus-visible:false text-line justify-start w-full placeholder:font-dmsans rounded-xl  transition-colors placeholder:font-light outline-none "
                      ></input>

                      <div className=" mt-4 font-dmsans text-xs font-normal leading-5 text-line">
                        <div className="flex grid-col-2 ">
                          <div className="px-2 text-sm">USDT</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex grid-col-2 gap-2 ">
              <div className="col-span-1">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => props(false)}
                  className=" flex items-center justify-center xl:w-32 2xl:w-32 lg:w-32 sm:w-32 md:w-32  font-semibold   border h-12 bg-gray-400 text-line border-gray-400 text-md p-2 rounded-lg mb-6 hover:bg-gray-400 hover-text-line"
                >
                  Cancel
                </button>
              </div>

              <div>
                {typeNew == "buyer" ? (
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      const balance = (formik.values.price / price).toFixed(2);

                      if (balance > item.available) {
                        toast.error(
                          "The available limit exceeds the order amount.",
                          {
                            duration: 2000,
                            style: {
                              border: "2px solid #F5F5F5",
                              padding: "16px",
                              color: "#B28EFB",
                              color: "#B28EFB",
                              fontFamily: "sans-serif",
                              backgroundColor: "#140D1E",
                            },

                            error: true,
                          }
                        );

                        return false;
                      }
                    }}
                    className=" flex items-center justify-center   font-semibold xl:w-32 2xl:w-32 lg:w-32 sm:w-32 md:w-32  border h-12 bg-[#0ECB81] text-line border-[#0ECB81] text-md p-2 rounded-lg mb-6 hover:bg-[#0ECB81] hover-text-line"
                  >
                    Buy USDT
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={async () => {
                      const availableLimit = formik.values.amount;

                      if (availableLimit > item.available) {
                        toast.error(
                          "The available limit exceeds the order amount.",
                          {
                            duration: 2000,
                            style: {
                              border: "2px solid #F5F5F5",
                              padding: "16px",
                              color: "#B28EFB",
                              color: "#B28EFB",
                              fontFamily: "sans-serif",
                              backgroundColor: "#140D1E",
                            },

                            error: true,
                          }
                        );
                      }

                      const id = JSON.parse(localStorage.getItem("user"))._id;

                      const userValue = await getApiData(
                        "auth/get_user_by_id" + "/" + id,
                        ""
                      );

                      const balance = userValue.user.balance;
                            console.log(balance,formik.values.amount);
                            
                      if (!(balance >= formik.values.amount)) {
                        toast.error("Insufficent Balance to Sell", {
                          duration: 2000,
                          style: {
                            border: "2px solid #F5F5F5",
                            padding: "16px",
                            color: "#B28EFB",
                            color: "#B28EFB",
                            fontFamily: "sans-serif",
                            backgroundColor: "#140D1E",
                          },

                          error: true,
                        });
                      }
                    }}
                    className=" flex items-center justify-center xl:w-32 2xl:w-32 lg:w-32 sm:w-32 md:w-32 font-semibold border h-12 border-red-600 text-line bg-red-600 text-md p-2 rounded-lg mb-6 hover:bg-red-600 hover-text-line"
                  >
                    Sell USDT
                  </button>
                )}
              </div>
            </div>
          </form>

          <span className="mb-3 text-2xl font-bold font-dmsans text-subheading-400 text-textColor"></span>
        </div>


        <div className="md:w-2/4 px-4 flex flex-col sm:hidden md:hidden lg:hidden  xl:hidden 2xl:hidden">
          <div className="flex items-center justify-start md:justify-start">
            <div className="relative">
              <div className="w-8 h-8 bg-[#4653C8] rounded-full flex justify-center items-center text-line text-xs font-semibold relative">
                {item.name.charAt(0).toUpperCase()}
              </div>
              <div className="absolute bottom-0 top-5 right-0 w-3 h-3  bg-[#0ECB81] rounded-full flex justify-center items-center text-line"></div>
            </div>

            <p className="text-line font-dmsans font-normal text-lg ml-2">
              {item.name}
            </p>
            <div>
              <img src={auth} alt="auth" className="text-line ml-2 h-3 w-3" />
            </div>

            <div className="flex items-center ml-2">
              <div>
                <img src={best} alt="best" />
              </div>
              <div>
                <p className="text-line font-dmsans font-normal text-sm ml-1">
                  {item.rating}%
                </p>
              </div>
            </div>
          </div>


          <div className="flex items-center justify-start px-10 mt-2 md:justify-start  ">
            <p className="text-line font-dmsans font-normal text-xs">
              {item.orders} orders
            </p>
            <div className="text-line mx-2 opacity-40">|</div>
            <div className="text-line">
              <p className="text-line font-dmsans font-normal text-xs">
                {item.completion}% completion
              </p>
            </div>
          </div>
          

        </div>
      </div>
    </div>
  );
};

export default OrderCalculate;
