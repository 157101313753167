import {configureStore} from '@reduxjs/toolkit';
import authSlice from './AuthStore/authSlice';
import menuSlice from './MenuStore/menuSlice';
import screenSlice from './ScreenStore/screenSlice';
import userSlice from './UserStore/userSlice';
import authUserSlice  from './UserStore/authUserSlice';
import productSlice from './AuthStore/productSlice';
import categorySlice from './AuthStore/categorySlice';
import distributorSlice from './AuthStore/distributorSlice';
import markupSlice  from './AuthStore/markupSlice';
import brandSlice from './AuthStore/brandSlice';
import customerMarkupSlice from './AuthStore/customerMarkupSlice';
import productPricingSlice from './AuthStore/productPricingSlice';
import merchantSlice from './AuthStore/merchantSlice';
import depositSlice from './AuthStore/depositSlice';

import withdrawSlice from './AuthStore/withdrawSlice';
import orderSlice from './AuthStore/orderSlice';
import buySlice from './AuthStore/buySlice';
import contactSlice from './AuthStore/contactSlice';
const authStore  = configureStore({
    
    reducer:{
        auth:authSlice,
        menu:menuSlice,
        screen:screenSlice,
        userdata:userSlice,
        authuser:authUserSlice,
        product:productSlice,
        category:categorySlice,
        distributor:distributorSlice,
        markup:markupSlice,
        brand:brandSlice,
        customer_markup:customerMarkupSlice,
        product_pricing:productPricingSlice,
        merchant:merchantSlice,
        deposit:depositSlice,
        withdraw:withdrawSlice,
        order:orderSlice,
        buy:buySlice,
        contact:contactSlice,
    }

});

export default authStore;