import React, { useLayoutEffect } from "react";
import useToggle from "../../utils/useToggle";

import ToasterGen from "../../Container/ToasterGen";
import b4 from "../../assets/b4.png";
import b5 from "../../assets/b5.png";
import b6 from "../../assets/b6.png";
import logo from "../../assets/logo2.png";
import mailer from "../../assets/imagemail.png";
import postApiData from "../../helpers/postApiData";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { username, email } = state || { email: null, username: null };

  useLayoutEffect(() => {
    return () => {
      //   if (location.state === null) {
      //     navigate("/", { replace: true });
      //   }
      //   if (localStorage.getItem("user")) {
      //     navigate("/dashboard", { replace: true, state: location.state });
      //   }
    };
  });

  useToggle(false);

  return (
    <React.Fragment>
      <ToasterGen></ToasterGen>

      <div className="bg-background-main flex justify-center  -z-10  ">
        <img
          className="h-36 w-36 absolute flex -top-2  right-64 "
          src={b6}
          alt="triangle"
        />

        <img
          className="h-56 w-56    absolute  left-64  top-72   md:block  hidden z-10"
          src={b5}
          alt="triangle"
        />

        <img
          className=" h-80 w-80    absolute  right-48    my-96 top-96  bottom-0   z-20"
          src={b4}
          alt="triangle"
        />
        <div className="relative flex flex-col md:w-1/2 w-full  mt-48 mb-36  bg-newbgcolor border border-solid border-st rounded-xl border-Strokes p-4  z-20">
          <div className="z-50">
            <img className=" h-8 w-32 " src={logo} alt="logo"></img>
            <div className="mb-1 mt-2 ">
              <span className="font-medium text-2xl font-dmsans  leading-8 text-[#171717]">
                Verify your email
              </span>
            </div>
            <div className="mb-4">
              <span className="text-line text-sm  font-dmsans">
                Check your email & click the link to activate your account.
              </span>
            </div>
            <div className="flex justify-center">
              <img
                className=" flex w-60 h-60  text-center align-middle  items-center   "
                src={mailer}
                alt="mailer"
              ></img>
            </div>

            <div className="flex flex-col md:flex-row gap-4 justify-center mt-2">
              <div>
                <button
                  onClick={async () => {
                    await postApiData("auth/resend_email", {
                      email: email,
                      username,
                    });
                  }}
                  className="w-full md:w-80 h-16 md:h-16 p-2 border border-1 bg-textColor text-newbgcolor font-dmsans rounded-full"
                >
                  Resend Email
                </button>
              </div>
              <div>
                <button
                  className="w-full md:w-80 p-2 md:h-16 h-12 border border-1 border-line border-solid bg-newbgColor text-line font-dmsans rounded-full"
                  onClick={() => navigate("/", { replace: true })}
                >
                  Contact Support
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 
      <div className="bg-background-main  h-full text-white flex items-center ">
        <div className="container mx-auto  justify-center items-center ">
          <div className="flex   min-h-screen  bg-transparent ">
            <div className="relative  m-6 space-y-8 bg-white   md:space-y-0 w-full">
              <div className="flex bg-background grid-flow-col grid-cols-2 gap-2">
                <div className=" bg-background w-full  p-8 md:p-14  top-12">
                  <div>
                    <span className=" font-bold    font-dmsans text-3xl   text-line ">
                      Welcome to MART USDT
                    </span>
                  </div>

                  <form
                    className=" relative     top-6 py-1"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="py-1 ">
                      <span className="mb-2 text-md font-dmsans  font-normal text-base  leading-5 text-line">
                        Email
                      </span>

                      <input
                        {...formik.getFieldProps("email")}
                        id="email"
                        type="email"
                        className=" gap-1 w-full  h-14 border bg-background  rounded-xl  text-line text-center placeholder:font-light"
                        placeholder="Enter your Email Address"
                      ></input>
                    </div>

                    <div className="py-1 ">
                      <span className="mb-2 text-md font-dmsans  font-normal text-base  leading-5 text-line">
                        Username
                      </span>

                      <input
                        {...formik.getFieldProps("username")}
                        id="username"
                        type="text"
                        className=" gap-1 w-full  h-14 border bg-background  rounded-xl  text-line text-center placeholder:font-light"
                        placeholder="Enter your User Name"
                      ></input>
                    </div>

                    <div className="py-1 ">
                      <span className="mb-2 text-md font-dmsans  font-normal text-base  leading-5 text-line">
                        Address
                      </span>

                      <input
                        {...formik.getFieldProps("address")}
                        id="address"
                        type="address"
                        className=" gap-1 w-full  h-14 border bg-background  rounded-xl  text-line text-center placeholder:font-light"
                        placeholder="Enter your Address"
                      ></input>
                    </div>

                    <div className="py-1">
                      <span className="mb-2 text-md font-dmsans font-normal text-base leading-5 text-line">
                        Phone Number
                      </span>
                      <input
                        {...formik.getFieldProps("phoneNumber")}
                        id="phoneNumber"
                        type="tel" // Use type="tel" for phone number input
                        pattern="[0-9]{7,}" // Add a pattern to enforce a valid phone number format
                        className="gap-1 w-full h-14 border bg-background rounded-xl text-line text-center placeholder:font-light"
                        placeholder="Enter your phone number"
                      ></input>
                    </div>

                    <div className="py-1  ">
                      <div className="    rounded-xl relative">
                        <span className="mb-2 text-md font-dmsans font-noraml text-line">
                          Your Password
                        </span>

                        <i className="absolute font-normal top-1/2 right-2  -translate-y-1/2 text-line">
                          {isPasswordHideShow === true ? (
                            <div className="flex items-center ">
                              <VscEye
                                className=" w-6   "
                                onClick={ChangePasswordHideShow}
                              ></VscEye>
                              <span
                                className="ml-2 font-normal text-lg leading-7     font-dmsans"
                                style={{ font: "icon" }}
                              >
                                Hide
                              </span>
                            </div>
                          ) : (
                            <div className="flex items-center font-normal">
                              <VscEyeClosed
                                className="w-6"
                                onClick={ChangePasswordHideShow}
                              />
                              <span
                                className="ml-2 font-normal text-lg leading-7 font-dmsans  "
                                style={{ font: "icon" }}
                              >
                                Show
                              </span>
                            </div>
                          )}
                        </i>
                      </div>

                      <input
                        {...formik.getFieldProps("password")}
                        id="password"
                        type={
                          isPasswordHideShow === false ? "password" : "text"
                        }
                        className="gap-1 w-full  h-14 border bg-background  rounded-xl  text-maincolor text-center placeholder:font-light"
                      />
                      <div className=" py-1 flex flex-wrap">
                        {rules_list.map((element, index) => (
                          <>
                            <div
                              key={element}
                              className="flex items-center mb-2 mr-4 "
                            >
                              <IoEllipse></IoEllipse>
                              <p className="mx-1 ">{element}</p>
                            </div>
                            {(index + 1) % 3 === 0 && (
                              <div className="w-full "></div>
                            )}
                          </>
                        ))}
                      </div>


                      <div className="relative top-10">
                        <button
                          type="submit"

                          className="  relative  w-1/2  rounded-full border h-16 bg-extra-color text-maincolor border-extra-color text-md  mb-6 hover:bg-extra-color hover-text-white"
                        >
                          Sign Up
                        </button>
                      </div>
                      <div className=" relative   top-6 text-left mr-20 mb-20 ">
                        <span className="text-line">
                          Already have an Account?
                          <Link
                            className="text-line underline mx-2"
                            to="/home"
                            replace={true}
                          >
                            Log in
                          </Link>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>

                <div className=" bg-background hidden md:flex flex-col w-full">
                  <div className="flex-grow">
                    <img
                      src={image}
                      alt="Image"
                      className="w-full h-full object-cover min-h-screen"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default VerifyEmail;
