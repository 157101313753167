import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";
export const distributorData = createAsyncThunk(
    "distributor",
    async (payload, { rejectWithValue }) => {
      try {
  
       
        const request = await axios.get(env.API_URL + "product/get_distributor");
        const response = await request.data;
   
        return response;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );


const distributorSlice = createSlice({
  name: "distributor",
  initialState: {
    distributors : [],
    loading: false,
    error: null,
  },
  reducers: {
    addDistributor: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(distributorData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(distributorData.rejected, (state, action) => {
  
        state.loading = true;
      })
      .addCase(distributorData.fulfilled, (state, action) => {
          state.loading = false;
          state.distributors = action.payload;
        
         
        }
      );
  },
});
export const { addDistributor } = distributorSlice.actions;
export default distributorSlice.reducer;


