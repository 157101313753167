import React from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import { buyData } from "../helpers/AuthStore/buySlice";
import { useSelector, useDispatch } from "react-redux";
import { useLayoutEffect, useEffect, useState } from "react";
import PostApiData from "../helpers/postApiData";
import { IoIosCreate } from "react-icons/io";
import SideMenu from "../Container/SideMenu";
import { toast, Toaster, ToastBar } from "react-hot-toast";
import { socket } from "../utils/global";
import {MdDelete} from "react-icons/md";

import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import ToasterGen from "../Container/ToasterGen";
const OrdersBuySell = () => {
  const dispatch = useDispatch();
  let buy = useSelector((state) => state.buy);
  
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [buyState, setbuyState] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100000000000;
  const filteredMenu = buy.buys.buys
    ? buy.buys.buys.filter((item) =>
    item._id.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMenu.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredMenu.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  async function handleSubmit(userId, amount, _id, verify, type) {
    if (type == "buy") {
      const dataupdate = await PostApiData("merchant/verify_buy", {
        userId: userId,
        amount: amount,
        _id: _id,
        verify: verify,
      });

      socket.emit("post_data", {title:"Buyer Order Verifed, Order Id:  " +  _id.toString()  +"",userId:userId});



    } else {
      const dataupdate = await PostApiData("merchant/verify_sell", {
        userId: userId,
        amount: amount,
        _id: _id,
        verify: verify,
      });

      socket.emit("post_data", {title:"Selling Order Verifed, Order Id:  " + _id.toString()  +"",userId:userId});

    }
    dispatch(buyData());
  }
  useLayoutEffect(() => {
    return () => {};
  }, [buy.buys.buys]);

  useEffect(() => {
    dispatch(buyData());
  }, [dispatch]);
  useEffect(() => {
    dispatch(buyData());
  }, [showModal, showModalCreate]);

  const handleUpdateClick = async (item) => {
    item = {
      ...item,
    };

    setbuyState(item);
    setShowModal(true);
  };
  const handleDeleteButton = async (id) => {
    await PostApiData("merchant/delete_order", { ID: id });
    dispatch(buyData());
  };

  useEffect(() => {if(localStorage.getItem('user')){const role = JSON.parse(localStorage.getItem('user')).role;if (role == 'user'){navigate('/NotFound');}    }  },[])


  

  return (
    <>
    <ToasterGen>
      
    </ToasterGen>

      <Header></Header>
      <MenuHeader></MenuHeader>

      <div className="grid grid-cols-1 lg:grid-cols-10 gap-0">
        <div className="lg:col-span-2  lg:flex bg-background-main">
          <SideMenu />
        </div>
        <div className="lg:col-span-8 bg-background-main">
          <div className="container mx-auto mt-14">
            <div className="flex flex-col min-h-screen bg-transparent">
              <div className="p-4">
                <span className="mb-3 mx-auto text-2xl font-bold font-dmsans text-subheading-400  text-textColor">
                  Order Page (Buy/Sell)
                </span>
              </div>

              <div className="flex mx-8 mt-10 items-end justify-end sm:justify-end ">
                <div className="absolute flex-shrink-0 items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search buy"
                      className="bg-background bbuy bbuy-gray-600 text-line font-dmsans text-sm rounded-lg focus:ring-background focus:bbuy-background block w-full pl-10 p-2.5 focus:outline-none focus:ring-0"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <AiOutlineSearch className="w-5 h-5 text-line dark:text-line" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative flex flex-col m-6 space-y-8 bg-background shadow-2xl rounded-2xl">
                <div className="p-8 md:p-14">
                  <span className="mb-3 font-dmsans text-subheading-400 text-textColor">
                 Order Buy/Sell List
                  </span>

                  <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-background">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             ID
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Amount
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Type
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             User
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Merchant
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Account Title
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Account Address
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Account Number
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Payment Method
                          </th>


                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Verification
                          </th>
                      

                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                          <th className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-background divide-y divide-gray-200">
                        {currentItems.map((item,index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item._id}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                
                                {item.type == "buy" ? item.amount && item.amount.toFixed(2): item.quantity && item.quantity.toFixed(2)}

                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.type}
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.user.username}
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.merchantId}
                              </div>
                            </td>


                         
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.user.firstName}
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.network}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.address}
                              </div>
                            </td>


                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                {item.paymentmethod}
                              </div>
                            </td>


                           
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-line font-dmsans">
                                <div className="p-4">
                                  <button
                                    disabled={item.verify}
                                    onClick={(e) => {
                                      if (item.verify) {
                                      } else {
                                        handleSubmit(
                                          item.userId,
                                          item.type == "buy" ? item.amount: item.quantity,
                                          item._id,
                                          item.verify,
                                          item.type
                                        );
                                      }
                                    }}
                                    className={`flex items-center justify-center w-auto bbuy h-12 ${
                                      item.verify
                                        ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                                        : "bg-textColor text-white hover:bg-textColor hover-text-white"
                                    } bbuy-gray-300 text-md p-2 rounded-lg mb-6`}
                                  >
                                    <IoIosCreate className="mr-2" />
                                    Verify
                                  </button>


                                  
                         

                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <button
                                onClick={() => handleDeleteButton(item._id)}
                                href="#"
                                className="text-line hover:extra-color"
                              >
                                <MdDelete />
                              </button>
                            </td>


                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdersBuySell;
