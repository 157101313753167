import React, { useLayoutEffect, useEffect } from "react";
import { Link, useNavigate,useParams } from "react-router-dom";
import useToggle from "../../utils/useToggle";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import logo from "../../assets/logo2.png";
import b4 from "../../assets/b4.png";
import b5 from "../../assets/b5.png";
import b6 from "../../assets/b6.png";

import { ValidationLogin } from "../../utils/validateLogin";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import image from "../../assets/sb.png";
import ToasterGen from "../../Container/ToasterGen";

import logod from "../../assets/logo2.png";
import { loginUser } from "../../helpers/AuthStore/authSlice";

import "./Login.css";

import postApiData from "../../helpers/postApiData";


const Login = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const username = searchParams.get("username");
  const token = searchParams.get("token");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      emailnew: "",
      password: "",
      navigate: navigate,
    },
    validate: ValidationLogin,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);

      try {
        dispatch(loginUser(values)).then((result) => {
          if (result.payload.message == "Successfully Login") {
            const userrole = JSON.parse(localStorage.getItem("user")).role;

            if (userrole == "admin") {
              navigate("/merchant_list");
            } else if (userrole == "user") {
              navigate("/dashboard");
            } 
          }
        });
      } catch (err) {
        console.log(err);
      }

      return false;
    },
  });

  const [isPasswordHideShow, setPasswordHideShow] = useToggle(false);

  useEffect(() => {


   const fetchData  =  async () => {

    if (username !=null && token!=null && username && token) {
      

      await postApiData("auth/verify_account", { username: username, token: token });



      console.log("valid")
    }
  }


    fetchData();

      // return () => {
    //   if (location.state === null) {
    //     navigate("/", { replace: true });
    //   }
    //   if (localStorage.getItem("user")) {
    //     navigate("/dashboard", { replace: true, state: location.state });
    //   }
    //  };
  },[]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const userrole = JSON.parse(localStorage.getItem("user")).role;

      if (userrole == "admin") {
        navigate("/merchant_list");
      } else if (userrole == "user") {
        navigate("/dashboard");
      } // Redirect to dashboard if user is already authenticated
    }
  }, []);

  useToggle(false);

  const ChangePasswordHideShow = () => {
    setPasswordHideShow();
  };

  return (
    <React.Fragment>
      <ToasterGen></ToasterGen>
  

 

<div className="bg-background-main flex justify-center -z-10 ">


<img className="h-36 w-36 absolute flex -top-2  right-96 " src={b6} alt="Image" />


<img className="h-56 w-56    absolute  left-1/4  top-72   z-10" src={b5} alt="Image" />


<img className=" h-80 w-80    absolute  right-80   top-96 my-16    z-20" src={b4} alt="Image" />
  <div className="relative flex flex-col w-full max-w-md mt-48 mb-36  bg-newbgcolor border border-solid border-st rounded-xl border-Strokes p-4  z-20">
  



<div className="z-50">
<img className=" h-8 w-32 "   src={logo}></img>
    <div className="mb-4 mt-2 " >


      <span className="font-medium text-2xl font-dmsans  leading-8 text-[#171717]">
        Login
      </span>
    </div>

    <div className="mb-4">
      <span className="text-line text-sm font-dmsans">
        Don't have an Account?
        <Link className="text-line underline mx-1 font-dmsans" to="/signup_email" replace={true}>
          Sign up
        </Link>
      </span>
    </div>

    <form onSubmit={formik.handleSubmit} className="mb-4">
      <div className="mb-2">
        <span className="text-md font-dmsans font-normal text-base leading-5 text-line">
          Your email
        </span>
      </div>
      <input
        {...formik.getFieldProps("emailnew")}
        id="emailnew"
        type="email"
        className="w-full h-12 border bg-background rounded-xl text-line text-center placeholder:font-light font-dmsans"
        placeholder=""
      />

      <div className="relative">
        <div className="mb-2">
          <span className="text-md font-dmsans font-normal text-line">
            Your Password
          </span>
        </div>

        <i className="absolute font-normal    items-end    justify-end right-1  top-5  mr-2  -translate-y-1/2 text-line">
                          {isPasswordHideShow === true ? (
                            <div className="flex items-center ">
                              <VscEye
                                className=" w-6   "
                                onClick={ChangePasswordHideShow}
                              ></VscEye>
                              <span
                                className="ml-1 font-normal text-lg leading-7     font-dmsans"
                                style={{ font: "icon" }}
                              >
                                Hide
                              </span>
                            </div>
                          ) : (
                            <div className="flex items-center font-normal">
                              <VscEyeClosed
                                className="w-6"
                                onClick={ChangePasswordHideShow}
                              />
                              <span
                                className="ml-1 font-normal text-lg leading-7 font-dmsans  "
                                style={{ font: "icon" }}
                              >
                                Show
                              </span>
                            </div>
                          )}
                        </i>
        
      </div>
      <input
        {...formik.getFieldProps("password")}
        id="password"
        value={formik.values.password}
        type={isPasswordHideShow === false ? "password" : "text"}
        className="w-full h-12 border bg-background rounded-xl pr-4 pl-4 font-dmsans text-line text-center placeholder:font-light"
      />

      <button
        type="submit"
        className="w-full font-dmsans my-4 rounded-full border h-12 bg-[#B28EFB] text-white border-[#B28EFB] text-md p-2 hover:bg-[#B28EFB] hover-text-white"
      >
        Login
      </button>

      <div className="text-left">
        <span className="text-line font-dmsans">
          Already have an account
          <Link className="text-line underline mx-2 font-dmsans" to="/login" replace={true}>
            Sign In
          </Link>
        </span>
      </div>
    </form>
  </div>
  </div>
</div>




{/* 
  <div className=" bg-background-main  h-screen ">
      <div className="bg-background-main  h-full text-white flex items-center    margin ">
        <div className="container mx-auto  justify-center items-center ">
          <div className="flex   min-h-screen  bg-transparent ">
            <div className="relative  m-6 space-y-8 bg-white   md:space-y-0 w-full">
              <div className="flex bg-background grid-flow-col grid-cols-2 gap-2">
                <div className=" bg-background w-full  p-8 md:p-14  top-12 h-max">
                  <div>
                    <span className=" font-bold    font-dmsans text-3xl   text-line ">
                      Welcome to TUMAANEX
                    </span>
                  </div>

                  <form
                    className=" relative     top-20 py-1 "
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="py-1 h-28">
                      <span className="mb-2 text-md font-dmsans  font-normal text-base  leading-5 text-line">
                        Your email
                      </span>

                      <input
                        {...formik.getFieldProps("emailnew")}
                        id="emailnew"
                        type="email"
                        className=" gap-1 w-full  h-14 border bg-background  rounded-xl  text-maincolor text-center placeholder:font-light"
                        placeholder=""
                      ></input>
                    </div>

                    <div className="py-1 h-44  ">
                      <div className="    rounded-xl relative">
                        <span className="mb-2 text-md font-dmsans font-noraml text-line">
                          Your Password
                        </span>

                        <i className="absolute font-normal top-1/2 right-2  -translate-y-1/2 text-line">
                          {isPasswordHideShow === true ? (
                            <div className="flex items-center ">
                              <VscEye
                                className=" w-6   "
                                onClick={ChangePasswordHideShow}
                              ></VscEye>
                              <span
                                className="ml-2 font-normal text-lg leading-7     font-dmsans"
                                style={{ font: "icon" }}
                              >
                                Hide
                              </span>
                            </div>
                          ) : (
                            <div className="flex items-center font-normal">
                              <VscEyeClosed
                                className="w-6"
                                onClick={ChangePasswordHideShow}
                              />
                              <span
                                className="ml-2 font-normal text-lg leading-7 font-dmsans  "
                                style={{ font: "icon" }}
                              >
                                Show
                              </span>
                            </div>
                          )}
                        </i>
                      </div>

                      <input
                        {...formik.getFieldProps("password")}
                        id="password"
                        value={formik.values.password}
                        type={
                          isPasswordHideShow === false ? "password" : "text"
                        }
                        className="gap-1 w-full  h-14 border bg-background  rounded-xl  text-maincolor text-center placeholder:font-light"
                      />
                    </div>


                



                    <button
                      type="submit"
                      className="    relative  w-1/2  my-6  rounded-full border   h-16 bg-extra-color  text-maincolor border-extra-color  text-md p-2  mb-6 hover:bg-extra-color hover-text-white"
                    >
                      Login
                    </button>
                    <div className=" relative   bg-background  top-1 text-left mr-20 mb-20 ">
                      <span className="text-line">
                        Don't have an Account?
                        <Link
                          className="text-line underline  mx-2   "
                          to="/signup_email"
                          replace={true}
                        >
                          Sign up
                        </Link>
                      </span>
                    </div>
                  </form>
                </div>

                <div className=" bg-background hidden md:flex flex-col w-full">
                        
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      </div>  */}
       
      
    </React.Fragment>
  );
};

export default Login;
