import React from 'react'
import r1 from '../assets/r1.png'
const Sec = () => {
  return (
    <div className='h-fit border-solid border-1 border-gray-300'>
    <div className="relative mt-0 w-full bg-maincolor p-5">
      <div className="flex flex-col gap-10 px-4 py-8 sm:flex-row">

        
        <div className="w-full sm:w-1/2 border-solid rounded-lg bg-maincolor border-1 p-4">
          <div className="w-full h-[300px] sm:h-[441px] bg-cover bg-center rounded-xl">
            <img className="w-full h-full bg-cover bg-center bg-no-repeat rounded-xl" src={r1} alt="Your Image" />
          </div>
        </div>


        <div className="w-full sm:w-1/2 border-solid rounded-lg bg-maincolor border-1">
      <div className="flex-col items-start gap-4 sm:gap-6 w-full h-auto sm:h-full">

      <div className="font-bold text-2xl sm:text-3xl font-dmsans flex items-center p-2 text-line bg-maincolor  ">About TUMAANEX</div>
        <p className="font-normal text-base sm:text-16 leading-180.5% mx-2 font-dmsans text-line">
          Welcome to TUMAANEX, a trailblazing platform where the future of digital asset trading converges with seamless innovation. At TUMAANEX, we stand at the forefront of redefining the landscape of peer-to-peer (P2P) USDT trading, breaking free from conventional norms to offer you an unparalleled decentralized experience.
        </p>

        {/* Our Vision of Decentralization */}
        <div className="font-bold text-2xl sm:text-3xl flex items-center p-2 text-line">Our Vision of Decentralization</div>
        <p className="font-normal text-base sm:text-16 leading-180.5% mx-2 font-dmsans text-line">
          At TUMAANEX, decentralization isn't just a buzzword; it's the cornerstone of our ethos. We believe in a financial ecosystem that is liberated from centralized control, putting the power back into the hands of the people. As a decentralized exchange, TUMAANEX is not governed by a single entity but thrives on the collective strength of our community.
        </p>
      </div>
    </div>


      </div>
    </div>
  </div>
  )
}

export default Sec