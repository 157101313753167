import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import dashboard from "../assets/dashboard1.png";
import funding from "../assets/funding.png";
import p2p from "../assets/p2p_marketplace.png";
import guidesection from "../assets/guidesection.png";
import information from "../assets/information.png";
import { Link } from "react-router-dom";
import env from "react-dotenv";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CiMenuBurger } from "react-icons/ci";
const SideMenu = ({ menuVisible, toggleMenu, currentState }) => {
  const navigate = useNavigate();
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  const dashboard_menu = [
    { name: "Dashboard", image: dashboard, link: "/dashboard", role: "user" },
    { name: "Funding Market", image: funding, link: "/funding", role: "user" },

    { name: "Merchant", image: funding, link: "/merchant_list", role: "admin" },
    {
      name: "Deposit Rules",
      image: dashboard,
      link: "/deposit_list",
      role: "admin",
    },
    {
      name: "Withdraw Rules",
      image: funding,
      link: "/withdraw_list",
      role: "admin",
    },
    { name: "Verify Orders", image: p2p, link: "/order", role: "admin" },
    {
      name: "Verify Orders Buy/Sell",
      image: p2p,
      link: "/order_buy_sell",
      role: "admin",
    },
    { name: "Contact", image: information, link: "/contact", role: "admin" },
    { name: "User", image: information, link: "/user_list", role: "admin" },
  ];
  const dashboard_menu_bottom = [
    {
      name: "Guide Section",
      image: guidesection,
      link: "/guide",
      role: "user",
    },
    { name: "About Us", image: information, link: "/about", role: "user" },
  ];

  const [role, setRole] = useState();
  useEffect(() => {
    if (localStorage.getItem("user")) {
      const datarole = JSON.parse(localStorage.getItem("user")).role;
      setRole(datarole);
    }
  }, []);

  return (
    <>
      {isMenuVisible == true ? (
        <div className=" flex  bg-background 2xl:hidden lg:hidden  xl:hidden items-end  justify-end  p-0 ">
          <button
            className="p-1 border-0 text-textColor  ml-auto bg-transparent   text-3xl leading-none font-semibold outline-none focus:outline-none "
            onClick={() => setIsMenuVisible(false)}
          >
            <span className="bg-transparent text-line ">×</span>
          </button>
        </div>
      ) : (
        <div className="relative  top-2 lg:top-20 md:top-20 sm:top-20 xl:top-20  left-0 z-50">
          <button
            className="p-1 border-0 text-textColor  ml-auto bg-transparent   text-3xl leading-none font-semibold outline-none focus:outline-none "
            onClick={() => setIsMenuVisible(true)}
          >
            <span className="bg-transparent text-line ">
              <CiMenuBurger></CiMenuBurger>
            </span>
          </button>
        </div>
      )}

      {isMenuVisible == true ? (
        <div className="grid grid-rows-3 grid-flow-row grid-auto-rows-[1fr] h-fit bg-background  ">
          <div className="row-start-1 bg-background">
            {dashboard_menu.map((element, index) =>
              role === element.role ? (
                <div
                  className="mx-auto items-center justify-center flex-shrink-0"
                  key={index}
                >
                  <div className="flex col-2 text-line w-full p-4 mx-auto shadow-md">
                    <Link
                      to={element.link}
                      className="flex col-2 text-line w-full p-2 mx-auto shadow-md"
                    >
                      <div className="flex gap-1 justify-center">
                        <div className="flex text-line text-sm font-dmsans font-normal leading-5 text-center">
                          <img
                            src={element.image}
                            className="h-6"
                            alt={`${element.name} Icon`}
                          />
                        </div>
                        <div className="text-line items-center text-sm font-dmsans font-normal leading-5 text-center mt-1">
                          {element.name}
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                <></>
              )
            )}
          </div>

          <div className=" 2xl:row-start-3 xl:row-start-3 lg:row-start-3 row-start-2 bg-background flex justify-start items-end mt-0 mb-0">
            <div className="row-start-1 bg-background">
              {dashboard_menu_bottom.map((element, index) =>
                role === element.role ? (
                  <div
                    className="mx-auto items-center justify-center flex-shrink-0"
                    key={index}
                  >
                    <div className="flex col-2 text-line w-full p-4 mx-auto shadow-md">
                      <Link
                        to={element.link}
                        className="flex col-2 text-line w-full p-2 mx-auto shadow-md"
                      >
                        <div className="flex gap-1 justify-center">
                          <div className="flex text-line text-sm font-dmsans font-normal leading-5 text-center">
                            <img
                              src={element.image}
                              className="h-6"
                              alt={`${element.name} Icon`}
                            />
                          </div>
                          <div className="text-line items-center text-sm font-dmsans font-normal leading-5 text-center mt-1">
                            {element.name}
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              )}

              <div className="mx-auto  justify-start  items-end  ">
                <div className="flex  items-center justify-center   lg:w-max md:w-max sm:w-max ">
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                </div>

                <div className="flex col-2 text-line w-full p-7  items-end shadow-md ">
                  <div className="flex gap-2 justify-center">
                    <div className="text-line text-sm font-dmsans font-normal leading-5 text-center">
                      <img
                        src={p2p}
                        className="w-6 h-6 text-line"
                        alt={`Log out  Icon`}
                      />
                    </div>
                    <div className="text-line items-center text-sm font-dmsans font-normal leading-5 text-center mt-1">
                      <button
                        onClick={(e) => {
                          let user = localStorage.getItem("user");

                          const userValues = { email: user.email };

                          const logout = axios
                            .post(env.API_URL + "auth/logout", userValues, {})
                            .then((response) => {
                              if (response.status == 200) {
                                localStorage.removeItem("user");
                                navigate("/login");
                              }
                            })
                            .catch((err) => {});
                        }}
                      >
                        Log out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SideMenu;
