  import React from "react";
  import { CgChevronDown } from "react-icons/cg";
  import { CgChevronUp } from "react-icons/cg";
  import { useEffect } from "react";
  import { useState } from "react";
  import Slider from 'react-slick';
  import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
  import { Carousel } from 'react-responsive-carousel';
  import 'slick-carousel/slick/slick-theme.css';
  import 'slick-carousel/slick/slick.css';
  import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

  const Faqs = () => {
    const [faqs, setFaqs] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [martusd1, setmartusd1] = useState(false);
    const [martusd2, setmartusd2] = useState(false);
    const [martusd3, setmartusd3] = useState(false);
    const [martusd4, setmartusd4] = useState(false);
    let totalPages = 7
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };


    const handlePreviousPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      }
    };

    const handleNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };
    const checkPage = (page) => {

      switch (page) {
        case 1:
    
          return "Why choose TUMAANEX?"; 
        case 2:
          // setFaqs(2);
          return "Basics of TUMAANEX";
        case 3:
          // setFaqs(3);
          return "Advanced Trading and Features";
        case 4:
          // setFaqs(4);
          return "Account and Security";
        case 5:
          // setFaqs(5);
          return "Fees and Payments";
        case 6:
          // setFaqs(5);
          return "Deposit and Withdrawal";
        case 7:
          // setFaqs(6);
          return "Support and Assistance";
        default:
          return "";
      }
    };
    
    useEffect(() => {
      // Call setFaqs here when currentPage changes
      switch (currentPage) {
        case 1:
          setFaqs(1);
          break;
        case 2:
          setFaqs(2);
          break;
                case 3:
          setFaqs(3);
          break;
          case 4:
            setFaqs(4);
            break;
            case 5:
              setFaqs(5);
              break;
              case 6:
                setFaqs(6);
                break;
                case 7:
                  setFaqs(7);
                  break;
        // Add cases for other pages as needed
        default:
          setFaqs(null);
          break;
      }
    }, [currentPage]);


    const getPages = () => {
      const maxVisiblePages = 7;
      const sidePages = Math.floor((maxVisiblePages - 0) / 1);
      const pages = [];
    
      pages.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`px-3 py-1 font-medium rounded font-dmsans mx-1 items-center leading-6 ${
            currentPage === 1
              ? "bg-[#E1E1E1] text-gray-600 h-7 items-start rounded-lg"
              : "bg-maincolor text-line"
          }`}
        >
          {checkPage(1)}
        </button>
      );
    
      let startPage = Math.max(2, currentPage - sidePages);
      let endPage = Math.min(totalPages - 1, currentPage + sidePages);
      let addStartDots = false;
      let addEndDots = false;
    
      if (endPage < totalPages - 1) {
        addEndDots = true;
      }
    
      for (let page = startPage; page <= endPage; page++) {
        pages.push(
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`px-3 py-1 font-medium rounded font-dmsans mx-1 items-center leading-6 ${
              currentPage === page
                ? "bg-[#E1E1E1] text-gray-600 h-7 items-center"
                : "bg-maincolor text-line"
            }`}
          >
            {checkPage(page)}
          </button>
        );
      }
    
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={`px-3 py-1 font-medium rounded font-dmsans mx-1 items-center leading-6 ${
            currentPage === totalPages
              ? "bg-[#E1E1E1] text-gray-600 h-7 items-center"
              : "bg-maincolor text-line"
          }`}
        >
          {checkPage(totalPages)}
        </button>
      );
    
      return pages;
    };
    
    
    

    return (

      
      <div className=" font-dmsans ">
  <div className="flex py-10 w-full justify-center bg-newbgcolor border-1 border-solid rounded-xl">
  <p className="text-line font-bold text-3xl font-dmsans justify-center">
    FAQs
  </p>
</div>




        <div className="bg-newbgcolor h-max border-1 border-solid rounded-xl ">
        <div className="flex flex-col bg-newbgcolor p-4 md:p-7 md:w-full mb-2">
  <div className="flex items-center mb-4">
    <button
      onClick={handlePreviousPage}
      disabled={currentPage === 1}
      className="font-dmsans md:px-5 md:block  md:w-16 md:h-16 h-10 w-10 py-1 font-medium border-solid border-2 bg-maincolor text-line rounded-xl border-gray-300 md:mb-0 mr-2"
    >
      <FaAngleLeft className="w-6 h-6 font-medium" />
    </button>
    <div className="flex flex-col bg-maincolor p-0 md:p-4 mt-2 items-center w-full mb-2 rounded-xl">
  <div className="flex flex-wrap justify-center mb-2 bg-maincolor items-center rounded-xl">
    {getPages()}
  </div>
</div>

    <button
      onClick={handleNextPage}
      disabled={currentPage === totalPages}
      className="font-dmsans md:block  md:px-5  md:w-16 md:h-16 h-10 w-10 py-0 font-medium border-solid border-2 bg-maincolor text-line rounded-xl border-gray-300 md:mb-0 ml-2"
    >
      <FaAngleRight className="w-6 h-6 font-dmsans font-medium" />

    </button>

  </div>
</div>



          <div className="  flex   md:mx-0 md:mt-0 mt-1 px-4">
            
            {/* <div className="   h-max px-20 py-10  ">





              <div className="flex flex-col  xl:w-max 2xl:w-max lg:w-max   ">
                <div className="flex  py-10 ">
                  <p className="text-line font-bold text-3xl font-dmsans">
                    FAQs
                  </p>
                </div>
            
                <div className="  ">
                  <p
                    className={`${
                      faqs == 1 ? "text-[#B28EFB]" : " text-line"
                    } mt-2 font-dmsans text-sm`}
                  >
                    <button onClick={() => setFaqs(1)}>
                      
                      What is TUMAANEX?
                    </button>
                  </p>
                </div>

                <div className="flex  items-center justify-center  mt-3 mb-3  w-max">
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                </div>

                <div className="  ">
                  <p
                    className={`${
                      faqs == 2 ? "text-[#B28EFB]" : " text-line"
                    } mt-2 font-dmsans text-sm`}
                  >
                    
                    <button onClick={() => setFaqs(2)}>
                      
                      Basics of TUMAANEX
                    </button>
                  </p>
                </div>

                <div className="flex  items-center justify-center  mt-3 mb-3 ">
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                </div>

                <div className="">
                  <p
                    className={`${
                      faqs == 3 ? "text-[#B28EFB]" : " text-line"
                    } mt-2 font-dmsans text-sm`}
                  >
                    
                    <button onClick={() => setFaqs(3)}>
                      
                      Advanced Trading and Features
                    </button>
                  </p>
                </div>

                <div className="flex  items-center justify-center  mt-3 mb-3 ">
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                </div>

                <div className="flex w-full ">
                  <p
                    className={`${
                      faqs == 4 ? "text-[#B28EFB]" : " text-line"
                    } mt-2 font-dmsans text-sm`}
                  >
                    
                    <button onClick={() => setFaqs(4)}>
                      
                      Account and Security
                    </button>
                  </p>
                </div>

                <div className="flex  items-center justify-center  mt-3 mb-3 w-full">
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                </div>

                <div className="flex w-full ">
                  <p
                    className={`${
                      faqs == 5 ? "text-[#B28EFB]" : " text-line"
                    } mt-2 font-dmsans text-sm`}
                  >
                    
                    <button onClick={() => setFaqs(5)}>Fees and Payments</button>
                  </p>
                </div>

                <div className="flex  items-center justify-center  mt-3 mb-3 w-full">
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                </div>

                <div className="flex w-full ">
                  <p
                    className={`${
                      faqs == 6 ? "text-[#B28EFB]" : " text-line"
                    } mt-2 font-dmsans text-sm`}
                  >
                    
                    <button onClick={() => setFaqs(6)}>Deposit and Withdrawal</button>
                  </p>
                </div>


                <div className="flex  items-center justify-center  mt-3 mb-3 w-full">
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                </div>

                <div className="flex w-full ">
                  <p
                    className={`${
                      faqs == 7  ? "text-[#B28EFB]" : " text-line"
                    } mt-2 font-dmsans text-sm`}
                  >
                    
                    <button onClick={() => setFaqs(7)}>Support and Assistance</button>
                  </p>
                </div>
    
              </div> 
            </div>*/}

            {faqs == 1 ? (
              <div className="py-5 w-full  md:mt-4 mt-48">
                <div className="flex flex-col gap-4 p-2 w-full">
                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div className="">
                        <p
                          className={` ${
                            martusd1 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          What is P2P Marketplace?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd1 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd1(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd1(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd1 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      P2P stands for peer-to-peer, and P2P marketplaces are
                      platforms that allow users to buy and sell crypto for fiat
                      currency directly with other users.
                    </div>
                  </div>

                  <div className="flex  items-center justify-center  mt-1 mb-1 w-full ">
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  </div>

                  <div className="flex grid-col gap-2 bg-newbgcolor">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd2 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          What is TUMAANEX?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd2 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd2(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd2(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2  font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd2 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      TUMAANEX is Asia's premier peer-to-peer (P2P) USDT trading
                      platform, connecting buyers and sellers directly for secure
                      and efficient USDT transactions.
                    </div>
                  </div>

                  <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  </div>

                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd3 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          How does TUMAANEX work?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd3 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd3(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd3(true);
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2  font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd3 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      TUMAANEX facilitates direct peer-to-peer trading between
                      users. Merchants list their offers, and buyers can choose
                      from available offers. Transactions occur through escrow,
                      ensuring security.
                    </div>
                  </div>

                  <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  </div>

                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd4 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          How do I sign up for TUMAANEX?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd4 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd4(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd4(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2  font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd4 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      Visit our registration page and provide the required
                      information. After registration, you can log in and start
                      trading.
                    </div>
                  </div>
                </div>
              </div>
            ) : faqs == 2 ? (
              <div className="py-5 w-full  md:mt-4 mt-48">
             <div className="flex flex-col gap-4 p-2 w-full">
                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd1 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          How do I buy USDT on TUMAANEX?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd1 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd1(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd1(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd1 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      Browse available buy offers, select an offer, and follow the
                      instructions to complete the trade. Payment details and
                      communication with the seller are facilitated through the
                      platform.
                    </div>
                  </div>

                  <div className="flex  items-center justify-center  mt-1 mb-1 w-full ">
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  </div>

                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd2 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          How do I sell USDT on TUMAANEX?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd2 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd2(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd2(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2  font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd2 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      If you want to start selling on TUMAANEX, you have to fill
                      out our merchant registration form and then we will process
                      your request within 2-3 working days. You will be asked some
                      documents as a proof and then you are good to go.
                    </div>
                  </div>

                  <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  </div>

                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd3 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          Is it safe to trade on TUMAANEX?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd3 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd3(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd3(true);
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2  font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd3 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      Yes, TUMAANEX employs advanced security measures, including
                      escrow services, to ensure safe and secure transactions.
                      Additionally, user reviews and ratings contribute to
                      transparency and trust.
                    </div>
                  </div>
                </div>
              </div>
            ) : faqs == 3 ? (
              <div className="py-5 w-full  md:mt-4 mt-48">
                 <div className="flex flex-col gap-4 p-2 w-full">
                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd1 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          Can I trade cryptocurrencies other than USDT on Mart
                          USDT?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd1 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd1(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd1(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd1 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      Currently, TUMAANEX focuses exclusively on USDT trading.
                      However, stay tuned for updates on any future additions to
                      our trading options.
                    </div>
                  </div>

                  <div className="flex  items-center justify-center  mt-1 mb-1 w-full ">
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  </div>

                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd2 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          What is the escrow system, and why is it important?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd2 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd2(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd2(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2  font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd2 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      The escrow system holds the seller's USDT in a secure
                      account until the transaction is completed. This prevents
                      fraud and ensures that both parties fulfill their
                      obligations.
                    </div>
                  </div>

                  <div className="flex  items-center justify-center  mt-1 mb-1 w-full">
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  </div>

                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd3 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          How do I dispute a trade on TUMAANEX?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd3 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd3(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd3(true);
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2  font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd3 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      In case of a dispute, both parties can initiate a dispute
                      resolution process. Our support team will intervene to help
                      reach a fair resolution based on the evidence provided.
                    </div>
                  </div>
                </div>
              </div>
            ) : faqs == 4 ? (
              <div className="py-5 w-full  md:mt-0 sm:mt-10 mt-48">
                <div className="flex flex-col gap-4 p-2 w-full">
                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd1 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          How do I enable two-factor authentication (2FA)?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd1 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd1(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd1(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd1 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      Go to your account settings and enable 2FA using an
                      authentication app. This adds an extra layer of security to
                      your TUMAANEX account.
                    </div>
                  </div>

                  <div className="flex  items-center justify-center  mt-1 mb-1 w-full ">
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  </div>

                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd2 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          How do I reset my password?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd2 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd2(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd2(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2  font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd2 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      You can initiate a password reset from the login page.
                      Follow the instructions sent to your registered email to
                      reset your password.
                    </div>
                  </div>
                </div>
              </div>
            ) : faqs == 5 ? (
              <div className="py-5 w-full  md:mt-4 mt-48">
                <div className="flex flex-col gap-4 p-2 w-full">
                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd1 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          Are there any fees for trading on TUMAANEX?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd1 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd1(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd1(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd1 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      Yes, TUMAANEX charges a small fee for completed trades but
                      they are only charged from Merchants. So, for you it is
                      completely free of cost and there are no hidden charges as
                      well.
                    </div>
                  </div>

                  <div className="flex  items-center justify-center  mt-1 mb-1 w-full ">
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                    <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  </div>

                  <div className="flex grid-col gap-2">
                    <div className="flex justify-between col-span-3">
                      <div>
                        <p
                          className={` ${
                            martusd2 == true ? "text-[#B28EFB]" : "text-line"
                          } mt-2 font-dmsans text-sm`}
                        >
                          Which payment methods are accepted on TUMAANEX?
                        </p>
                      </div>
                    </div>
                    <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                      {martusd2 == true ? (
                        <CgChevronUp
                          className="text-line"
                          onClick={() => {
                            setmartusd2(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="text-line"
                          onClick={() => {
                            setmartusd2(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2  font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                        martusd2 ? "" : "h-0 overflow-hidden"
                      }`}
                    >
                      TUMAANEX supports a range of payment methods, including
                      bank transfers, mobile payments, and more. You can choose
                      any payment method according to your needs.
                    </div>
                  </div>
                </div>
              </div>
            ) : faqs == 6 ?  (
              <div className="py-5 w-full  md:mt-4 mt-48">
              <div className="flex flex-col gap-4 p-2 w-full">
                <div className="flex grid-col gap-2">
                  <div className="flex justify-between col-span-3">
                    <div>
                      <p
                        className={` ${
                          martusd1 == true ? "text-[#B28EFB]" : "text-line"
                        } mt-2 font-dmsans text-sm`}
                      >
                          How do I withdraw funds to other exchanges?
                      </p>
                    </div>
                  </div>
                  <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                    {martusd1 == true ? (
                      <CgChevronUp
                        className="text-line"
                        onClick={() => {
                          setmartusd1(false);
                        }}
                      />
                    ) : (
                      <CgChevronDown
                        className="text-line"
                        onClick={() => {
                          setmartusd1(true);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <div
                    className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                      martusd1 ? "" : "h-0 overflow-hidden"
                    }`}
                  >
  After Login, Head over to your “Funding Wallet Section” and click on “Withdraw” and select the network on which you want to withdraw your USDTs. Please be careful of different fees on different networks. We recommend using BEP-20 since it is the cheapest option. Provide your address on which you want to withdraw and that’s it.   
              </div>
                </div>

                <div className="flex  items-center justify-center  mt-1 mb-1 w-full ">
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                </div>

                <div className="flex grid-col gap-2">
                  <div className="flex justify-between col-span-3">
                    <div>
                      <p
                        className={` ${
                          martusd2 == true ? "text-[#B28EFB]" : "text-line"
                        } mt-2 font-dmsans text-sm`}
                      >
  How do I deposit if I want to sell my USDTs?

                      </p>
                    </div>
                  </div>
                  <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                    {martusd2 == true ? (
                      <CgChevronUp
                        className="text-line"
                        onClick={() => {
                          setmartusd2(false);
                        }}
                      />
                    ) : (
                      <CgChevronDown
                        className="text-line"
                        onClick={() => {
                          setmartusd2(true);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <div
                    className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2  font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                      martusd2 ? "" : "h-0 overflow-hidden"
                    }`}
                  >
  After Login, Head over to your “Funding Wallet Section” and click on “Deposit”. You will be provided with a BEP-20 address to deposit your USDTs. Simply select the desired amount you wish to deposit and proceed. After completing your deposit your balance will be updated.
                  </div>
                </div>
              </div>
            </div> 

            )
            : faqs == 7 ? (
              <div className="py-5 w-full  md:mt-4 mt-48">
              <div className="flex flex-col gap-4 p-2 w-full">
                <div className="flex grid-col gap-2">
                  <div className="flex justify-between col-span-3">
                    <div>
                      <p
                        className={` ${
                          martusd1 == true ? "text-[#B28EFB]" : "text-line"
                        } mt-2 font-dmsans text-sm`}
                      >
                        How can I contact TUMAANEX's customer support?
                      </p>
                    </div>
                  </div>
                  <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                    {martusd1 == true ? (
                      <CgChevronUp
                        className="text-line"
                        onClick={() => {
                          setmartusd1(false);
                        }}
                      />
                    ) : (
                      <CgChevronDown
                        className="text-line"
                        onClick={() => {
                          setmartusd1(true);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <div
                    className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                      martusd1 ? "" : "h-0 overflow-hidden"
                    }`}
                  >
  Visit our contact page to reach out to our dedicated customer support team. We're here to assist you with any questions or concerns you may have.             </div>
                </div>

                <div className="flex  items-center justify-center  mt-1 mb-1 w-full ">
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                </div>

                <div className="flex grid-col gap-2">
                  <div className="flex justify-between col-span-3">
                    <div>
                      <p
                        className={` ${
                          martusd2 == true ? "text-[#B28EFB]" : "text-line"
                        } mt-2 font-dmsans text-sm`}
                      >
  What if a user's behavior raises suspicion of fraudulent activity?

                      </p>
                    </div>
                  </div>
                  <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                    {martusd2 == true ? (
                      <CgChevronUp
                        className="text-line"
                        onClick={() => {
                          setmartusd2(false);
                        }}
                      />
                    ) : (
                      <CgChevronDown
                        className="text-line"
                        onClick={() => {
                          setmartusd2(true);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <div
                    className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2  font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                      martusd2 ? "" : "h-0 overflow-hidden"
                    }`}
                  >
                    TUMAANEX takes suspicious activity seriously. If you suspect fraudulent behavior, contact our support team, and we will investigate the matter promptly.
                    <br>
                    </br>
                    <br>
                    </br>
                    
                    Stay Informed: Updates and News
                      </div>
                </div>




                <div className="flex  items-center justify-center  mt-1 mb-1 w-full ">
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                  <div className="flex-grow border-t bg-line opacity-5 border-line"></div>
                </div>

                <div className="flex grid-col gap-2">
                  <div className="flex justify-between col-span-3">
                    <div>
                      <p
                        className={` ${
                          martusd3 == true ? "text-[#B28EFB]" : "text-line"
                        } mt-2 font-dmsans text-sm`}
                      >
  Where can I find news and updates about TUMAANEX?

                      </p>
                    </div>
                  </div>
                  <div className="flex text-line min-w-max col-span-1 mt-2 ml-auto">
                    {martusd3 == true ? (
                      <CgChevronUp
                        className="text-line"
                        onClick={() => {
                          setmartusd3(false);
                        }}
                      />
                    ) : (
                      <CgChevronDown
                        className="text-line"
                        onClick={() => {
                          setmartusd3(true);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <div
                    className={`text-line 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2  font-dmsans text-sm whitespace-break-spaces leading-6 relative ${
                      martusd3 ? "" : "h-0 overflow-hidden"
                    }`}
                  >
                    Stay informed about the latest news, updates, and announcements by visiting our official website, social media channels, and community forums.

  These FAQs cover a wide range of topics, from the basics of trading on TUMAANEX to more advanced questions. If you have any additional inquiries, feel free to reach out to our customer support team for assistance. Happy trading on TUMAANEX!

                      </div>
                </div>

              </div>
            </div> 

            ) :
            <></>
          }
  
          </div>
        </div>
      </div>
    );
  };

  export default Faqs;
