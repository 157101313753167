import React from "react";

import MenuHeader from "../Container/MenuHeader";
import Header from "../Container/Header";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import GetApiData from "../helpers/getApiData";
import moment from "moment";

import { Link } from "react-router-dom";
import { IoEllipse } from "react-icons/io5";
import { BiLinkExternal } from "react-icons/bi";
import { CgChevronDown } from "react-icons/cg";
import { CgChevronUp } from "react-icons/cg";

import im from "../assets/logo.png";
import getApiData from "../helpers/getApiData";
import { socket } from "../utils/global";
import Footer from "./Footer";

const Funding = () => {
  const [feeds, setFeeds] = useState([]);
  const [isNewFeed, setIsNewFeed] = useState(false);
  const [showAllFeeds, setShowAllFeeds] = useState(false);
  const numberOfFeeds = 10;
  const displayedFeeds = showAllFeeds ? feeds : feeds.slice(0, numberOfFeeds);

  const navigate = useNavigate(true);
  const [balance, setBalance] = useState();
  const [transaction, setTransaction] = useState();
  const [orderdetail, setorderdetail] = useState();
  const [notification, setnotification] = useState();

  const [responsedw, setdw] = useState({});
  const [responseOrder, setresponseOrder] = useState({});

  const [user, setuserData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (localStorage.getItem("user")) {
        const id = JSON.parse(localStorage.getItem("user"))._id;
        const data = await GetApiData("auth/get_balance" + "/" + id, "");

        setBalance(data.balance);

        const userId = JSON.parse(localStorage.getItem("user"))._id;
        const response = await getApiData(
          "merchant/get_all_order/" + userId + "/" + "dw",
          ""
        );
        setdw(response.order);

        const responseOrders = await getApiData(
          "merchant/get_all_order/" + userId + "/" + "new",
          ""
        );

        setresponseOrder(responseOrders.order);
      } else {
        navigate("/home");
      }
    }

    fetchData();
  }, []);

  function handleScroll() {
    window.scroll({
      top: document.body.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const role = JSON.parse(localStorage.getItem("user")).role;
      if (role == "admin") {
        navigate("/NotFound");
      }
      setuserData(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  useEffect(() => {
    socket.emit("initial_data");
    socket.on("get_data", getData);
    socket.on("change_data", changeData);
    return () => {
      socket.off("get_data");
      socket.off("change_data");
    };
  }, []);

  const getData = (feeds) => {
    setFeeds(feeds);
  };

  const changeData = () => socket.emit("initial_data");

  return (
    <>
      <Header />
      <MenuHeader />
      <div className="flex flex-col min-h-screen">
        <div className="flex flex-col h-fit bg-newbgcolor">
          <div className="bg-bg-newbgcolor   relative w-full top-0">
            <div className="grid grid-cols-1 lg:grid-cols-10 gap-0">
              <div className="bg-newbgcolor  relative px-3 py-5 col-span-4">
                <h1 className="text-line font-dmsans font-bold text-2xl leading-8">
                  Funding Wallet
                </h1>
              </div>

              <div className="flex flex-row gap-2 p-5 w-full   lg:w-44 mt-2 lg:mt-0">
                <button
                  onClick={() => {
                    navigate("/deposit");
                  }}
                  className="flex p-3 px-6 rounded-sm text-newbgcolor font-dmsans font-normal text-sm bg-textColor mb-2 w-full"
                >
                  Deposit
                </button>

                <button
                  onClick={() => {
                    navigate("/withdraw");
                  }}
                  className="flex p-3 px-6 rounded-sm text-newbgcolor font-dmsans font-normal text-sm bg-textColor mb-2 w-full"
                >
                  Withdraw
                </button>
              </div>
            </div>
            <div className="flex  justify-start">
              <span className="mb-3 flex  mx-3 text-xl  font-bold font-dmsans justify-start  text-subheading-400  text-textColor">
                Balance {balance && balance.toFixed(2)} USD
              </span>
            </div>

            <div className=" grid grid-cols-1 md:grid-cols-3 gap-0 relative top-4 mt-0 ">
              <div className="w-full bg-newbgcolor relative p-2 col-span-2">
                <div className="bg-newbgcolor md:ml-4 md:mr-4 p-4 md:p-5 border border-maincolor rounded-xl">
                  <div className="flex flex-col md:flex-row md:gap-4">
                    <div className="bg-newbgcolor mb-2 md:mb-0 md:mr-2 md:flex-grow">
                      <div className="flex grid-row gap-0">
                        <p className="font-dmsans text-lg font-normal text-line">
                          Account Balance
                          <span className="mx-2 text-textColor">USDT</span>
                        </p>
                        {transaction == true ? (
                          <CgChevronUp
                            className=" font-bold  text-textColor justify-start mt-2 "
                            onClick={(e) => {
                              setTransaction(false);
                            }}
                          />
                        ) : (
                          <CgChevronDown
                            className=" font-bold  text-textColor justify-start mt-2 "
                            onClick={(e) => {
                              setTransaction(true);
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div className="flex items-end justify-end">
                      <p className="font-dmsans leading-10 font-bold text-line text-4xl">
                        {balance && balance.toFixed(2)} $
                      </p>
                    </div>
                  </div>
                  {transaction == true ? (
                    <>
                      <div className="w-full h-6 mt-2 md:mt-3">
                        <span className="font-dmsans text-textColor text-base font-normal leading-5">
                        Verified   Transaction History
                        </span>
                      </div>

                      <div className="flex flex-col gap-2">
                        {responsedw &&
                          responsedw.map((item, index) => (
                            <div     key={index} className="h-14 border border-maincolor rounded-xl border-solid bg-maincolor flex">
                                  <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                                <div className="flex flex-col md:flex-row md:items-center">
                                  <div className="mt-2 md:mr-4">
                                    {item.type === "deposit" ? (
                                      <p className="text-line text-xs font-normal leading-5 font-dmsans">
                                        Received Amount (Deposited Amount)
                                      </p>
                                    ) : (
                                      <p className="text-line text-xs font-normal leading-5 font-dmsans">
                                        Withdrawn Amount (Withdrawn Amount)
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="flex justify-end">
                                  <p className="text-line text-xs font-normal leading-5 font-dmsans">
                                    {item.amount}$
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="bg-newbgcolor md:ml-4 md:mr-4 p-4 md:p-5 border border-maincolor rounded-xl  mt-4 ">
                  <div className="flex flex-col md:flex-row md:gap-4">
                    <div className="bg-newbgcolor mb-2 md:mb-0 md:mr-2 md:flex-grow">
                      <p className="font-dmsans text-lg font-normal text-line">
                        Order Detail
                      </p>
                    </div>
                    <div className="flex items-end justify-end">
                      <p className="font-dmsans leading-8 md:leading-10 font-bold text-line text-2xl md:text-4xl whitespace-break-spaces">
                        {Array.isArray(responseOrder)
                          ? responseOrder
                              .reduce((acc, obj) => {
                                return acc + (obj.type == "buy" ?obj.amount : obj.quantity);
                              }, 0)
                              .toFixed(2)
                          : "responseOrder is not an array"}
                      </p>
                    </div>
                  </div>
                  <div className="w-full h-6 mt-2 md:mt-3">
                    <div className="flex grid-row gap-0">
                      <span className="font-dmsans text-textColor text-base font-normal leading-5">
                        Orders
                      </span>

                      {orderdetail == true ? (
                        <CgChevronUp
                          className=" mx-2 font-bold  text-textColor justify-start mt-1"
                          onClick={(e) => {
                            setorderdetail(false);
                          }}
                        />
                      ) : (
                        <CgChevronDown
                          className="mx-2 font-bold  text-textColor justify-start mt-1"
                          onClick={(e) => {
                            setorderdetail(true);
                          }}
                        />
                      )}
                    </div>
                  </div>

                  {orderdetail == true ? (
                    <div>
                      <div className="flex flex-col gap-2">
                        {responseOrder &&
                          responseOrder.map((item, index) => (
                            <div
                              key={index}
                              className="border border-maincolor rounded-xl   bg-maincolor mb-4 p-3"
                            >
                              <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                                <div className="flex flex-col md:flex-row md:items-center">
                                  <div className="mt-2 md:mr-4">
                                    <p className="text-line text-xs font-normal leading-5 font-dmsans">
                                 <p className="text-line ">     Order ID: {item._id} | Type: {item.type}
                                 </p>
                                      <Link
                                        to="/single_order"
                                        state={{
                                          id: item._id,
                                          type: item.type,
                                        }}
                                      >
                                        <BiLinkExternal />
                                      </Link>
                                    </p>
                                    <div className="flex flex-col gap-1 md:grid md:grid-cols-2 md:gap-2">
                                    <div className="font-dmsans font-normal text-xs text-line leading-4">
                                      Created at
                                    </div>
                                
                                    <div >
                                      {/* <IoEllipse className="w-2 h-2 text-softColor items-center mt-1" /> */}
                                    </div>
                                    <div className="font-dmsans font-normal text-xs text-softColor leading-4">
                                      {moment(item.date).format("MMMM D, YYYY")}
                                    </div>
                                    
                                    <div className="font-dmsans font-normal text-xs text-softColor leading-4">
                                      {moment(item.date).format("HH:mm:ss")}
                                    </div>
                                  </div>
                                  </div>
                                  
                                </div>
                                <div className="mt-2 md:mt-0 md:flex-grow">
                                  <p className="text-line text-xs font-normal leading-5 font-dmsans text-right">
                                       {item.type == "buy" ? item.amount && item.amount.toFixed(2) : item.quantity && item.quantity.toFixed(2) } $
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="bg-newbgcolor ">
                <div className="bg-newbgcolor  md:ml-4 md:mr-4 p-4 md:p-5 border border-solid rounded-xl border-maincolor  mt-4">
                  <div className="bg-newbgcolor  border border-transparent rounded-xl  mt-4">
                    <div className="flex flex-col md:flex-row md:gap-4">
                      <p className="font-dmsans text-lg font-normal text-line">
                        Notifications
                      </p>
                    </div>

                    <div className="h-6 mt-2 md:mt-3">
                      <div className="flex grid-row gap-0">
                        <span className="font-dmsans text-textColor text-base font-normal leading-5">
                          All
                        </span>

                        {notification == true ? (
                          <CgChevronUp
                            className=" font-bold  text-textColor justify-start mt-1 "
                            onClick={(e) => {
                              setnotification(false);
                            }}
                          />
                        ) : (
                          <CgChevronDown
                            className=" font-bold  text-textColor justify-start mt-1 "
                            onClick={(e) => {
                              setnotification(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {notification === true &&
                      displayedFeeds.map(
                        (feed, index) =>
                          (feed.userId === user._id ||
                            user.role === "admin") && (
                            <div className="flex flex-col gap-2" key={index}>
                              <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                                <div className="flex flex-col md:flex-row md:items-center mb-5 bg-maincolor border-solid border-maincolor rounded-xl">
                                  <div className="mt-2 md:mr-4 gap-5 ">
                                    <div className="flex flex-grid grid-cols-2 gap-2  ">
                                      <img
                                        className="w-10 h-10 rounded-sm"
                                        src={im}
                                        alt="Notification"
                                      />
                                      <p className="text-line  text-xs font-normal leading-5 font-dmsans mt-2">
                                        {feed.title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                      )}

                    <div>
                      {feeds.length > 5 && notification == true && (
                        <div>
                          {showAllFeeds ? (
                            <button
                              onClick={() => {
                                setShowAllFeeds(false);
                              }}
                              className="text-line font-dmsans font-normal cursor-pointer"
                            >
                              Hide Feeds
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                setShowAllFeeds(true);
                              }}
                              className="text-line font-dmsans font-normal cursor-pointer"
                            >
                              Show All Feeds
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-80  bg-newbgcolor"></div>
        <div className="h-16 bg-newbgcolor">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Funding;
