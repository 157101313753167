import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";

export const withdrawData = createAsyncThunk(
  "withdraw",

  async (payload, { rejectWithValue }) => {
    try {
      const request = await axios.get(env.API_URL + "merchant/get_withdraw");
      const response = await request.data;


      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message); 
    }
  }
);

const withdrawSlice = createSlice({
  name: "withdraw",
  initialState: {
    withdraws : [],
    loading: false,
    error: null,
  },
  reducers: {
    addDeposit: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(withdrawData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(withdrawData.rejected, (state, action) => {
  
        state.loading = true;
      })
      .addCase(withdrawData.fulfilled, (state, action) => {
          state.loading = false;
          state.withdraws = action.payload;
       
        }
      );
  },
});
export const { addDeposit } = withdrawSlice.actions;
export default withdrawSlice.reducer;

