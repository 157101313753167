import React, { useLayoutEffect, useEffect } from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import SideMenu from "../Container/SideMenu";
import getApiData from "../helpers/getApiData";
import { useState } from "react";
import { useFormik } from "formik";
import { ValidationDepositAmount } from "../utils/validationAmount";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import ToasterGen from "./ToasterGen";
const Deposit = () => {
  const [network, setNetwork] = useState();
  const [address, setAddress] = useState();
  const [currency, setCurrency] = useState();
  const [user, setUser] = useState();
  const navigate = useNavigate();

 

  const formik = useFormik({
    initialValues: {
      network: network,
      currency: currency,
      address: address,
      amount: "",
      userId: "",
      navigate:navigate,
    },
    validate: ValidationDepositAmount,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);

      return false;
    },
  });

  useEffect(() => {}, []);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const role = JSON.parse(localStorage.getItem("user")).role;
      if (role == "admin") {
        navigate("/NotFound");
      }
    }
  }, []);

  const LineConnector = ({ number }) => (
    <div className="flex justify-center items-center">
      <div className="w-7 h-7 bg-maincolor text-white rounded-full flex rounder-xl items-center justify-center font-bold text-lg">
        {number}
      </div>
      <div className="h-0.5 w-10 bg-maincolor ml-2 my-2" />
    </div>
  );
  useLayoutEffect(() => {
    async function fetchData() {
      const data = await getApiData("merchant/get_deposit_rules", "");

      setNetwork(data.deposit[0].network);
      setAddress(data.deposit[0].address);
      setCurrency(data.deposit[0].currency);

      formik.values.network = network;
      formik.values.address = network;
      formik.values.currency = network;

      if (localStorage.getItem("user")) {
        formik.values.userId = JSON.parse(localStorage.getItem("user"))._id;
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <ToasterGen></ToasterGen>

      <Header />
      <MenuHeader />

      <div className=" bg-newbgcolor">
        <div className="bg-newbgcolor relative  py-5">
          <h1 className=" font-dmsans mx-4 font-medium text-2xl leading-8  text-line  ">
            Deposit
          </h1>
        </div>
        <div className="p-10 bg-newbgcolor text-line border-maincolor rounded-xl  ">
          <div className="px-4 py-5 container mx-auto  border mt-4  border-maincolor  bg-maincolor rounded-xl  border-opacity-40">
            <div className="col-span-1">
              <div className="bg-maincolor p-6 md:p-8">

              <h1 className="mx-4  font-dmsans mb-2 font-medium text-2xl leading-8 text-line   ">
            Funding
          </h1>

                <span className=" mx-4 text-sm text-line flex justify-start font-dmsans">
                  Enter the Amount you want to Deposit
                </span>

                <form className="py-1" onSubmit={formik.handleSubmit}>
                  <div className="px-4 p-5 w-full h-full bg-maincolor">
                    {/* <div className="flex justify-center">
                      <div className="flex justify-center">
                        <div className="w-7 h-7 bg-textColor text-white rounded-full flex items-center justify-center font-bold text">
                          1
                        </div>
                      </div>
                    </div>
                    <span className="text-sm text-maincolor flex justify-center">
                      Network
                    </span>
                    <div className="flex bg-[#181322] text-maincolor py-6 font-normal text-2xl justify-center">
                      {network}
                    </div>

                    <div className="flex  items-center justify-center  mt-1 mb-5 w-full">
                      <div className="flex-grow border-t bg-line opacity-10 border-line"></div>
                      <div className="flex-grow border-t bg-line opacity-10 border-line"></div>
                    </div>

                    <div className="flex justify-center">
                      <div className="flex justify-center">
                        <div className="w-7 h-7 bg-textColor text-white rounded-full flex items-center justify-center font-bold text-xs">
                          2
                        </div>
                      </div>
                    </div>
                    <span className="text-sm text-maincolor flex justify-center">
                      Currency
                    </span>
                    <div className="flex bg-[#181322] text-maincolor py-6 font-normal text-2xl justify-center">
                      {currency}
                    </div>

                    <div className="flex  items-center justify-center  mt-1 mb-5 w-full">
                      <div className="flex-grow border-t bg-line opacity-10 border-line"></div>
                      <div className="flex-grow border-t bg-line opacity-10 border-line"></div>
                    </div>

                    <div className="flex justify-center">
                      <div className="flex justify-center">
                        <div className="w-7 h-7 bg-textColor text-white rounded-full flex items-center justify-center font-bold text-xs">
                          3
                        </div>
                      </div>
                    </div>
                    <span className="text-sm text-maincolor flex justify-center">
                      Address
                    </span>
                    <div className="flex bg-[#181322] text-maincolor py-6 font-normal text-2xl justify-center">
                      {address}
                    </div> */}

                  

                    <div className="py-1 w-full flex flex-col   justify-start ">
                      <span className="mb-2 text-md font-dmsans font-normal  leading-5 text-line text-sm">
                        Your Amount to Deposit in USDTs
                      </span>
                      <input
                        {...formik.getFieldProps("amount")}
                        id="amount"
                        type="number"
                        pattern="[0-9]{0,5}"
                        min={1}
                        className=" h-10 px-4 py-2  border text-sm   w-1/3 rounded-xl  bg-maincolor  text-line font-dmsans placeholder-[#8e8e93] focus:outline-none"
                        placeholder="Amount"
                      />


                    </div>
                    <div className="flex justify-start">
                      <button
                        type="submit"
                        
                        className="relative   justify-center text-sm mx-2 my-6 w-56 font-dmsans  border h-10  bg-textColor text-maincolor border-textColor text-md p-2 rounded-lg mb-6 hover:bg-textColor hover-text-white "
                      >
                        
                        Update
                      </button>


                    </div>
                                          
<span className="text-sm text-line  break-words font-dmsans flex justify-start flex-wrap w-1/3 italic">
                  Note: You have to successfully make deposit within 1 hour of creating deposit link. After 1 hour that deposit link becomes invalid.

                </span>



                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Deposit;
