import axios from "axios";
import PostApiData from "../helpers/postApiData";
import { socket } from "./global";
import getApiData from "../helpers/getApiData";

export async function ValidationDepositAmount(values) {
  const errors = verify_amount_create({}, values);

  return errors;
}

async function verify_amount_create(error = {}, values) {
  if (values.userId == "") {
    values.userId = JSON.parse(localStorage.getItem("user"))._id;
  }

  let dateToday = Date(Date.now());

  const Values = {
    network: values.network,
    currency: values.currency,
    address: values.address,
    amount: values.amount,
    type: "deposit",
    quantity: 1,
    userId: values.userId,
    date: dateToday,
  };





  
 const response =  await PostApiData("merchant/create_order", Values);








  socket.emit("post_data", {
    title:
      "Transaction  to Deposit an Amount : " +
      values.amount +
      " Created Successfully",
    userId: values.userId,
  });



  
 values.navigate("/output_deposit", {
  state: { 
      txn_id:response.result.txn_id,
      address: response.result.address,
      amount: response.result.amount,
      status_url:response.result.status_url,
      qrcode_url:response.result.qrcode_url,
      id:response.id,
    },
});


  





}

export async function ValidationWithdrawAmount(values) {
  const errors = verify_amount_create_wth({}, values);

  return errors;
}

async function verify_amount_create_wth(error = {}, values) {
  let dateToday = Date(Date.now());

  const Values = {
    network: values.network,
    currency: values.currency,
    address: values.address,
    amount: values.amount,
    type: "withdraw",
    quantity: 1,
    userId: values.userId,
    date: dateToday,
  };


  const id = JSON.parse(localStorage.getItem("user"))._id;

  const userValue = await getApiData("auth/get_user_by_id" + "/" + id, "");

  const balance = userValue.user.balance;

  if (balance > values.amount) {
  await PostApiData("merchant/create_order", Values);

  socket.emit("post_data", {
    title:
      "Transaction  to Withdraw an Amount : " +
      values.amount +
      " Created Successfully",
    userId: values.userId,
  });

  }








}

export async function ValidateOrderBuy(values) {
  const errors = verify_amount_create_buy({}, values);

  return errors;
}

async function verify_amount_create_buy(error = {}, values) {
  const userId = JSON.parse(localStorage.getItem("user"))._id;

  let Values = {};

  let dateToday = Date(Date.now());

  if (values.type == "buy") {
    Values = {
      network: "",
      currency: values.currency,
      address: "",
      amount: values.price / values.mprice,
      type: values.type,
      quantity: values.price,
      merchantId: values.merchantId,
      userId: userId,
      verify: false,

      date: dateToday,
    };
  } else {
    Values = {
      network: "",
      currency: values.currency,
      address: "",
      amount: values.amount * values.mprice,
      type: values.type,
      quantity: values.amount,
      merchantId: values.merchantId,
      userId: userId,
      verify: false,
      date: dateToday,
    };
  }

  


  if (
    (values.availablity < values.price / values.mprice && values.type == "buy")
  ) {



    return false;
  } else if (values.availablity < values.amount && values.type == "sell") {
    return false;
  }

 


  const id = JSON.parse(localStorage.getItem("user"))._id;

  const userValue = await getApiData("auth/get_user_by_id" + "/" + id, "");

  const balance = userValue.user.balance;

 
  if (balance >= values.amount && values.type == "sell") {
    const response = await PostApiData("merchant/create_order", Values);
    socket.emit("post_data", {
      title: "Order with ID: " + response.order._id + " Created Successfully",
      userId,
    });

    values.navigate("/single_order", {
      state: { id: response.order._id, type: values.type },
    });
    
  } else if (values.type == "buy") {
    const response = await PostApiData("merchant/create_order", Values);
    socket.emit("post_data", {
      title: "Order with ID: " + response.order._id + " Created Successfully",
      userId,
    });
    values.navigate("/single_order", {
      state: { id: response.order._id, type: values.type },
    });
  }
}

export async function ValidateContactForm(values) {
  const errors = verify_contact_form({}, values);

  return errors;
}

async function verify_contact_form(error = {}, values) {
  const Values = {
    name: values.name,
    email: values.email,
    phone: values.phone,
    desc: values.desc,
  };
  await PostApiData("auth/create_contact", Values);

  // socket.emit("post_data", {title:"Transaction  to Withdraw an Amount : " + values.amount +" Created Successfully",userId:values.userId});
}

export async function ValidationProfileUpdate(values) {
  const errors = verify_profile_update_wth({}, values);

  return errors;
}

async function verify_profile_update_wth(error = {}, values) {
  const userId = JSON.parse(localStorage.getItem("user"))._id;

  const formData = new FormData();

  formData.append("firstname", values.firstname);
  formData.append("lastname", values.lastname);
  formData.append("email", values.email);
  formData.append("paymentMethod", values.paymentmethod.label);
  formData.append("phoneNumber", values.phone);
  formData.append("ID", userId);
  formData.append("avatar", values.avatar);
  formData.append("accountNumber", values.accountNumber);
  formData.append("bankname", values.bankname);
  formData.append("title", values.title);

  await PostApiData("auth/update_user_profile", formData);

  socket.emit("post_data", {
    title: "Profile Updated  Successfully",
    userId: userId,
  });
}
