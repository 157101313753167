import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";
export const productData = createAsyncThunk(
    "product",
    async (payload, { rejectWithValue }) => {
      try {
    
        const request = await axios.get(env.API_URL + "product/get_products"
        );
        const response = await request.data;

  
        return response;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );


const productSlice = createSlice({
  name: "product",
  initialState: {
    products : [],
    loading: false,
    error: null,
  },
  reducers: {
    addProduct: (state, action) => {
            
    },
  },
  extraReducers: (builder) => {
    
    builder
      .addCase(productData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(productData.rejected, (state, action) => {
  
        state.loading = true;
      })
      .addCase(productData.fulfilled, (state, action) => {
          state.loading = false;
      
          state.products = action.payload;
        
         
        }
      );
  },
});
export const { addProduct } = productSlice.actions;
export default productSlice.reducer;


