import React from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import { merchantData } from "../helpers/AuthStore/merchantSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLayoutEffect, useEffect, useState } from "react";
import GetApiData from "../helpers/getApiData";
import PostApiData from "../helpers/postApiData";
import { IoIosCreate } from "react-icons/io";
import { AiOutlineEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import CreateMerchant from "./CreateMerchant";
import UpdateMerchant from './UpdateMerchant';


import { useNavigate } from "react-router-dom";
import SideMenu from "../Container/SideMenu";
import { AiOutlineSearch } from "react-icons/ai";
import ToasterGen from "../Container/ToasterGen";
const MerchantList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let merchant = useSelector((state) => state.merchant);
  const [parentData, setParentData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [merchantState, setMerchantState] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10000000;
  const filteredMenu = merchant.merchants.merchant
    ? merchant.merchants.merchant.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMenu.slice(indexOfFirstItem, indexOfLastItem);

  // const totalPages = Math.ceil(filteredMenu.length / itemsPerPage);

  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  useLayoutEffect(() => {
    return () => {};
  }, [merchant.merchants.merchant]);

  useEffect(() => {
    dispatch(merchantData({disable:true}));
  }, [dispatch]);
  useEffect(() => {
    dispatch(merchantData({disable:true}));
  }, [showModal, showModalCreate]);

  const handleUpdateClick = async (item) => {
    item = {
      ...item,
      
    };

    setMerchantState(item);
    setShowModal(true);
  };
  const handleDeleteButton = async (id) => {

    await PostApiData("merchant/delete_merchant", { ID: id });
    dispatch(merchantData());
  };


  useEffect(() => {if(localStorage.getItem('user')){const role = JSON.parse(localStorage.getItem('user')).role;if (role == 'user'){navigate('/NotFound');}    }  },[])


  useEffect(() => {
    const fetchParentData = async () => {
      if (merchant.merchants.merchant) {
        for (const item of merchant.merchants.merchant) {
          const data = await GetApiData(
            "merchant/get_merchant_by_id",
            item.parent
          );
          setParentData((prevData) => ({
            ...prevData,
            [item.parent]: data,
          }));
        }
      }
    };

    fetchParentData();
  }, [merchant.merchants.merchant, dispatch]);

  return (
    <>
    <ToasterGen></ToasterGen>

      <Header></Header>
      <MenuHeader></MenuHeader>
      <div className="grid grid-cols-1 lg:grid-cols-10 gap-0">
        <div className="lg:col-span-2  lg:flex bg-background-main ">
          <SideMenu />
        </div>
        <div className="lg:col-span-8 bg-background-main">

    
      {showModalCreate ? (
        <CreateMerchant
          props={setShowModalCreate}
          merchantState={merchantState}
        ></CreateMerchant>
      ) : null}

      {showModal ? (
        <UpdateMerchant
          props={setShowModal}
          merchantState={merchantState}
          merchantdata = {merchantData}
        ></UpdateMerchant>
   
      ) : null}

      <div className="container mx-auto mt-14">
        <div className="flex flex-col min-h-screen bg-transparent">
          <div className="p-4">
            <button
              onClick={() => {
                setShowModalCreate(true);
              }}
              className="flex items-center justify-center w-auto border h-12 bg-textColor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
            >
              <IoIosCreate className="mr-2" />
              Create Merchant
            </button>
          </div>


          <div className="flex mx-8 mt-10 items-end justify-end sm:justify-end ">
            <div className="absolute flex-shrink-0 items-center">
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Merchant"
                  className="bg-background border border-gray-600 text-line font-dmsans text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 p-2.5 focus:outline-none focus:ring-0"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <AiOutlineSearch className="w-5 h-5 text-line dark:text-line" />
                </div>
              </div>
            </div>
          </div>

          <div className="relative flex flex-col m-6 space-y-8 bg-background shadow-2xl rounded-2xl">
            <div className="p-8 md:p-14">
              <span className="mb-3 font-dmsans text-subheading-400 text-textColor">
                Merchant List
              </span>

              <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-background">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                   
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Rating
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Orders
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Completion
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Payment
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Currency
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Available
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Limit High
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Limit Low
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Payment Method
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Bank Account Number
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Bank Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Phone Number
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Email
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Average Time
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Release Time
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Description
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Bank Title
                      </th>

                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Disable
                      </th>
                      
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                      <th className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-background divide-y divide-gray-200">
                    {currentItems.map((item,index) => (
                      <tr key={index}>
                         
  <td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

  {item.name}
</div>
</td>


<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">


    {item.rating }                                         
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.orders}                                      
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.completion}                                          
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.Payment}                                        
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.Currency}                  
</div>

</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.type}                                        
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.available}                                          
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.limitHigh}
                        
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.limitLow}
                        
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.PaymentMethod}                      
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.BankAccountNumber}
                      
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.BankName}                            
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.phoneNumber}                      
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.email}
                        
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.avgtime}
                        
</div>
</td>
<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.Ttime}
                        
</div>
</td><td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.desc}
                        
</div>
</td>

<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.banktitle}
                        
</div>
</td>


<td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-line font-dmsans">

    {item.disable == true? "Hidden" :"Not Hidden"}
                        
</div>

</td>






  

                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() => {
                              handleUpdateClick(item, parentData);
                            }}
                            className="text-line hover:extra-color"
                          >
                            <AiOutlineEdit />
                          </button>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() => handleDeleteButton(item._id)}
                            href="#"
                            className="text-line hover:extra-color"
                          >
                            <MdDelete />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default MerchantList;
