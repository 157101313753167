import React from 'react'
import r1 from '../assets/Rectangle11.png'
const Sec2 = () => {
  return (
    <div className='h-fit border-solid border-1 border-gray-300'>
    <div className="relative mt-0 w-full bg-[#FAFAFA] p-5">
      <div className="flex flex-col gap-10 px-4 py-8 sm:flex-row">

        
       

        <div className="w-full sm:w-1/2 border-solid rounded-lg bg-transparent border-1">
      <div className="flex-col items-start gap-4 sm:gap-6 w-full h-auto sm:h-full">

    <div className='bg-newbgcolor rounded-md '>
        <div className="font-bold text-2xl sm:text-3xl font-dmsans flex items-center p-5  text-line ">Decentralized Network</div>
        <p className="font-normal text-base sm:text-16 leading-180.5% mx-2  font-dmsans text-line leading-relaxed ">
        The pioneering Peer-to-Peer Decentralized Cryptocurrency Exchange offers a fully decentralized platform, ensuring user control and security without KYC verification. Merchants undergo KYC verification to prevent fraud, and a manual escrow service provides extra protection for users.
        </p>

</div>
      
        <div className="font-bold   text-2xl sm:text-3xl flex items-center p-5 text-line">Empowering Every Trader</div>
       
        <div className="font-bold   text-2xl sm:text-3xl flex items-center p-5 text-line">No Middlemen, Just Traders</div>
       

        <div className="font-bold   text-2xl sm:text-3xl flex items-center p-5 text-line">Secure, Transparent, and Inclusive</div>
       

      </div>
    </div>


    <div className="w-full sm:w-1/2 border-solid rounded-lg bg-transparent border-1 p-4 ">
          <div className="w-full h-[300px] sm:h-[441px] bg-cover bg-center rounded-xl">
            <img className="w-full h-full bg-cover bg-center bg-no-repeat rounded-xl" src={r1} alt="Your Image" />
          </div>
        </div>



      </div>
    </div>
  </div>
  )
}

export default Sec2