import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";

export const markUpData = createAsyncThunk(
  "customer_markup",
  async (payload, { rejectWithValue }) => {
    try {
      const request = await axios.get(env.API_URL + "customer/get_markups");
      const response = await request.data;
     
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const customerMarkupSlice = createSlice({
  name: "customer_markup",
  initialState: {
    markups: [],
    loading: false,
    error: null,
  },
  reducers: {
    addMarkup: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(markUpData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(markUpData.rejected, (state, action) => {
        state.loading = true;
      })
      .addCase(markUpData.fulfilled, (state, action) => {
        state.loading = false;
        state.markups = action.payload;
      });
  },
});

export const { addMarkup } = customerMarkupSlice.actions;
export default customerMarkupSlice.reducer;
