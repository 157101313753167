import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";

export const depositData = createAsyncThunk(
  "deposit",

  async (payload, { rejectWithValue }) => {
    try {
      const request = await axios.get(env.API_URL + "merchant/get_deposits");
      const response = await request.data;

      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message); 
    }
  }
);

const depositSlice = createSlice({
  name: "desposit",
  initialState: {
    deposits : [],
    loading: false,
    error: null,
  },
  reducers: {
    addDeposit: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(depositData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(depositData.rejected, (state, action) => {
  
        state.loading = true;
      })
      .addCase(depositData.fulfilled, (state, action) => {
          state.loading = false;
          state.deposits = action.payload;
       
        }
      );
  },
});
export const { addDeposit } = depositSlice.actions;
export default depositSlice.reducer;

