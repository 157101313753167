import React from "react";

import { Link } from "react-router-dom";
import { BiUpArrowAlt } from "react-icons/bi";
import Terms from "../assets/Files/Terms of Usage.pdf"

import Privacy from "../assets/Files/Privacy Policy.pdf"

import "./Footer.css"
import logo from "../assets/logo2.png";
const Footer = () => {
    
  function handleScroll() {
    window.scroll({
      top: document.body.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }
  return (
    <div className="h-max bg-maincolor ">
      <div className="flex w-full h-20 bg-gradient-to-r justify-center py-10  z-50">
        <Link to="/dashboard">
          <img
            className="lg:block mx-2 sm:h-16 md:h-16 lg:h-16 md:w-auto sm:w-auto lg:w-auto xl:w-auto w-fit h-16"
            src={logo}
            alt="TUMAANEX"
          />
        </Link>
      </div>
      <div className="relative w-full bg-maincolor z-50 mt-10">
      <div className="flex flex-col items-center justify-center w-full h-[50vw] max-h-[100px]  opacity-70 text-line ">
      <div className="flex flex-col h-auto p-4 md:p-8 text-center bg-transparent text-line  w-1/2 z-50 ">
         
      <div className="flex flex-col md:flex-row justify-center items-center z-50">
        <div className="mb-4 md:mb-0 md:mr-6 text-line">
          <a  href={Terms} download="Terms of Use" className="text-line font-dmsans text-sm">
            Terms of Use
          </a>
        </div>
        <div className="mb-4 md:mb-0 md:mr-6 text-line">
          <a href={Privacy} download="Privacy Policy" className="text-line font-dmsans text-sm">
            Privacy Policy
          </a>
        </div>
        <div className="text-line">
          <span className="font-dmsans text-sm">&copy; 2023-2024 TUMAANEX</span>
        </div>
      </div>


       </div>
       
  <div className="group2 -z-10"></div>
  <div className="group4"></div>
  <div className="group3"></div>


</div>

      </div>
    


     
      <div className="flex items-center justify-end mt-4 md:mt-0">
        <div className="w-12 h-12 bg-maincolor border border-solid border-[#B28EFB33] rounded-xl flex items-center justify-center">
          <button onClick={handleScroll} className="text-maincolor p-3 z-50">
            <BiUpArrowAlt className="w-6 h-6 text-line" />
          </button>
        </div>
      
    </div>
      
    </div>
  );
};

export default Footer;
