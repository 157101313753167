import React from "react";

import { useState, useEffect } from "react";
import auth from "../assets/auth.png";
import best from "../assets/best.png";
import OrderCalculate from "./OrderCalculate";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";
import { AiOutlineArrowUp } from "react-icons/ai";
import  './TradeDetail.css';
const TradeDetails = ({
  handleColumnSort,
  sortColumn,
  sortDirection,
  currentItems,
  typeNew,
}) => {
  const [modal, setShowModal] = useState(false);
  const [type, setType] = useState(true);
  const [merchantState, setMerchantState] = useState({});
  const [price, setPrice] = useState(0);
  const [id, setId] = useState(0);
  const [Currency, setCurrency] = useState(0);
  const navigate = useNavigate();





  useEffect(() => {
    if (localStorage.getItem("user")) {
      const role = JSON.parse(localStorage.getItem("user")).role;
      if (role == "admin") {
        navigate("/NotFound");
      }
    }
  }, []);


 

  return (
    <div className="bg-newbgcolor p-5 rounded-lg border-transparent mt-2  w-full h-full ">
      
      
    <div className="bg-transparent  py-7 rounded-lg border-transparent mt-2  w-full h-full ">
      <div className=" ">
        <table className="relative bg-transparent w-full ">
          
      <thead className="hidden md:table-header-group font-dmsans  h-20 lg:w-max xl:w-max 2xl:w-max md:w-max sm:w-max border-b border-gray-500  ">
            <tr>
              <th className="h-4 font-normal leading-5  text-line px-4 text-left  text-xs">
                Advertiser
              </th>
              <th className="h-4 font-normal leading-5 text-line text-left justify-start text-xs">
                <div className="flex grid-col-2 gap-2 text-xs">
                <button
                    onClick={() => handleColumnSort("Price")}
                    className="flex items-center space-x-1"
                  >
                    Price
                    {sortColumn === "Price" && (
                      <span
                        className={`${
                          sortDirection === "asc"
                            ? "text-textColor"
                            : "text-line dark:text-line"
                        }`}
                      >
                        {sortDirection === "asc" ? (
                          <AiOutlineArrowUp />
                        ) : (
                          <AiOutlineArrowDown />
                        )}
                      </span>
                    )}
                  </button>

                  <div className="  flex  bg-[#FEF6D8]  border rounded px-1">
                    <p className=" font-medium   text-xs   leading-5 text-textColor   ">
                    {sortDirection === "asc" ? ( "lowest to highest" ): ( "highest to lowest")}
                    
                    </p>
                  </div>
                </div>
              </th>

              <th className="h-4 font-normal leading-5 text-line px-0 text-left text-xs">
                Limit/Available
              </th>
              <th className="h-4 font-normal leading-5 text-line px-0  text-left text-xs">
                Payment
              </th>
              <th className="h-4 font-normal leading-5 text-line px-4 text-left text-xs">
                <div  className="flex grid-col-2 gap-2 justify-end text-sm">
                  Trade
                  <div  className="   w-12  h-5 bg-[#E6FFF1]  border rounded px-1 text-xs">
                    <p className=" font-medium   text-xs   leading-5 text-[#03A66D] ">
                      0 Fee
                    </p>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          

          <tbody className="bg-newbgcolor border-newcolortext border-solid">
          {currentItems &&
              currentItems.map((item, index) => (
                <React.Fragment key={index}>
                  {item.type === typeNew && (
                    <>
                      <tr
                        

                        className="lg:table-row md:table-row xl:table-row 2xl:table-row grid w-full"
                      >
                        <td className="px-0 py-7">
                          <div className="flex grid-cols-3 gap-1 items-center">
                            <div className="relative">
                              <div className="w-9 h-9 bg-[#4653C8] rounded-full flex justify-center items-center text-line text-xs font-semibold relative">
                                {item.name.charAt(0).toUpperCase()}
                              </div>
                              <div className="absolute bottom-0 top-6 right-0 w-3 h-3  border border-solid border-white  bg-[#0ECB81] rounded-full flex justify-center items-center text-line"></div>
                            </div>
                            <p className="text-line font-dmsans font-normal text-lg">
                              {item.name}
                            </p>
                            <div>
                              <img
                                src={auth}
                                alt="auth"
                                className="text-line h-3 w-3"
                              />
                            </div>
                            
                            
                              <div className="flex grid-cols-2 items-center lg:hidden sm:hidden md:hidden xl:hidden 2xl:hidden ">
                            <div> 
                              <img src={best}></img>
                            </div>
                            <div>
                              <p className="text-line mx-1 font-dmsans font-normal text-sm ">
                                {item.rating}%
                              </p>
                            </div>
                          </div>


                          </div>

                          <div className="mt-2">
                            <div className="flex grid-cols-3 gap-3 items-center">
                              <p className="text-line font-dmsans font-normal text-sm ">
                                {item.orders} orders
                              </p>
                              <div className="text-gray-500  opacity-40">|</div>
                              <div className="text-line ">
                                <p className="text-line font-dmsans font-normal text-sm ">
                                  {item.completion}% completion
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="hidden grid-cols-2 items-center font-dmsans   lg:flex sm:flex md:flex xl:flex 2xl:flex">
                            <div> 
                              <img src={best}></img>
                            </div>
                            <div>
                              <p className="text-line mx-1 font-dmsans font-normal text-sm ">
                                {item.rating}%
                              </p>
                            </div>
                          </div>
                        </td>

                        <td className="px-0">
                          <div className="flex  grid-col-2 gap-2 ">
                            <p className="text-2xl font-dmsans font-medium text-line  ">
                              {item.Payment}
                            </p>
                            <span className=" font-dmsans text-line  font-normal items-center mt-1 text-sm">
                              {item.Currency}
                            </span>
                          </div>
                        </td>
                        <td className="px-0">
                       
                          <p className="text-sm font-dmsans font-medium text-line">
                          <span className=" text-gray-400 text-xs lg:hidden md:hidden sm:hidden xl:hidden 2xl:hidden font-dmsans">Available</span>  {item.available} USDT
                          </p>

                          <div className=" mt-2 flex grid-cols-3 gap-3 items-center">
                            <p className="text-line font-dmsans font-normal text-sm ">
                     
                            <span className=" text-gray-400 text-xs lg:hidden md:hidden sm:hidden xl:hidden 2xl:hidden font-dmsans">Limit</span>          {item.Currency} {item.limitLow}
                            </p>
                            <div className="text-line ">-</div>
                            <div className="text-line ">
                              <p className="text-line font-dmsans font-normal text-sm ">
                                {item.Currency} {item.limitHigh}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-0">
                          <div className="text-xs text-softColor">
                            <div className="flex grid-cols-2 items-center gap-2">
                              <div className=" w-1 h-4 top-1  rounded bg-[#F0B90B] "></div>
                              <p className="text-sm font-dmsans font-medium text-line">
                                {item.PaymentMethod}
                              </p>
                            </div>

                            <div className="flex grid-cols-2 items-center gap-2">
                              <div className=" w-1 h-4 top-1  rounded bg-[#711871] "></div>
                              <p className="text-sm font-dmsans font-medium text-line">
                                {item.BankName}
                              </p>
                            </div>
                          </div>
                        </td>
                        

              {typeNew == "buyer" ? (
                          <td className=" flex   items-center justify-end py-8">
                          <button
                              onClick={() => {
                          
                                setId(item._id);
                                setShowModal(true);
                                setPrice(item.Payment);
                                setMerchantState(item);

                                setCurrency(item.Currency);
                              }}
                              className=" flex items-center  justify-center  font-semibold  lg:w-32 md:w-32 w-full sm:w-32 xl:w-32 2xl:w-32  border h-12 bg-[#0ECB81] text-line border-[#0ECB81] text-md p-2 rounded-lg mb-6 hover:bg-[#0ECB81] hover-text-line"
                            >
                              Buy USDT
                            </button>
                          </td>
                        ) : (
                          <td className=" flex   items-center justify-end py-8">
                            <button
                              onClick={() => {
                                
                                setId(item._id);                             
                                setShowModal(true);
                           
                                setPrice(item.Payment);
                                setMerchantState(item);
                                setCurrency(item.Currency);
                                
                         
                              }}
                              className=" flex items-center justify-center  lg:w-32 md:w-32 w-full sm:w-32 xl:w-32 2xl:w-32  font-semibold border h-12 border-red-600 text-line bg-red-600 text-md p-2 rounded-lg mb-6 hover:bg-red-600 hover-text-line"
                            >
                              Sell USDT
                            </button>
                          </td>
                        )}
                        
                      </tr>
                     
                   

                   
                      <tr>
  <td colSpan="8">
  

    {item._id == id && modal  ? (
      <OrderCalculate
        props={setShowModal}
        Currency={Currency}
        type={type}
        price={price}
        merchantState={merchantState}
        typeNew={typeNew}
        item={item}
 
  

      ></OrderCalculate>
    ) : null} 
  </td>
</tr>
   <tr>
                        <td
                          colSpan="5"
                          className="py-2 border-t   border-gray-500"
                        ></td>
                      </tr>
                    </>
                  )}
                </React.Fragment>
              ))}
          </tbody>

         


        </table>

      </div>
      </div>
    </div>
  );
};

export default TradeDetails;
