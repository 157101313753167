import DashBoard from "../DashBoard/DashBoard";
import Home from "../components/Home/Home";
import Login from "../components/Login/Login";
import { createBrowserRouter } from "react-router-dom";

import CreateMenu from "../Menu/CreateMenu";
import MenuList from "../Menu/MenuList";
import ScreenList from "../Screen/ScreenList";

import AuthScreenList from "../Screen/AuthScreenList";
import NotFound from "./NotFound";

import SignUp from "../components/SignUp/SignUp";
import SignUpEmail from "../components/SignUpEmail";
import Funding from "../Container/Funding";
import MerchantList from "../Buyers/MerchantList";
// Modify the base path for the build
import SideMenu from "../Container/SideMenu";
import DepositList from "../Deposit/DepositList";
import Deposit from "../Container/Deposit";
import WithdrawList from "../Withdraw/WithdrawList";
import Withdraw from "../Container/Withdraw";
import Orders from "../Buyers/Orders";

import SingleOrder from "../DashBoard/SingleOrder";
import OrdersBuySell from "../Buyers/OrdersBuySell";
import Contact from "../components/Home/Contact";
import Profile from "../Container/Profile";
import UserList from "../User/UserList";
import DepositOutput from "../Container/DepositOutput";
import VerifyEmail from "../components/SignUp/VerifyEmail";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/user_list",
    element: <UserList />
  },

  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/signup",
    element: <SignUp />
  },
  {
    path: "/signup_email",
    element: <SignUpEmail />
  },
  {
    path: "/dashboard",
    element: <DashBoard />
  },
  {
    path: "/menu",
    element: <CreateMenu />
  },
  {
    path: "/menu_list",
    element: <MenuList />
  },
  {
    path: "/screen_list",
    element: <ScreenList />
  },

  {
    path: "/auth_list",
    element: <AuthScreenList />
  },

  {
    path: "*",
    element: <NotFound />
  },

  {
    path: "/profile",
    element: <Profile />
  },
  {
    path: "/side_menu",
    element: <SideMenu />
  },
  ,
  {
    path: "/funding",
    element: <Funding />
  },
  ,
  {
    path: "/merchant_list",
    element: <MerchantList />
  },
  {
    path: "/deposit_list",
    element: <DepositList />
  },

  ,
  {
    path: "/deposit",
    element: <Deposit />
  },
  {
    path: "/withdraw_list",
    element: <WithdrawList />
  },
  {
    path: "/withdraw",
    element: <Withdraw />
  },
  {
    path: "/order",
    element: <Orders />
  },
  {
    path: "/order_buy_sell",
    element: <OrdersBuySell />
  },

  {
    path: "/p2p",
    element: <p2p />
  },
  {
    path: "/single_order",
    element: <SingleOrder />
  },

  {
    path: "home",
    element: <Home />
  },
  {
    path: "contact",
    element: <Contact />
  },
  {
    path: "output_deposit",
    element: <DepositOutput />
  },

  {
    path: "verify_email",
    element: <VerifyEmail />
  }
]);

export default router;
