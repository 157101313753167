import React, { useLayoutEffect, useEffect } from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import { useLocation } from "react-router-dom";

import { toast, Toaster, ToastBar } from "react-hot-toast";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import ToasterGen from "./ToasterGen";
import getApiData from "../helpers/getApiData";
const DepositOutput = () => {


  const { state } = useLocation();
  const { txn_id,address,amount,status_url,qrcode_url,id} = state || { txn_id:null, address: null, amount: null,status_url:null,
    qrcode_url:null,id:null};
  const navigate = useNavigate();


  useEffect(() => {}, []);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const role = JSON.parse(localStorage.getItem("user")).role;
      if (role == "admin") {
        navigate("/NotFound");
      }
    }
  }, []);

 
  

  return (
    <>
      <ToasterGen></ToasterGen>

      <Header />
      <MenuHeader />

      <div className=" bg-newbgcolor">
        <div className="bg-newbgcolor relative px-3 py-5">
          <h1 className="text-line font-dmsans font-medium text-2xl leading-8">
          Make  Deposit
          </h1>
        </div>
        <div className="p-10 bg-newbgcolor text-line border-maincolor ">
          <div className="px-5 py-5 container mx-auto  border mt-4  border-maincolor bg-maincolor   rounded-xl border-opacity-40">
            <div className="col-span-1">
              <div className="bg-maincolor p-6 md:p-8">
                <span className=" mx-2 text-sm text-line flex justify-start font-dmsans">
                  Scan Below  QR code for Payment or Deposit Exact Amount in BEP-20 USDT At given Address 

                </span>
                <div className="px-4 p-5 w-full h-full bg-maincolor">
                    <div className="flex justify-start">
                    <QRCode value={qrcode_url} className=" flex justify-center align-middle items-center"></QRCode>
                    
                    </div>
                 



                  </div>
                  <div className="px-4 p-5 w-full h-full bg-maincolor">
                    <div className="flex justify-start">
                  
                    </div>
                    <span className="text-xs text-line  text-left break-words   font-dmsans ">
                                            Deposit Address: {address}
                    </span>
                    



                  </div>

      <div className="px-4 p-5 w-full h-full bg-maincolor">
                    <div className="flex justify-center">
                  
                    </div>
                    <span className="text-xs text-line text-center break-words   font-dmsans ">
                      Deposit Amount: {amount}
                    </span>
                    



                  </div>
                  <div className="px-4 p-5 w-full h-full bg-maincolor">
                    <div className="flex justify-center">
                  
                    </div>
                    <span className="text-xs text-line text-center break-words    font-dmsans"> 
                                         Transaction ID: {txn_id}
                    </span>
                    


                    <div className="flex justify-start">
                      <button
                        type="submit"
                        
                        onClick={async (e) => {
                            e.preventDefault();
  
                        const response =   await getApiData("merchant/get_verify_status",id);
                 

                          if(response.value == false)
                          {
                           

                            toast.success(response.message, {
                              duration: 2000,
                              style: {
                                border: "2px solid #F5F5F5",
                                padding: "16px",
                                backgroundColor: "#F5F5F5", // Add your desired background color
                                color: "#171717",
                               fontFamily:"sans-serif"
                              },
    
                              error: true,
                            });

                            


                          }
                          else

                          {

                            toast.success(response.message, {
                              duration: 2000,
                              style: {
                                border: "2px solid #F5F5F5",
                                padding: "16px",
                                backgroundColor: "#F5F5F5", // Add your desired background color
                                color: "#171717",
                               fontFamily:"sans-serif"
                              },
    
                              error: true,
                            });




                          }
                            

                          }}


                        className="relative   justify-start text-sm mx-2 my-6 w-56  border h-fit  bg-textColor  text-maincolor border-textColor font-dmsans text-md p-2 rounded-lg mb-6 hover:bg-textColor hover-text-white "
                      >
                        
                        
                        Check Status
                      </button>


                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default DepositOutput;
